import { TradeTicketFormFields } from '@app/etfs-equities/models';
import { Action } from '@ngrx/store';

import { QueryParams, TabLink } from '../../states';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum TradeTicketActionTypes {
  UPDATE_TRADE_TICKET = '[Trade Ticket] Update Trade Ticket',
  UPDATE_TRADE_TICKET_TAB_LINK = '[Trade Ticket] Update Trade Ticket Tab Link',
  UPDATE_EXTENDED_TRADING_TAB_LINK = '[Trade Ticket] Update Extended Trading Tab Link',
  CLEAR_TRADE_TICKET = '[Trade Ticket] Clear',
  RESET_TRADE_TICKET = '[Trade Ticket] Reset',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class UpdateTradeTicketAction implements Action {
  readonly type = TradeTicketActionTypes.UPDATE_TRADE_TICKET;
  constructor(public payload: TradeTicketFormFields) {}
}

export class UpdateTradeTicketTabLinkAction implements Action {
  readonly type = TradeTicketActionTypes.UPDATE_TRADE_TICKET_TAB_LINK;
  constructor(public payload: TabLink) {}
}

export class UpdateExtendedTradingTabLinkAction implements Action {
  readonly type = TradeTicketActionTypes.UPDATE_EXTENDED_TRADING_TAB_LINK;
  constructor(public payload: TabLink) {}
}
export class ClearTradeTicketAction implements Action {
  readonly type = TradeTicketActionTypes.CLEAR_TRADE_TICKET;
}

export class ResetTradeTicketAction implements Action {
  readonly type = TradeTicketActionTypes.RESET_TRADE_TICKET;
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createUpdateTradeTicketAction = (tradeTicket: TradeTicketFormFields) => {
  return new UpdateTradeTicketAction(tradeTicket);
};

export const createUpdateTradeTicketTabLinkAction = (url: string, queryParams: QueryParams = null) => {
  const [path] = url.split('?');
  return new UpdateTradeTicketTabLinkAction({ url: path, queryParams });
};

export const createUpdateExtendedTradingTabLinkAction = (url: string, queryParams: QueryParams = null) => {
  const [path] = url.split('?');
  return new UpdateExtendedTradingTabLinkAction({ url: path, queryParams });
};

export const createClearTradeTicketAction = () => {
  return new ClearTradeTicketAction();
};

export const createResetTradeTicketAction = () => {
  return new ResetTradeTicketAction();
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type TradeTicketActionTypesUnion =
  | UpdateTradeTicketAction
  | UpdateTradeTicketTabLinkAction
  | UpdateExtendedTradingTabLinkAction
  | ClearTradeTicketAction
  | ResetTradeTicketAction;
