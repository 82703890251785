<div class="row">
  <div class="col-12 col-md-6 c11n-space-stack-3x mb-md-0">
    <twe-input-label
      for="orderType"
      [labelText]="content?.labels.orderType"
      [tooltipHeader]="content?.labels.orderType"
      data-testid="guidelet-ordertype"
    >
      <p [innerHtml]="content?.guidelet.orderType.limitOrder"></p>
      <button c11n-link colorMode="on-dark" type="button" (click)="orderTypesModal.open($event)">
        {{ content?.guidelet.orderType.linkToModal }}
      </button>
    </twe-input-label>
    <h5 class="c11n-text-md-bold c11n-space-stack-0_5x">{{ content?.labels.limit }}</h5>
    <p class="c11n-text-sm twe-c11n-color-ink-400 mb-0">{{ content?.guidelet.orderType.limitOnlyInfo }}</p>
  </div>
  <div class="col-12 col-md-6">
    <twe-price-control
      [controlName]="'limitPrice'"
      [controlLabel]="content?.labels.limitPrice"
      [parentGroup]="tradeTicketService?.tradeTicket"
      [hasError]="tradeTicketService?.limitPriceHasError()"
      [requiredWhenInError]="tradeTicketService?.limitPriceHasRequiredWhenInError()"
    >
    </twe-price-control>
  </div>
</div>

<twe-order-types-modal #orderTypesModal></twe-order-types-modal>
