<ng-container
  *ngIf="{
    cancelResponse: cancelResponse$ | async,
    orderCancel: (orderCancel$ | async)
  } as view"
>
  <div class="container-fluid c11n-space-stack-5x">
    <div class="row justify-content-around">
      <div class="col-md-7 offset-xl-1 c11n-space-stack-5x" role="alert">
        <div class="c11n-space-stack-5x">
          <h2 class="c11n-text-lg-bold mt-3 c11n-space-stack-1_5x">
            {{ content.cancelSuccess.cancelOrderReceived }}
          </h2>
          <p class="c11n-text-md" [innerHTML]="content.cancelSuccess.trackRequest"></p>
          <button c11n-link fontWeight="normal" (click)="printComponent()">
            {{ content.cancelSuccess.printSummary }}
          </button>
        </div>
        <div class="container-fluid c11n-space-stack-2x">
          <div class="row gx-0">
            <div class="twe-flex-center-items">
              <div class="col-xs-3">
                <img
                  [alt]="content.cancelSuccess.requestPending.graphicAlt"
                  src="{{ windowService.assetsPath }}assets/svg/file-clock.svg"
                />
              </div>
              <div class="col">
                <p class="c11n-text-md">{{ content.cancelSuccess.requestPending.message }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="c11n-text-xs twe-c11n-color-ink-400 c11n-space-stack-5x">
          {{ content.cancelSuccess.timeSent }}:
          {{ (view.cancelResponse?.cancelTimestamp | tweDate) || '&mdash;' }}
        </div>

        <twe-cancel-order-detail></twe-cancel-order-detail>
      </div>
      <div
        class="col-md-4 col-lg-3 offset-xl-1 c11n-space-stack-5x"
        role="navigation"
        [attr.aria-label]="content.headings.whatsNext"
      >
        <h2 class="twe-c11n-color-ink-800 c11n-text-lg-bold c11n-space-stack-1_5x">
          {{ content.headings.whatsNext }}
        </h2>
        <ul class="twe-unstyled-list" [attr.aria-label]="content.headings.whatsNext">
          <li class="c11n-text-md c11n-space-stack-1_5x">
            <a c11n-link fontWeight="normal" [routerLink]="OPEN_ORDERS_PATH">
              {{ content.labels.viewOpenOrders }}
            </a>
          </li>
          <li class="c11n-text-md c11n-space-stack-1_5x">
            <a c11n-link fontWeight="normal" [attr.href]="secureSiteUrls.balancesHoldings">
              {{ content.labels.viewMyHoldings }}
            </a>
          </li>
          <li class="c11n-text-md">
            <a
              c11n-link
              fontWeight="normal"
              [routerLink]="TRADE_PATH"
              [queryParams]="{ accountId: view.orderCancel?.accountId }"
            >
              {{ content.labels.placeAnotherTrade }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
