<c11n-modal-dialog #modal maxWidth="900" [headingText]="content.dollarBasedInvestingLearnMoreModal.title">
  <div c11n-modal-dialog-body>
    <div class="c11n-space-stack-5x">
      <p class="dollar-based-overlay__now">{{ content.dollarBasedInvestingLearnMoreModal.nowYouCanBuy }}</p>
    </div>

    <div class="row c11n-space-stack-2x">
      <div class="col-md-6 c11n-space-stack-3x">
        <p class="c11n-text-md mb-0" [innerHTML]="content.dollarBasedInvestingLearnMoreModal.example"></p>
      </div>

      <div class="col-md-6 d-flex align-items-start">
        <img
          src="{{ windowService.assetsPath }}assets/svg/dollar-investing-learn-more.svg"
          [alt]="content.dollarBasedInvestingLearnMoreModal.graphicAlt"
          width="84"
        />

        <div class="d-column c11n-space-inline-left-3x">
          <p class="twe-c11n-color-red-800 c11n-text-xl mb-0 twe-text-bold">
            {{ content.dollarBasedInvestingLearnMoreModal.oneHundredDollars }}
          </p>
          <p class="twe-c11n-color-red-800 c11n-text-md mb-0 twe-text-bold">
            {{ content.dollarBasedInvestingLearnMoreModal.wholeShare }}
          </p>
          <p class="c11n-text-md">
            {{ content.dollarBasedInvestingLearnMoreModal.dollarBasedTrade }}
          </p>
        </div>
      </div>
    </div>

    <div class="c11n-text-md">
      <p class="twe-text-bold">
        {{ content.dollarBasedInvestingLearnMoreModal.additionalDetails }}
      </p>
      <ul>
        <li *ngFor="let detail of content.dollarBasedInvestingLearnMoreModal.additionalDetailsList">
          {{ detail }}
        </li>
      </ul>
    </div>
  </div>
</c11n-modal-dialog>
