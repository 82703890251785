import { Action } from '@ngrx/store';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum MarketDataTypes {
  EXPAND_CLOSE_PANEL = '[Market Data] Expand/Close Quote panel',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class QuoteExpandClosePanelAction implements Action {
  readonly type = MarketDataTypes.EXPAND_CLOSE_PANEL;
  constructor(public payload: boolean) {}
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createQuoteExpandClosePanelAction = (expandPanel: boolean) => {
  return new QuoteExpandClosePanelAction(expandPanel);
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type MarketDataActionTypesUnion = QuoteExpandClosePanelAction;
