import { OrderEnums } from '@app/etfs-equities/enums';
import { Quote } from '@app/etfs-equities/models';
import { createSelector } from '@ngrx/store';
import { InstrumentClassSetInstrumentClassesEnum } from '@vanguard/invest-api-client-typescript-axios';

import { selectAccountIsMargin } from '../account/account.selectors';
import {
  selectIsCashAndMarginOrShortHoldings,
  selectQuote,
  selectQuoteIsHalted,
  selectSharesHeldForCash,
  selectSharesHeldForMargin,
} from '../quote/quote.selectors';
import {
  selectActionIsBuy,
  selectActionIsBuyOrSellShort,
  selectActionIsSellOrBuyToCover,
  selectAmount,
  selectAmountType,
} from '../trade-ticket/trade-ticket.selectors';

/* For selectors that need selectors from multiple files */

export const selectHasEnoughCashMarginShares = createSelector(
  selectAccountIsMargin,
  selectIsCashAndMarginOrShortHoldings,
  selectSharesHeldForCash,
  selectSharesHeldForMargin,
  selectAmount,
  selectAmountType,
  (
    accountIsmargin,
    isCashAndMarginOrShortHoldings: boolean,
    cashShares: number,
    marginShares: number,
    amount: number,
    amountType: string | null
  ): boolean =>
    !Number.isNaN(amount) &&
    accountIsmargin &&
    isCashAndMarginOrShortHoldings &&
    amountType === OrderEnums.AmountTypes.SHARES &&
    (amount <= cashShares || amount <= marginShares)
);

export const selectHasEnoughCashShares = createSelector(
  selectHasEnoughCashMarginShares,
  selectSharesHeldForCash,
  selectAmount,
  (hasEnoughCashMarginShares: boolean, cashShares: number, amount: number): boolean =>
    hasEnoughCashMarginShares && amount <= cashShares
);

export const selectHasEnoughMarginShares = createSelector(
  selectHasEnoughCashMarginShares,
  selectSharesHeldForMargin,
  selectAmount,
  (hasEnoughCashMarginShares: boolean, marginShares: number, amount: number): boolean =>
    hasEnoughCashMarginShares && amount <= marginShares
);

export const selectShowNoBuyOrdersBannerForLowTierOtc = createSelector(
  selectQuote,
  selectActionIsBuyOrSellShort,
  (quote: Quote, buyOrSellShort) =>
    !!quote &&
    quote.isOtc &&
    quote.instrumentClasses.includes(InstrumentClassSetInstrumentClassesEnum.EXTRA_TYPE9) &&
    buyOrSellShort
);

export const selectShowLowLiquidityHint = createSelector(
  selectQuote,
  selectActionIsSellOrBuyToCover,
  (quote: Quote, isSellOrBuyToCover) =>
    !!quote &&
    quote.isOtc &&
    (!quote.instrumentClasses.includes(InstrumentClassSetInstrumentClassesEnum.EXTRA_TYPE9) || isSellOrBuyToCover)
);

export const selectIsClientBuyingHaltedStock = createSelector(
  selectActionIsBuy,
  selectQuoteIsHalted,
  (isBuy, isHalted): boolean => isBuy && isHalted
);
