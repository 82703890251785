import { Injectable } from '@angular/core';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@app/core/services';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { AemQueryResult } from '../../models/aem/aem-query-result.model';

@Injectable({
  providedIn: 'root',
})
export class AemContentService {
  content: JsonContent = content;

  constructor(private readonly apollo: Apollo, private readonly gatekeeper: GatekeeperService) {}

  getContent(path: string): Observable<any> {
    return this.gatekeeper.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_AEM).pipe(
      switchMap((gatekeeperAEMState) => {
        if (gatekeeperAEMState) {
          return this.apollo
            .watchQuery({
              query: gql`
                {
                  announcementBanner: multiLineList(
                    filter: {
                      _path: {
                        _expressions: [
                          {
                            value: "${path}"
                            _operator: STARTS_WITH
                          }
                        ]
                      }
                    }
                  ) {
                    items {
                      _path
                      heading
                      body {
                        bodyText: plaintext
                      }
                    }
                  }
                }
              `,
            })
            .valueChanges.pipe(
              map(
                (result: AemQueryResult) =>
                  `${result.data?.announcementBanner.items[0].heading}${result.data?.announcementBanner.items[0].body[0].bodyText}`
              ),
              catchError(() => {
                return of(this.content?.announcementBanner?.content);
              })
            );
        } else {
          return of(this.content?.announcementBanner?.content);
        }
      })
    );
  }
}
