<twe-generic-tooltip-modal #transactionTypeForMarginModal [headingText]="content.labels.transaction">
  <p [innerHtml]="content?.guidelet.transaction.buyToCover"></p>
  <p [innerHtml]="content?.guidelet.transaction.sellShort"></p>
  <p *ngIf="!isBeacon">
    <a
      c11n-link
      href="https://personal.vanguard.com/pdf/margin.pdf"
      [attr.title]="content?.guidelet.transaction.link"
      target="_blank"
    >
      {{ content?.guidelet.transaction.link }}</a
    >.
  </p>
</twe-generic-tooltip-modal>

<ng-container
  *ngIf="{
    selectedAccount: (selectedAccount$ | async),
    accountIsMargin: (accountIsMargin$ | async),
    isIncapacitated: (isIncapacitated$ | async)
  } as view"
>
  <div class="c11n-space-stack-3x c11n-space--force">
    <ng-container *ngIf="!(view.selectedAccount && view.accountIsMargin)">
      <label c11n-label size="medium">{{ content.labels.transaction }}</label>
      <c11n-segmented-control
        appSegmentControlReset
        color="yellow"
        size="small"
        [legendText]="content.labels.transaction"
      >
        <c11n-segment [labelText]="content.labels.buy">
          <input
            c11n-segment-input
            id="actionBuy"
            type="radio"
            [formControl]="tradeTicketService?.tradeTicket?.controls?.action"
            name="action"
            [value]="transactionTypes.BUY"
            [attr.disabled]="view.isIncapacitated ? true : null"
            aria-describedby="transaction-required-error"
          />
        </c11n-segment>

        <c11n-segment [labelText]="content.labels.sell">
          <input
            c11n-segment-input
            id="actionSell"
            type="radio"
            [formControl]="tradeTicketService?.tradeTicket?.controls?.action"
            name="action"
            [value]="transactionTypes.SELL"
            [attr.disabled]="view.isIncapacitated ? true : null"
            aria-describedby="transaction-required-error"
          />
        </c11n-segment>
      </c11n-segmented-control>

      <c11n-hint-error
        [hasError]="tradeTicketService?.controlHasError('action')"
        [errorText]="content.validation.transaction"
        [hintErrorId]="'transaction-required-error'"
        [ngClass]="{ 'm-0': !tradeTicketService?.controlHasError('action') }"
      ></c11n-hint-error>
    </ng-container>

    <fieldset
      class="twe-mt-n075"
      *ngIf="view.selectedAccount && view.accountIsMargin"
      [attr.aria-invalid]="tradeTicketService?.controlHasError('action')"
    >
      <div class="d-flex align-items-center">
        <p class="c11n-text-xs mb-0">{{ content.labels.transaction }}</p>
        <c11n-icon-button
          class="c11n-text-lg-bold"
          [labelText]="content.guidelet.transaction.linkToModal"
          buttonType="button"
          size="small"
          variant="ghost"
          iconName="help"
          (clickEvent)="transactionTypeForMarginModal.modal.openModalDialog()"
        ></c11n-icon-button>
      </div>

      <div class="row">
        <c11n-radio-group groupId="action-radio-buttons-group" [variant]="'default'">
          <div class="row">
            <div class="col-6">
              <c11n-radio
                class="c11n-size-space-2x c11n-space--force"
                ariaDescribedBy="transaction-required-error-margin"
                [labelText]="content.labels.buy"
                [descriptiveLabel]="'transaction-required-error-margin'"
                [labelWeight]="'normal'"
              >
                <input
                  [formControl]="tradeTicketService?.tradeTicket?.controls?.action"
                  c11nRadioInput
                  id="radio-button-buy"
                  type="radio"
                  name="radioButton"
                  [value]="transactionTypes.BUY"
                />
              </c11n-radio>

              <c11n-radio
                ariaDescribedBy="transaction-required-error-margin"
                [labelText]="content.labels.buyToCover"
                [descriptiveLabel]="'transaction-required-error-margin'"
                [labelWeight]="'normal'"
              >
                <input
                  [formControl]="tradeTicketService?.tradeTicket?.controls?.action"
                  c11nRadioInput
                  id="radio-button-buy-to-cover"
                  type="radio"
                  name="radioButton"
                  [value]="transactionTypes.BUY_TO_COVER"
                />
              </c11n-radio>
            </div>

            <div class="col-6">
              <c11n-radio
                class="c11n-size-space-2x c11n-space--force"
                ariaDescribedBy="transaction-required-error-margin"
                [labelText]="content.labels.sell"
                [descriptiveLabel]="'transaction-required-error-margin'"
                [labelWeight]="'normal'"
              >
                <input
                  [formControl]="tradeTicketService?.tradeTicket?.controls?.action"
                  c11nRadioInput
                  id="radio-button-sell"
                  type="radio"
                  name="radioButton"
                  [value]="transactionTypes.SELL"
                />
              </c11n-radio>

              <c11n-radio
                ariaDescribedBy="transaction-required-error-margin"
                [labelText]="content.labels.sellShort"
                [descriptiveLabel]="'transaction-required-error-margin'"
                [labelWeight]="'normal'"
              >
                <input
                  [formControl]="tradeTicketService?.tradeTicket?.controls?.action"
                  c11nRadioInput
                  id="radio-button-sell-short"
                  type="radio"
                  name="radioButton"
                  [value]="transactionTypes.SELL_SHORT"
                />
              </c11n-radio>
            </div>
          </div>
        </c11n-radio-group>
      </div>

      <c11n-hint-error
        [hasError]="tradeTicketService?.controlHasError('action')"
        [errorText]="content.validation.transaction"
        [hintErrorId]="'transaction-required-error-margin'"
        [ngClass]="{ 'm-0': !tradeTicketService?.controlHasError('action') }"
      ></c11n-hint-error>
    </fieldset>
  </div>
</ng-container>
