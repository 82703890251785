import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@app/core/services';
import { CashMarginIndicatorTypes, OrderEnums, SecurityAccountTypes } from '@app/etfs-equities/enums';
import { Account, CostBasis, JsonContent, Order, Quote } from '@app/etfs-equities/models';
import { TradeTicketService } from '@app/etfs-equities/services';
import {
  securityAccountType,
  selectEstimatedShares,
  selectIsEditCostBasisState,
  selectOrder,
  selectOrderContent,
  selectOrderFormatSharesDisplay,
  selectPrincipal,
  selectQuote,
  selectSelectedAccount,
  TayneState,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { CostBasisUnavailableModalComponent } from '@etfs-equities/pages';
import { CostBasisService } from '@etfs-equities/services/cost-basis/cost-basis.service';
import { select, Store } from '@ngrx/store';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-17';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'twe-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  // Decorators...
  @ViewChild(CostBasisUnavailableModalComponent)
  costBasisMethodUnavailableModal: CostBasisUnavailableModalComponent;

  // Enums for form control values...
  amountTypes = OrderEnums.AmountTypes;
  marginIndicator = CashMarginIndicatorTypes.MARGIN;
  transactionTypes = OrderEnums.TransactionTypes;

  // Store state...
  selectedAccount$: Observable<Account.Account>;
  order$: Observable<Order.Order>;
  principal$: Observable<number>;
  quote$: Observable<Quote>;
  orderContent$: Observable<string>;
  dollarBasedTradesEnabled$: Observable<boolean>;
  estimatedShares$: Observable<number>;
  formatSharesDisplay$: Observable<string>;
  securityAccountType$: Observable<SecurityAccountTypes>;
  isEditCostBasis$: Observable<boolean>;

  // Local state...
  content: JsonContent = content;
  durationLayoutStyles: string;
  orderTypeLayoutStyles: string;
  limitPriceLayoutStyles: string;
  stopPriceLayoutStyles: string;
  specIdWasSelected: boolean;
  totalShares: number;
  totalSharesCost: number;
  isStopLimit = false;
  isStopOrLimit = false;
  isMarket = false;

  unsubscribe$ = new Subject<void>();

  constructor(
    private readonly store: Store<TayneState>,
    private readonly decimal: DecimalPipe,
    private readonly costBasisService: CostBasisService,
    private readonly tradeTicketService: TradeTicketService,
    private readonly gatekeeperService: GatekeeperService
  ) {}

  get showCostBasis() {
    return this.tradeTicketService.costBasisIsVisible;
  }

  ngOnInit() {
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.principal$ = this.store.pipe(select(selectPrincipal));
    this.quote$ = this.store.pipe(select(selectQuote));
    this.order$ = this.store.pipe(select(selectOrder));
    this.orderContent$ = this.store.pipe(select(selectOrderContent));
    this.estimatedShares$ = this.store.pipe(select(selectEstimatedShares));
    this.formatSharesDisplay$ = this.store.pipe(select(selectOrderFormatSharesDisplay));
    this.securityAccountType$ = this.store.pipe(select(securityAccountType));
    this.isEditCostBasis$ = this.store.pipe(select(selectIsEditCostBasisState));

    this.dollarBasedTradesEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_DOLLAR_BASED_TRADES
    );

    this.order$
      .pipe(
        filter((order) => !!order),
        tap((order) => {
          this.specIdWasSelected = order.costBasisMethod === CostBasisMethod.SPEC_ID;

          if (order.costBasisLots) {
            this.calculateTotalSharesSold(order.costBasisLots);
            this.calculateTotalSharesCost(order.costBasisLots);
          }
          this.isMarket = order.orderType === OrderEnums.Types.MARKET;
          this.isStopLimit = order.orderType === OrderEnums.Types.STOP_LIMIT;
          this.isStopOrLimit = order.orderType === OrderEnums.Types.STOP || order.orderType === OrderEnums.Types.LIMIT;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openCostBasisUnavailableModal() {
    this.costBasisService.openUnavailableModal$.next();
  }

  private calculateTotalSharesSold(selectedLots: CostBasis.SelectedLot[]) {
    this.totalShares = 0;

    selectedLots.forEach((lot) => (this.totalShares += +lot.selectedLotQuantity));
  }

  private calculateTotalSharesCost(selectedLots: CostBasis.SelectedLot[]) {
    this.totalSharesCost = 0;

    selectedLots.forEach(
      (lot) => (this.totalSharesCost += +this.decimal.transform(lot.selectedLotQuantity * lot.costperShare, '1.2-2'))
    );
  }
}
