import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/shared/services/environment/environment.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private readonly appName = 'trade-twe-app';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly cookieService: CookieService,
    private readonly envService: EnvironmentService
  ) {}

  public debug(logCode: string, message: unknown) {
    this.log({
      level: 'debug',
      appName: this.appName,
      logCode,
      message,
    });
  }

  public info(logCode: string, message: unknown) {
    this.log({
      level: 'info',
      logCode,
      appName: this.appName,
      message,
    });
  }

  public warn(logCode: string, message: unknown) {
    this.log({
      level: 'warn',
      appName: this.appName,
      logCode,
      message,
    });
  }

  public error(logCode: string, message: unknown) {
    this.log({
      level: 'error',
      appName: this.appName,
      logCode,
      message,
    });
  }

  private log(logData: Record<string, unknown>) {
    this.httpClient
      .request('POST', this.envService.getApiUrlBaseOnRoute() + 'api/logging/', {
        withCredentials: true,
        body: logData,
        headers: new HttpHeaders({
          'Content-type': 'application/json',
          'TWE-XSRF-TOKEN': this.cookieService.get('tweXsrfToken'),
        }),
      })
      .subscribe();
  }
}
