import { InstrumentClassSetInstrumentClassesEnum } from '@vanguard/invest-api-client-typescript-axios';

export enum TradingRestrictionEnum {
  NO_BUY_ORDERS = 'NO_BUY_ORDERS',
  NONE = 'NONE',
}

export enum QuoteSecurityTypeEnum {
  ETF = 'ETF',
  COMMON_STOCK = 'COMMON_STOCK',
}

export enum InstrumentClassExtendedEnum {
  LEVINV_RESET_ETF_OVERRIDE = 'LevInv_RESET_ETF',
}

export type InstrumentClassEnum = InstrumentClassExtendedEnum | InstrumentClassSetInstrumentClassesEnum;
