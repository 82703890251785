<c11n-modal-dialog
  #modal
  [headingText]="content.exitModal.title"
  [primaryButtonLabel]="content.exitModal.exit"
  [secondaryButtonLabel]="content.exitModal.continueOrder"
  (primaryClick)="exitTradeApp()"
  (secondaryClick)="continueInTradeApp(); modal.closeDialogModal()"
>
  <div c11n-modal-dialog-body>
    <p>{{ content.exitModal.body }}</p>
  </div>
</c11n-modal-dialog>
