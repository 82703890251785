import { Injectable } from '@angular/core';
import { MarketDataService } from '@app/etfs-equities/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { QuoteExpandClosePanelAction } from '../../actions';
import { MarketDataTypes } from '../../actions/market-data/market-data.actions';

@Injectable()
export class MarketDataEffects {
  quoteExpandClosePanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MarketDataTypes.EXPAND_CLOSE_PANEL),
        tap((action: QuoteExpandClosePanelAction) => (this.marketDataService.panelIsOpen = action.payload))
      ),
    { dispatch: false }
  );

  constructor(private readonly marketDataService: MarketDataService, private readonly actions$: Actions) {}
}
