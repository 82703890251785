import { SecurityClassTypes } from '@app/etfs-equities/enums';
import { Account } from '@app/etfs-equities/models';

export class HoldingsUtil {
  private static readonly etfTypes: SecurityClassTypes[] = [SecurityClassTypes.ETF, SecurityClassTypes.VANGUARD_ETF];

  private static readonly stockTypes: SecurityClassTypes[] = [
    SecurityClassTypes.COMMON_STOCK,
    SecurityClassTypes.PREFERRED_STOCK,
    SecurityClassTypes.CONVERTIBLE_PREFERRED_STOCK,
    SecurityClassTypes.WHEN_AS_AND_IF_ISSUED_EQUITY,
    SecurityClassTypes.MISCELLANEOUS_STOCK,
    SecurityClassTypes.RIGHTS,
    SecurityClassTypes.WARRANT,
    SecurityClassTypes.UNITS,
  ];

  private static readonly optionTypes: SecurityClassTypes[] = [
    SecurityClassTypes.CALL_OPTION,
    SecurityClassTypes.PUT_OPTION,
  ];

  private static readonly mutualFundTypes: SecurityClassTypes[] = [SecurityClassTypes.MUTUAL_FUND];

  private static readonly CDTypes: SecurityClassTypes[] = [SecurityClassTypes.CERTIFICATES_OF_DEPOSIT];

  private static readonly bondTypes: SecurityClassTypes[] = [
    SecurityClassTypes.CORPORATE_BOND,
    SecurityClassTypes.CONVERTIBLE_BOND,
    SecurityClassTypes.MUNICIPAL_BOND,
    SecurityClassTypes.BOND_UNIT,
    SecurityClassTypes.MUNI_ASSESSMENT_DIST,
    SecurityClassTypes.WHEN_AS_AND_IF_ISSUED_BOND,
    SecurityClassTypes.US_TREASURY_BILL,
    SecurityClassTypes.US_TREASURY_NOTE,
    SecurityClassTypes.US_TREASURY_BOND,
    SecurityClassTypes.OTHER_GOVERNMENT,
    SecurityClassTypes.GOVERNMENT_MORTGAGE,
    SecurityClassTypes.COLLATERALIZED_MORTGAGE_OBLIGATION,
    SecurityClassTypes.US_TREASURY_ZERO_COUPON,
    SecurityClassTypes.SHORT_TERM_PAPER,
    SecurityClassTypes.TAX_SHELTER,
    SecurityClassTypes.COMMERCIAL_PAPER,
    SecurityClassTypes.FIXED_INCOME_UNITS,
    SecurityClassTypes.MISCELLANEOUS_FIXED_INCOME,
  ];

  static getEtfs(holdings: Account.Holding[]): Account.MappedHolding[] {
    return holdings
      .filter((h) => HoldingsUtil.holdingIsEtf(h))
      .map((h) => HoldingsUtil.formatHolding(h))
      .sort((a, b) => HoldingsUtil.sortHoldings(a, b));
  }

  static getStocks(holdings: Account.Holding[]): Account.MappedHolding[] {
    return holdings
      .filter((h) => HoldingsUtil.holdingIsStock(h))
      .map((h) => HoldingsUtil.formatHolding(h))
      .sort((a, b) => HoldingsUtil.sortHoldings(a, b));
  }

  static getOptions(holdings: Account.Holding[]): Account.MappedHolding[] {
    return holdings
      .filter((h) => HoldingsUtil.holdingIsOption(h))
      .map((h) => HoldingsUtil.formatHolding(h))
      .sort((a, b) => HoldingsUtil.sortHoldings(a, b));
  }

  static getMutualFunds(holdings: Account.Holding[]): Account.MappedHolding[] {
    return holdings
      .filter((h) => HoldingsUtil.holdingIsMutualFund(h))
      .map((h) => HoldingsUtil.formatHolding(h))
      .sort((a, b) => HoldingsUtil.sortHoldings(a, b));
  }

  static getCDs(holdings: Account.Holding[]): Account.MappedHolding[] {
    return holdings
      .filter((h) => HoldingsUtil.holdingIsCD(h))
      .map((h) => HoldingsUtil.formatHolding(h))
      .sort((a, b) => HoldingsUtil.sortHoldings(a, b));
  }

  static getBonds(holdings: Account.Holding[]): Account.MappedHolding[] {
    return holdings
      .filter((h) => HoldingsUtil.holdingIsBond(h))
      .map((h) => HoldingsUtil.formatHolding(h))
      .sort((a, b) => HoldingsUtil.sortHoldings(a, b));
  }

  static getOtherSecurities(holdings: Account.Holding[]): Account.MappedHolding[] {
    const excludeTypes = HoldingsUtil.etfTypes.concat(
      HoldingsUtil.stockTypes,
      HoldingsUtil.optionTypes,
      HoldingsUtil.mutualFundTypes,
      HoldingsUtil.CDTypes,
      HoldingsUtil.bondTypes
    );

    return holdings
      .filter((h) => excludeTypes.indexOf(h.brokeragePosition.securityClassType) === -1)
      .map((h) => HoldingsUtil.formatHolding(h))
      .sort((a, b) => HoldingsUtil.sortHoldings(a, b));
  }

  private static holdingIsEtf(holding: Account.Holding): boolean {
    return (
      holding.brokeragePosition && HoldingsUtil.etfTypes.indexOf(holding.brokeragePosition.securityClassType) !== -1
    );
  }

  private static holdingIsStock(holding: Account.Holding): boolean {
    return (
      holding.brokeragePosition && HoldingsUtil.stockTypes.indexOf(holding.brokeragePosition.securityClassType) !== -1
    );
  }

  private static holdingIsOption(holding: Account.Holding): boolean {
    return (
      holding.brokeragePosition && HoldingsUtil.optionTypes.indexOf(holding.brokeragePosition.securityClassType) !== -1
    );
  }

  private static holdingIsMutualFund(holding: Account.Holding): boolean {
    return (
      holding.brokeragePosition &&
      HoldingsUtil.mutualFundTypes.indexOf(holding.brokeragePosition.securityClassType) !== -1
    );
  }

  private static holdingIsCD(holding: Account.Holding): boolean {
    return (
      holding.brokeragePosition && HoldingsUtil.CDTypes.indexOf(holding.brokeragePosition.securityClassType) !== -1
    );
  }

  private static holdingIsBond(holding: Account.Holding): boolean {
    return (
      holding.brokeragePosition && HoldingsUtil.bondTypes.indexOf(holding.brokeragePosition.securityClassType) !== -1
    );
  }

  private static readonly formatHolding = (holding: Account.Holding): Account.MappedHolding => ({
    raw: { ...holding },
    symbol: holding.ticker,
    name: holding.brokeragePosition.securityDescription,
    quantity: holding.quantity,
  });

  private static readonly sortHoldings = (a: Account.MappedHolding, b: Account.MappedHolding) =>
    a.name.localeCompare(b.name);
}
