import { Action } from '@ngrx/store';

export enum HistoryActionTypes {
  CLEAR_HISTORY_URL = '[History] Clear url',
  SET_HISTORY_URL = '[History] Set url',
}

class ClearHistoryUrlAction implements Action {
  readonly type = HistoryActionTypes.CLEAR_HISTORY_URL;
}

class SetHistoryUrlAction implements Action {
  readonly type = HistoryActionTypes.SET_HISTORY_URL;
  constructor(public payload: string) {}
}

export const createClearHistoryUrlAction = () => new ClearHistoryUrlAction();

export const createSetHistoryUrlAction = (url: string) => new SetHistoryUrlAction(url);

export type HistoryActionTypesUnion = ClearHistoryUrlAction | SetHistoryUrlAction;
