<twe-order-types-modal #orderTypesModal></twe-order-types-modal>

<ng-container
  *ngIf="{
    quote: (quote$ | async),
    selectShowLowLiquidityHint: (selectShowLowLiquidityHint$ | async),
    isClientBuyingHaltedStock: (isClientBuyingHaltedStock$ | async),
    orderIsEveningDuration: (orderIsEveningDuration$ | async)
  } as view"
>
  <div class="c11n-space-stack-2x c11n-space--force">
    <twe-input-label
      for="orderType"
      [labelText]="content?.labels.orderType"
      [tooltipHeader]="content?.labels.orderType"
      data-testid="guidelet-ordertype"
    >
      <p [innerHtml]="content?.guidelet.orderType.marketOrder"></p>
      <p [innerHtml]="content?.guidelet.orderType.limitOrder"></p>
      <p [innerHtml]="content?.guidelet.orderType.advanceOrderType"></p>
      <button c11n-link colorMode="on-dark" type="button" (click)="orderTypesModal.open($event)">
        {{ content?.guidelet.orderType.linkToModal }}
      </button>
    </twe-input-label>
    <div
      class="read-only c11n-text-md-bold c11n-space-stack-1_5x"
      data-testid="order-type-read-only"
      *ngIf="
        tradeTicketService?.amountTypeIsDollars() ||
          tradeTicketService?.isSellAllLessThanOne() ||
          view.quote?.isOtc ||
          view.selectShowLowLiquidityHint ||
          view.isClientBuyingHaltedStock ||
          view.orderIsEveningDuration;
        else orderTypeToggle
      "
    >
      {{ getOrderTypeContent() }}
    </div>
    <ng-template #orderTypeToggle>
      <fieldset class="d-none d-md-block" [attr.aria-invalid]="tradeTicketService?.controlHasError('orderType')">
        <c11n-segmented-control
          #orderTypeSegmentedControl
          appSegmentControlReset
          color="yellow"
          size="small"
          [legendText]="content?.labels.orderType"
          data-testid="radio-btn-group-ordertype"
        >
          <c11n-segment [labelText]="content?.labels.market">
            <input
              c11n-segment-input
              id="orderTypeMarket"
              [formControl]="tradeTicketService?.tradeTicket?.controls.orderType"
              name="orderType"
              type="radio"
              aria-describedby="order-type-required-error otc-limit-only-message dollar-based-order-type-hint sell-all-less-than-one-order-type-hint"
              [value]="orderTypes.MARKET"
              (click)="adobeService.sendAdobeLaunchProcess('equityETFTicket-capture: Select Market Order Type')"
              [checked]="tradeTicketService?.tradeTicket?.controls.orderType.value == orderTypes.MARKET"
            />
          </c11n-segment>

          <c11n-segment [labelText]="content?.labels.limit">
            <input
              c11n-segment-input
              id="orderTypeLimit"
              [formControl]="tradeTicketService?.tradeTicket?.controls.orderType"
              name="orderType"
              type="radio"
              aria-describedby="order-type-required-error otc-limit-only-message"
              [value]="orderTypes.LIMIT"
              (click)="adobeService.sendAdobeLaunchProcess('equityETFTicket-capture: Select Limit Order Type')"
              [checked]="tradeTicketService?.tradeTicket?.controls.orderType.value == orderTypes.LIMIT"
            />
          </c11n-segment>

          <c11n-segment [labelText]="content?.labels.stop">
            <input
              c11n-segment-input
              id="orderTypeStop"
              [formControl]="tradeTicketService?.tradeTicket?.controls.orderType"
              name="orderType"
              type="radio"
              aria-describedby="order-type-required-error otc-limit-only-message"
              [value]="orderTypes.STOP"
              (click)="adobeService.sendAdobeLaunchProcess('equityETFTicket-capture: Select Stop Order Type')"
              [checked]="tradeTicketService?.tradeTicket?.controls.orderType.value == orderTypes.STOP"
            />
          </c11n-segment>

          <c11n-segment [labelText]="content?.labels.stopLimit">
            <input
              c11n-segment-input
              id="orderTypeStopLimit"
              [formControl]="tradeTicketService?.tradeTicket?.controls.orderType"
              name="orderType"
              type="radio"
              aria-describedby="order-type-required-error otc-limit-only-message"
              [value]="orderTypes.STOP_LIMIT"
              (click)="adobeService.sendAdobeLaunchProcess('equityETFTicket-capture: Select Stop Limit Order Type')"
              [checked]="tradeTicketService?.tradeTicket?.controls.orderType.value == orderTypes.STOP_LIMIT"
            />
          </c11n-segment>
        </c11n-segmented-control>

        <c11n-hint-error
          [hasError]="tradeTicketService?.controlHasError('orderType', 'required')"
          [errorText]="content?.validation.orderType"
          [hintErrorId]="'sorder-type-required-error'"
          data-testid="txt-input-validation"
          [ngClass]="{ 'm-0': !tradeTicketService?.controlHasError('orderType', 'required') }"
        ></c11n-hint-error>
      </fieldset>

      <div class="d-block d-md-none">
        <c11n-select
          (stateChange)="
            adobeService.sendAdobeLaunchProcess('equityETFTicket-capture: Select ' + $event + ' Order Type');
            syncOrderTypeWithSegmentControl()
          "
          [hideLabel]="true"
          [errorText]="content?.validation.orderType"
          [hasError]="tradeTicketService?.controlHasError('orderType')"
          hintText=""
          [isReadOnly]="
            view.quote?.isOtc || tradeTicketService?.amountTypeIsDollars() || tradeTicketService?.isSellAllLessThanOne()
          "
          aria-live="polite"
        >
          <select c11nSelectInput id="orderType" [formControl]="tradeTicketService?.tradeTicket?.controls.orderType">
            <option [value]="null" disabled selected hidden>Select an option</option>
            <option
              *ngFor="let option of orderTypeDropdownOptions"
              [value]="option.value"
              [selected]="tradeTicketService?.tradeTicket?.controls.orderType.value == option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </c11n-select>
      </div>
    </ng-template>

    <div id="dollar-based-order-type-hint">
      <p *ngIf="tradeTicketService?.amountTypeIsDollars()" class="c11n-text-sm twe-c11n-color-ink-400">
        {{ content?.dollarBasedOrderTypeHint }}
      </p>
    </div>

    <div id="sell-all-less-than-one-order-type-hint">
      <p *ngIf="tradeTicketService?.isSellAllLessThanOne()" class="c11n-text-sm twe-c11n-color-ink-400">
        {{ content?.sellAllLessThanOneOrderTypeHint }}
      </p>
    </div>

    <div id="otc-limit-only-message" *ngIf="view.selectShowLowLiquidityHint" class="c11n-stack-space-3x">
      <p data-testid="symbol-OTC-message" class="c11n-text-sm text-color-light-grey">
        {{ content?.otcLimitOnly }}
      </p>
      <p class="c11n-text-sm text-color-light-grey">
        {{ content?.otcRestrictionNotice }}
      </p>
    </div>
  </div>
</ng-container>
