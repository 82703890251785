import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppComponent } from '@app/app.component';
import { JsonContent } from '@app/etfs-equities/models';
import { WindowService } from '@app/etfs-equities/services';
import content from '@content/content.json';
import { environment } from '@env/environment';

@Component({
  selector: 'twe-critical-error',
  templateUrl: './critical-error.component.html',
  styleUrls: ['./critical-error.component.scss'],
})
export class CriticalErrorComponent implements OnInit, OnDestroy {
  content: JsonContent = content;
  orderStatusLink = environment.secureSiteUrls.orderStatus;

  constructor(private appComponent: AppComponent, public readonly windowService: WindowService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.appComponent.isCriticalError = true;
    });
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.appComponent.isCriticalError = false;
    });
  }
}
