import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingContext } from '@app/core/interceptors/loading-interceptor';
import { UILogError } from '@app/core/models/ui-log/ui-log.model';
import { OrderEnums } from '@app/etfs-equities/enums';
import { CostBasis, Order } from '@app/etfs-equities/models';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import { CookieService } from 'ngx-cookie-service';
import { catchError, Observable, Subject, tap, throwError } from 'rxjs';

import { TradeTicketService } from '../trade-ticket/trade-ticket.service';

@Injectable({
  providedIn: 'root',
})
export class CostBasisService {
  isLoadingLots = false;
  isLoadingLotsFailed = false;

  isLoadingTradeMethods = false;
  isSubmitting = false;

  openSelectionModal$: Subject<void> = new Subject();
  openUnavailableModal$: Subject<void> = new Subject();
  httpError$: Subject<UILogError> = new Subject();

  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
    private readonly cookieService: CookieService,
    private readonly tradeTicketService: TradeTicketService
  ) {}

  fetchLots(
    accountId: number,
    holdingId: number,
    orderId?: string,
    transactionType?: string
  ): Observable<CostBasis.LotResponse> {
    this.isLoadingLots = true;
    this.isLoadingLotsFailed = false;

    // In the unlikely scenario when account id is not provided
    if (!accountId) {
      this.tradeTicketService.tradeCannotBeCompleted$.next({
        error: new HttpErrorResponse({
          status: 204,
          statusText: 'Not Found',
          error: new Error('No account selected'),
        }),
        serviceName: 'TradePageComponent.loadLotsAndContinueToSelection',
      });
      return;
    }

    // In the unlikely scenario when holding id is not provided
    if (!holdingId) {
      return throwError(() => new Error('Invalid holdingId'));
    }

    let url =
      this.envService.getApiUrlBaseOnRoute() +
      `api/cost-basis/unrealized-lots?account_id=${accountId}&holding_id=${holdingId}`;
    if (!!orderId) {
      url += `&order_id=${orderId}`;
    }
    if (!!transactionType) {
      const longFlag = transactionType === OrderEnums.TransactionTypes.SELL ? true : false;
      url += `&isLongFlag=${longFlag}`;
    }
    return this.http.get<CostBasis.LotResponse>(url, { withCredentials: true });
  }

  fetchTradeMethods(accountId: number, holdingId: number) {
    this.isLoadingTradeMethods = true;

    if (!accountId || !holdingId) {
      return throwError(() => new HttpErrorResponse({ error: `Invalid accountId or holdingId` }));
    }
    return this.http.get<CostBasis.CostBasisTradeMethodsResponse>(
      this.envService.getApiUrlBaseOnRoute() +
        `api/cost-basis/trade-methods?accountId=${accountId}&holdingId=${holdingId}`,
      { withCredentials: true }
    );
  }

  fetchCostBasisForAccount(accountId: number) {
    if (!accountId) {
      return throwError(() => new HttpErrorResponse({ error: `Invalid accountId` }));
    }
    return this.http.get<CostBasis.CostBasisForSelectedAccountResponse>(
      this.envService.getApiUrlBaseOnRoute() + `api/selected-account-cost-basis/${accountId}`,
      { withCredentials: true }
    );
  }

  openSelectionModal() {
    setTimeout(() => {
      // adding setTimeout to ensure that the emission of values is delayed
      // until after any async operations are completed
      this.openSelectionModal$.next();
    });
  }

  submitEditCostBasis(order: Order.Order): Observable<Order.Order> {
    this.isSubmitting = true;

    return this.http
      .post<Order.Order>(
        `${this.envService.getApiUrlBaseOnRoute()}api/cost-basis/edit`,
        { order },
        {
          headers: new HttpHeaders({
            'TWE-XSRF-TOKEN': this.cookieService.get('tweXsrfToken'),
          }),
          withCredentials: true,
          context: new HttpContext().set(LoadingContext, {
            showLoading: true,
            status: 'submit',
          }),
        }
      )
      .pipe(
        tap(() => (this.isSubmitting = false)),
        catchError((error: HttpErrorResponse) => {
          this.isSubmitting = false;
          return this.handleHttpError(error, 'CostBasisService.submitEditCostBasis');
        })
      );
  }

  private handleHttpError(error: HttpErrorResponse, serviceName?: string) {
    this.httpError$.next({ error, serviceName });
    return throwError(() => error);
  }
}
