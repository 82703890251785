import { Pipe, PipeTransform } from '@angular/core';
import content from '@content/content.json';

@Pipe({
  name: 'tweOpenOrderDuration',
})
export class OpenOrderDurationPipe implements PipeTransform {
  transform(duration: string): string {
    if (typeof duration !== 'string') {
      return duration;
    }

    switch (duration.toLowerCase()) {
      case 'day':
        return content.labels.day;

      case 'eve':
        return content.labels.evening;

      case 'gtc':
        return content.labels.sixtyDayGTC;

      default:
        return duration;
    }
  }
}
