import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DataLayerEventsEnum, WindowPushEvents } from '@app/core/models/adobe-launch';
import { ProcessAbandoned, ProcessError, UnionProcesses } from '@app/core/models/adobe-launch/processes';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { environment } from '@env/environment';
import { interval, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

export interface TrackingDefault {
  domain?: string;
  section?: string;
  subSection?: string;
  pageId?: string;
  subViewId?: string;
}

@Injectable()
export class AdobeAnalyticsService {
  //  Public observables/subjects...

  public errors$: Observable<any>;

  //  Private variables...

  private readonly $window: any; // we use any type because datalayer is a custom field for adobe analytics
  private readonly MAX_RETRY = 30;
  private readonly WAIT_TIME = 100;

  // Private observables/subjects...

  private readonly _errSubject: Subject<any>;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this._errSubject = new Subject();
    this.errors$ = this._errSubject.asObservable();
    this.$window = document.defaultView;
  }

  public initializePage(page: any) {
    const adobeEnv = environment.adobeEnvironment;
    this.adobeLaunch().then(
      (impl: any) => {
        impl.initializePage(page, adobeEnv);
      },
      () => {
        return;
      }
    );
  }

  public trackPageLoadCompleted() {
    this.adobeLaunch().then(
      (impl: any) => {
        impl.trackPageLoadCompleted();
      },
      () => {
        return;
      }
    );
  }

  public windowPush(event: WindowPushEvents) {
    this.adobeLaunch().then(
      () => {
        this.$window.dataLayer = this.$window.dataLayer || [];
        this.$window.dataLayer.push(event);
      },
      () => {
        return;
      }
    );
  }

  public processPush(event: DataLayerEventsEnum, process: UnionProcesses) {
    if (!event || !process) {
      return;
    }
    const data: WindowPushEvents = {
      event,
      process,
    };

    this.windowPush(data);
  }

  /**
   * Sends Process Abandoned tags to Adobe Launch
   * @param process - the process to send to adobe launch
   */
  public sendProcessAbandoned(process: ProcessAbandoned) {
    this.processPush(DataLayerEventsEnum.PROCESS_ABANDONED, process);
  }

  public sendAdobeTrackingOnClick(name: string, type = 'button', location = 'capture'): void {
    this.windowPush({
      event: DataLayerEventsEnum.CALL_TO_ACTION,
      callToAction: {
        location: `equityETFTicket-${location}`,
        ctaName: name,
        ctaType: type,
      },
      fireTrackLink: {
        fireTrackLink: 'true',
      },
    });
  }

  public sendAdobeLaunchProcess(tag: string, processType?: string) {
    const process: UnionProcesses = {
      processSideStep: tag,
    };

    if (processType) {
      process.processType = processType;
    }
    this.processPush(DataLayerEventsEnum.PROCESS_SIDE_STEP_SUCCESS, process);
  }

  public sendAdobeLaunchProcessError(errorCode: string, processStep: string) {
    const process: ProcessError = {
      processType: CONSTANTS.ADOBE_PROCESS_TYPE,
      processStep,
      errorCode,
    };

    this.processPush(DataLayerEventsEnum.PROCESS_ERROR, process);
  }

  private adobeLaunch() {
    return new Promise((resolve, reject) => {
      let adobeLaunch = this.$window.adobeLaunch;

      if (adobeLaunch) {
        resolve(adobeLaunch);
        return;
      }

      const intervalObs = interval(this.WAIT_TIME).pipe(take(this.MAX_RETRY));

      const subscription = intervalObs.subscribe((count) => {
        adobeLaunch = this.$window.adobeLaunch;

        if (adobeLaunch) {
          subscription.unsubscribe();
          resolve(adobeLaunch);
        } else if (count >= this.MAX_RETRY - 1) {
          this._errSubject.next(new Error('window.adobeLaunch missing'));
          reject();
        }
      });
    });
  }
}
