import { createSelector } from '@ngrx/store';

import { FundsAvailableState, TayneState } from '../../states';
import { selectTayneState } from '../tayne/tayne.selectors';

export const selectFundsAvailableState = createSelector(selectTayneState, (state: TayneState) => state.fundsAvailable);

export const selectFundsAvailable = createSelector(
  selectFundsAvailableState,
  (state: FundsAvailableState) => state.fundsAvailable
);

export const selectFundsAvailableIsLoading = createSelector(
  selectFundsAvailableState,
  (state: FundsAvailableState) => state.isLoading
);

export const selectFundsAvailableLoadingFailed = createSelector(
  selectFundsAvailableState,
  (state: FundsAvailableState) => state.loadingFailed
);

export const selectFundsAvailablePanelIsOpen = createSelector(
  selectFundsAvailableState,
  (state: FundsAvailableState) => state.panelIsOpen
);
