import { ChangeOrderState, TayneState } from '@etfs-equities/store';
import { createSelector } from '@ngrx/store';

import { selectTayneState } from '../tayne/tayne.selectors';

export const selectChangeOrderState = createSelector(selectTayneState, (state: TayneState) => state.changeOrder);

export const selectIsChangeOrderState = createSelector(
  selectChangeOrderState,
  (state: ChangeOrderState) => state.isChangeOrder
);
