import { Component, Input } from '@angular/core';
import { Order } from '@etfs-equities/models';

@Component({
  selector: 'twe-rule-content',
  templateUrl: './rule-content.component.html',
  styleUrls: ['./rule-content.component.scss'],
})
export class RuleContentComponent {
  @Input() triggeredRules?: Order.TriggeredRule[] = [];
}
