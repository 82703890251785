import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';
import { filter, Observable, Subject, takeUntil, tap } from 'rxjs';

import { CONSTANTS } from '../../constants';
import { SymbolRetrievalType } from '../../enums';
import { MarketDataService, WindowService } from '../../services';
import { TayneState } from '../../store';
import { selectEnvironment } from '../../store/selectors/environment/environment.selectors';

@Component({
  selector: 'twe-non-vanguard-mutual-fund-modal',
  templateUrl: './non-vanguard-mutual-fund-modal.component.html',
  styleUrls: ['./non-vanguard-mutual-fund-modal.component.scss'],
})
export class NonVanguardMutualFundModalComponent implements OnInit, OnDestroy {
  @ViewChild('nonVanguardMutualFundModal')
  nonVanguardMutualFundModal: ModalDialogComponent;

  @Input()
  isChangeOrder = false;

  @Output()
  cancelEmit = new EventEmitter<void>();

  // Public variables...
  content: JsonContent = content;
  readonly TRADE_PATH = CONSTANTS.TRADE_PATH;
  nonVgMutualFundUrl: string;

  // Public observables/subjects...
  env$: Observable<any>;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private windowService: WindowService,
    private readonly store: Store<TayneState>,
    private readonly marketDataService: MarketDataService
  ) {}

  ngOnInit(): void {
    this.env$ = this.store.pipe(select(selectEnvironment));

    this.setEnvUrl();
    this.watchForNonVanguardMutualFund();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  open() {
    this.nonVanguardMutualFundModal.openModalDialog();
  }

  goToExternalLink() {
    this.windowService.navigateToExternalLink(this.nonVgMutualFundUrl);
  }

  cancel() {
    this.cancelEmit.emit();
    this.nonVanguardMutualFundModal.closeDialogModal();
  }

  private watchForNonVanguardMutualFund() {
    this.marketDataService.symbolRetrievalError$
      .pipe(
        filter((error) => !this.isChangeOrder && error.type === SymbolRetrievalType.NON_VG_MUTUAL_FUND),
        tap(() => this.open()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private setEnvUrl() {
    this.env$.subscribe((env) => {
      this.nonVgMutualFundUrl = `${env.origin_personal}/us/TradeTicket?investmentType=FUND_ACCESS_MUTUAL_FUND`;
    });
  }
}
