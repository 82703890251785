import { HttpErrorResponse } from '@angular/common/http';
import {
  OrderCancel,
  OrderCancelResponse,
  OrderCancelSubmissionData,
  OrderCancelValidationResponse,
} from '@etfs-equities/models/order-cancel';
import { Action } from '@ngrx/store';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum OrderCancelActionTypes {
  CLEAR_CANCEL_ORDER = '[Order Cancel] Clear Cancel Order',
  LOAD_CANCEL_ORDER = '[Order Cancel] Load Cancel Order',
  LOAD_CANCEL_ORDER_SUCCESS = '[Order Cancel] Load Cancel Order Success',
  LOAD_CANCEL_ORDER_ERROR = '[Order Cancel] Load Cancel Order Error',
  VALIDATE_ORDER_CANCEL = '[Order Cancel] Validate',
  VALIDATE_ORDER_CANCEL_SUCCESS = '[Order Cancel] Validate Success',
  VALIDATE_ORDER_CANCEL_ERROR = '[Order Cancel] Validate Error',
  UPDATE_TRIGGERED_RULES = '[Order Cancel] Update triggered rules',
  SUBMIT_ORDER_CANCEL = '[Order Cancel] Submit',
  SUBMIT_ORDER_CANCEL_SUCCESS = '[Order Cancel] Submit Success',
  SUBMIT_ORDER_CANCEL_ERROR = '[Order Cancel] Submit Error',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class ClearCancelOrderAction implements Action {
  readonly type = OrderCancelActionTypes.CLEAR_CANCEL_ORDER;
}

export class LoadCancelOrderAction implements Action {
  readonly type = OrderCancelActionTypes.LOAD_CANCEL_ORDER;
  constructor(public accountId: string, public orderId: string) {}
}

export class LoadCancelOrderSuccessAction implements Action {
  readonly type = OrderCancelActionTypes.LOAD_CANCEL_ORDER_SUCCESS;
  constructor(public payload: OrderCancel) {}
}

export class LoadCancelOrderErrorAction implements Action {
  readonly type = OrderCancelActionTypes.LOAD_CANCEL_ORDER_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export class ValidateOrderCancelAction implements Action {
  readonly type = OrderCancelActionTypes.VALIDATE_ORDER_CANCEL;
}

export class ValidateOrderCancelSuccessAction implements Action {
  readonly type = OrderCancelActionTypes.VALIDATE_ORDER_CANCEL_SUCCESS;
  constructor(public payload: OrderCancelValidationResponse) {}
}

export class ValidateOrderCancelErrorAction implements Action {
  readonly type = OrderCancelActionTypes.VALIDATE_ORDER_CANCEL_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateTriggeredRulesAction implements Action {
  readonly type = OrderCancelActionTypes.UPDATE_TRIGGERED_RULES;
  constructor(public payload: OrderCancelValidationResponse) {}
}

export class SubmitOrderCancelAction implements Action {
  readonly type = OrderCancelActionTypes.SUBMIT_ORDER_CANCEL;
  constructor(public payload: OrderCancelSubmissionData) {}
}

export class SubmitOrderCancelSuccessAction implements Action {
  readonly type = OrderCancelActionTypes.SUBMIT_ORDER_CANCEL_SUCCESS;
  constructor(public payload: OrderCancelResponse) {}
}

export class SubmitOrderCancelErrorAction implements Action {
  readonly type = OrderCancelActionTypes.SUBMIT_ORDER_CANCEL_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createClearCancelOrderAction = () => {
  return new ClearCancelOrderAction();
};

export const createLoadCancelOrderAction = (accountId: string, orderId: string) => {
  return new LoadCancelOrderAction(accountId, orderId);
};

export const createLoadCancelOrderSuccessAction = (order: OrderCancel) => {
  return new LoadCancelOrderSuccessAction(order);
};

export const createLoadCancelOrderErrorAction = (error: HttpErrorResponse) => {
  return new LoadCancelOrderErrorAction(error);
};

export const createValidateOrderCancelAction = () => {
  return new ValidateOrderCancelAction();
};

export const createValidateOrderCancelSuccessAction = (response: OrderCancelValidationResponse) => {
  return new ValidateOrderCancelSuccessAction(response);
};

export const createValidateOrderCancelErrorAction = (error: HttpErrorResponse) => {
  return new ValidateOrderCancelErrorAction(error);
};

export const createSubmitOrderCancelAction = (acceptedRules: OrderCancelSubmissionData) => {
  return new SubmitOrderCancelAction(acceptedRules);
};

export const createSubmitOrderCancelSuccessAction = (response: OrderCancelResponse) => {
  return new SubmitOrderCancelSuccessAction(response);
};

export const createSubmitOrderCancelErrorAction = (error: HttpErrorResponse) => {
  return new SubmitOrderCancelErrorAction(error);
};

export const createUpdateTriggeredRulesAction = (response: OrderCancelValidationResponse) => {
  return new UpdateTriggeredRulesAction(response);
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type OrderCancelActionTypesUnion =
  | ClearCancelOrderAction
  | LoadCancelOrderAction
  | LoadCancelOrderSuccessAction
  | LoadCancelOrderErrorAction
  | ValidateOrderCancelAction
  | ValidateOrderCancelSuccessAction
  | ValidateOrderCancelErrorAction
  | UpdateTriggeredRulesAction
  | SubmitOrderCancelAction
  | SubmitOrderCancelSuccessAction
  | SubmitOrderCancelErrorAction;
