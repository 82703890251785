<c11n-modal-dialog
  #cancelWarningModal
  [headingText]="content.alertsModal.title"
  modalId="cancelWarningModal"
  variant="warning"
>
  <div c11n-modal-dialog-body>
    <twe-rule-content [triggeredRules]="triggeredRules"></twe-rule-content>

    <div c11n-modal-dialog-custom-buttons class="c11n-modal-dialog__content__buttons">
      <c11n-button
        *ngIf="!isSubmissionWithTriggeredRules"
        [labelText]="content.cancelOrder.cancelButtonLabel"
        variant="primary"
        (clickEvent)="cancelOrder.emit(); close()"
      >
      </c11n-button>

      <c11n-button
        [labelText]="content.cancelOrder.warningSoftStopModal.cancelBtnText"
        variant="secondary"
        (clickEvent)="close()"
      >
      </c11n-button>
    </div>
  </div>
</c11n-modal-dialog>
