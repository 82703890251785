import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'tweDate',
})
export class DatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!value) {
      return null;
    }

    const parsed = moment(value, 'YYYY-MM-DDTHH:mm:ss.SSS');

    if (!parsed.isValid()) {
      return value;
    }

    const time = parsed.format('h:mm A').replace(/am/i, 'A.M.').replace(/pm/i, 'P.M.');

    const date = parsed.format('MM/DD/YYYY');

    if (args.indexOf('dateOnly') !== -1) {
      return date;
    }

    if (args.indexOf('timeOnly') !== -1) {
      return time;
    }

    return `${time} ET ${date}`;
  }
}
