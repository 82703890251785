<c11n-navigation
  id="twe-c11n-tab-nav"
  class="twe-c11n-nav twe-nav-block z-index-top"
  [ariaLabel]="content.primaryNavigation"
  [menuData]="navigationMenuData$ | async"
  overflowOption="nav-overflow"
  data-testid="twe-c11n-tab-nav"
  (activeItemChange)="handleActiveItemChange()"
  [ngClass]="{ 'hide-active-item': hideActiveItem }"
>
</c11n-navigation>
