import { Component, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';

@Component({
  selector: 'twe-duration-modal',
  templateUrl: './duration-modal.component.html',
  styleUrls: ['./duration-modal.component.scss'],
})
export class DurationModalComponent {
  @ViewChild('modal')
  modal: ModalDialogComponent;

  public content: JsonContent | undefined = content;

  open() {
    this.modal.openModalDialog();
  }
}
