<ng-container
  *ngIf="{
    actionIsBuy: (actionIsBuy$ | async),
    accountIsMargin: (accountIsMargin$ | async),
    quoteIsVgEtf: (quoteIsVgEtf$ | async)
  } as view"
>
  <div
    *ngIf="view.accountIsMargin && view.actionIsBuy && view.quoteIsVgEtf"
    class="c11n-space-stack-3x c11n-space--force"
  >
    <c11n-banner [headingText]="content?.marginRequirementsForVanguardETFsBanner.title" iconName="announcement">
      <div class="c11n-text-md--crop">
        {{ content?.marginRequirementsForVanguardETFsBanner.descr }}
      </div>
    </c11n-banner>
  </div>
</ng-container>
