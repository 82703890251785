<ng-container
  *ngIf="{
    principal: (principal$ | async),
    quoteIsVgEtf: (quoteIsVgEtf$ | async),
    amount: (amount$ | async),
    estimatedShares: (estimatedShares$ | async),
  } as view"
>
  <h2 class="c11n-text-lg-bold">{{ content.labels.orderSummary }}</h2>

  <tcx-product-details-table id="order-summary-table" [tableData]="getTableData(view)"></tcx-product-details-table>
</ng-container>
