import { Component, OnInit } from '@angular/core';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@app/core/services';
import { JsonContent } from '@app/etfs-equities/models';
import { MarketDataService, TradeTicketService } from '@app/etfs-equities/services';
import { selectQuoteIsVgETF, TayneState } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-order-validation-banner',
  templateUrl: './order-validation-banner.component.html',
  styleUrl: './order-validation-banner.component.scss',
})
export class OrderValidationBannerComponent implements OnInit {
  //  Public variables...
  content: JsonContent = content;

  // Observables...
  dollarBasedEnabled$: Observable<boolean>;
  quoteIsVgEtf$: Observable<boolean>;

  constructor(
    private readonly store: Store<TayneState>,
    public tradeTicketService: TradeTicketService,
    public marketDataService: MarketDataService,
    private readonly gatekeeperService: GatekeeperService
  ) {}

  ngOnInit(): void {
    this.dollarBasedEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_DOLLAR_BASED_TRADES
    );
    this.quoteIsVgEtf$ = this.store.select(selectQuoteIsVgETF);
  }
}
