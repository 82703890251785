<c11n-modal-dialog #modal [headingText]="content.contactUsModal.title">
  <div c11n-modal-dialog-body>
    <h3 class="contact-us__header c11n-text-md-bold">
      {{ content.contactUsModal.generalQuestions }}
    </h3>
    <div class="contact-us__phone c11n-space-stack-3x c11n-space--force">
      <div class="contact-us__number c11n-text-md-bold">
        <a
          c11n-link
          variant="primary-reinforced"
          [attr.href]="content.contactUsModal.phoneNumberGeneralLink"
          (click)="adobeService.sendAdobeTrackingOnClick('General questions Phone number', 'link', 'contact us')"
          >{{ content.contactUsModal.phoneNumberGeneral }}
          <span class="visually-hidden">
            ,{{ content.contactUsModal.generalQuestions }} ,{{ content.contactUsModal.phoneNumberText }}
          </span>
        </a>
      </div>
      <div class="contact-us__hours">
        <div class="c11n-text-sm-bold c11n-space-stack-1x">
          {{ content.contactUsModal.callDays }}
        </div>
        <div class="c11n-text-sm twe-c11n-color-ink-400">
          {{ content.contactUsModal.callTimes }}
        </div>
      </div>
    </div>
    <h3 class="contact-us__header c11n-text-md-bold">
      {{ content.contactUsModal.ttyTdd }}
    </h3>
    <div class="contact-us__phone c11n-space-stack-4x c11n-space--force">
      <div class="contact-us__number c11n-text-md-bold">
        <a
          c11n-link
          variant="primary-reinforced"
          [attr.href]="content.contactUsModal.phoneNumberTTYLink"
          (click)="adobeService.sendAdobeTrackingOnClick('TTY/TTD Phone number', 'link', 'contact us')"
        >
          {{ content.contactUsModal.phoneNumberTTY }}
          <span class="visually-hidden">
            ,{{ content.contactUsModal.ttyTdd }} ,{{ content.contactUsModal.phoneNumberText }}
          </span>
        </a>
      </div>
      <div class="contact-us__hours">
        <div class="c11n-text-sm-bold c11n-space-stack-1x">
          {{ content.contactUsModal.callDays }}
        </div>
        <div class="c11n-text-sm twe-c11n-color-ink-400">
          {{ content.contactUsModal.callTimes }}
        </div>
      </div>
    </div>
    <p class="c11n-text-sm mb-0">
      {{ content.contactUsModal.contactUsBody }}
    </p>
  </div>
</c11n-modal-dialog>
