import { HttpErrorResponse } from '@angular/common/http';
import { EcnHours } from '@etfs-equities/models/exchange.model';
import { SetExtendedHoursAgreementRequestEcnStatusEnum } from '@vanguard/invest-api-client-typescript-axios';

export interface ExtendedHoursState {
  ecnHours: EcnHours | null;
  ecnAgreement: ECNAgreement | null;
}

export interface ECNAgreement {
  status: SetExtendedHoursAgreementRequestEcnStatusEnum | null;
  error: HttpErrorResponse | null;
}

export const initialExtendedHoursState: ExtendedHoursState = {
  ecnHours: null,
  ecnAgreement: null,
};
