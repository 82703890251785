import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { DataLayerEventsEnum } from '@app/core/models/adobe-launch';
import { AdobeAnalyticsService, GatekeeperService } from '@app/core/services';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { OrderEnums } from '@app/etfs-equities/enums';
import { Account, JsonContent, Order, Quote } from '@app/etfs-equities/models';
import { AccountService, TradeTicketService, WindowService } from '@app/etfs-equities/services';
import {
  createClearAvailableMethodsAction,
  createClearHistoryUrlAction,
  createClearKeywordSearchAction,
  createClearOrderAction,
  createClearQuoteAction,
  createClearTradeTicketAction,
  createQuoteExpandClosePanelAction,
  createRefreshAccountsAction,
  createRefreshCostBasisForSelectedAccountAction,
  createRefreshFundsAvailableAction,
  createSetIsChangeOrderAction,
  createUpdateExtendedTradingTabLinkAction,
  createUpdateLotFormAction,
  createUpdateTradeTicketTabLinkAction,
  selectIsChangeOrderState,
  selectIsEditCostBasisState,
  selectIsSellingAllHeldShares,
  selectOrder,
  selectOrderHasExceededFundsAvailable,
  selectQuote,
  selectSelectedAccount,
  selectShouldDisplayProspectusLink,
  TayneState,
} from '@app/etfs-equities/store';
import { selectEnvironment } from '@app/etfs-equities/store/selectors/environment/environment.selectors';
import { FocusOnElementUtility } from '@app/shared/utilities/focus-element/focus-element.utility';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

export const adobeLinkData = {
  callToActionLocation: 'equityETFTicket-confirm',
  callToActionType: 'link',
  processType: 'Brokerage Path',
};

@Component({
  selector: 'twe-confirm',
  templateUrl: './confirm-page.component.html',
  styleUrls: ['./confirm-page.component.scss'],
})
export class ConfirmPageComponent implements OnInit, OnDestroy, AfterViewInit {
  // Public observables/subjects...

  env$: Observable<any>;
  order$: Observable<Order.Order>;
  orderHasExceededFundsAvailable$: Observable<boolean>;
  selectedAccount$: Observable<Account.Account>;
  shouldDisplayProspectusLink$: Observable<boolean>;
  isSellingAllHeldShares$: Observable<boolean>;
  dollarBasedTradesEnabled$: Observable<boolean>;
  quote$: Observable<Quote>;
  isChangeOrder$: Observable<boolean>;
  isEditCostBasis$: Observable<boolean>;
  isTplus1Enabled$: Observable<boolean>;

  // Public variables...
  secureSiteUrls = environment.secureSiteUrls;
  BUYSELL_ROUTER_URL = '/us/BuySellRouter?investmentType=MUTL&transactionType=';
  BUY_TRANSACTION = 'BUY';
  SELL_TRANSACTION = 'SEL';
  EXCHANGE_TRANSACTION = 'EXCH';

  transactionTypes = OrderEnums.TransactionTypes;
  isBeacon = false;
  content: JsonContent = content;
  readonly TRADE_PATH = CONSTANTS.TRADE_PATH;
  readonly CANCEL_PATH = CONSTANTS.CANCEL_PATH;

  constructor(
    private readonly tradeTicketService: TradeTicketService,
    public readonly accountService: AccountService,
    public readonly windowService: WindowService,
    public readonly adobeService: AdobeAnalyticsService,
    private readonly store: Store<TayneState>,
    private readonly gatekeeperService: GatekeeperService
  ) {}

  ngOnInit() {
    this.env$ = this.store.pipe(select(selectEnvironment));
    this.order$ = this.store.pipe(select(selectOrder));
    this.orderHasExceededFundsAvailable$ = this.store.pipe(select(selectOrderHasExceededFundsAvailable));
    this.shouldDisplayProspectusLink$ = this.store.pipe(select(selectShouldDisplayProspectusLink));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.isBeacon = this.windowService.getIsBeacon();
    this.isSellingAllHeldShares$ = this.store.pipe(select(selectIsSellingAllHeldShares));
    this.quote$ = this.store.pipe(select(selectQuote));
    this.isChangeOrder$ = this.store.pipe(select(selectIsChangeOrderState));
    this.isEditCostBasis$ = this.store.pipe(select(selectIsEditCostBasisState));

    this.dollarBasedTradesEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_DOLLAR_BASED_TRADES
    );
    this.isTplus1Enabled$ = this.gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_TPLUS1_CONTENT);

    this.order$
      .pipe(
        tap(() => this.store.dispatch(createRefreshFundsAvailableAction())),
        filter((order: Order.Order) => order && order.orderId && order.orderId.length > 0),
        tap((order) => {
          if (order.costBasisDetailsToChangeCbaMethod) {
            this.store.dispatch(createRefreshAccountsAction());
            this.store.dispatch(createRefreshCostBasisForSelectedAccountAction());
            this.store.dispatch(createClearAvailableMethodsAction());
          }
        }),
        withLatestFrom(this.selectedAccount$),
        take(1)
      )
      .subscribe(([order, account]) => this.sendAdobeTrackLinkCall(order, account));

    FocusOnElementUtility.focus('h1-sr-only');
  }

  ngAfterViewInit() {
    this.shouldDisplayProspectusLink$
      .pipe(
        take(1),
        filter((shouldDisplayProspectusLink) => shouldDisplayProspectusLink === true),
        switchMap(() => this.store.select(selectOrder)),
        take(1)
      )
      .subscribe();
    globalThis.randomNum = Math.floor(Math.random() * 4) + 1;
  }

  ngOnDestroy() {
    this.accountService.openOrdersResponse$ = undefined;
    this.tradeTicketService.costBasisIsVisible = false;
    this.tradeTicketService.tradeTicket.controls.amount.clearAsyncValidators();
    this.tradeTicketService.resetForm();
    this.tradeTicketService.setSharesValidators();
    this.store.dispatch(createQuoteExpandClosePanelAction(false));
    this.store.dispatch(createUpdateTradeTicketTabLinkAction(CONSTANTS.TRADE_PATH));
    this.store.dispatch(createUpdateExtendedTradingTabLinkAction(CONSTANTS.EXTENDED_TRADING_PATH));
    this.store.dispatch(createClearOrderAction());
    this.store.dispatch(createClearQuoteAction());
    this.store.dispatch(createClearTradeTicketAction());
    this.store.dispatch(createUpdateLotFormAction(null));
    this.store.dispatch(createClearKeywordSearchAction());
    this.store.dispatch(createSetIsChangeOrderAction(false));
    this.store.dispatch(createClearHistoryUrlAction());
  }

  printComponent(): void {
    window.print();
  }

  formatAmount(amount: number): string {
    return Number(amount).toFixed(2);
  }

  private sendAdobeTrackLinkCall(order: Order.Order, account: Account.Account) {
    this.adobeService.processPush(DataLayerEventsEnum.PROCESS_SIDE_STEP_SUCCESS, {
      processSideStep: `Brokerage Path - Process Complete:${order.transactionType}:${order.amountType}`,
    });

    this.adobeService.processPush(DataLayerEventsEnum.PROCESS_COMPLETE, {
      tickerSymbol: order.ticker,
      accountType: account.accountType,
      transactionId: order.orderId,
      amountTransacted:
        order.amountType === OrderEnums.AmountTypes.DOLLARS
          ? order.shares.toString()
          : order.estimatedAmount.toString(),
    });
  }
}
