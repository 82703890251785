import { ClientDataActionTypes, ClientDataActionTypesUnion } from '../../actions/client-data/client-data.action';
import { ClientDataState, initialClientDataState } from '../../states';

export const clientDataReducer = (
  state: ClientDataState = initialClientDataState,
  action: ClientDataActionTypesUnion
): ClientDataState => {
  switch (action.type) {
    case ClientDataActionTypes.SET_INCAPACITATED:
      return { ...state, isIncapacitated: action.payload };

    case ClientDataActionTypes.SET_EXIT_BTN_STATUS:
      return { ...state, isExitBtnClicked: action.payload };

    case ClientDataActionTypes.SET_CLIENT_ID:
      return { ...state, clientId: action.payload };

    default:
      return state;
  }
};
