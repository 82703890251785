export enum UILogCode {
  ACCOUNT = 'ui-account',
  ACCOUNT_ERROR = 'ui-account-error',
  AUTH = 'ui-auth',
  ADOBE_ERROR = 'ui-adobe-error',
  BUY_SELL_ROUTER = 'ui-buy-sell-router',
  CLIENT_FLAGS = 'ui-client-flags',
  COMMISSIONS_FEES = 'ui-commissions-fees',
  COST_BASIS = 'ui-cost-basis',
  COST_BASIS_ERROR = 'ui-cost-basis-error',
  FRAPI = 'ui-frapi',
  FUNDS_AVAILABLE = 'ui-funds-available',
  GATE_KEEPER = 'ui-gate-keeper',
  GATE_KEEPER_ERROR = 'ui-gate-keeper-error',
  LOGGING = 'ui-logging',
  OPEN_ORDERS = 'ui-open-orders',
  ORDER = 'ui-order',
  ORDER_ERROR = 'ui-order-error',
  ORDER_SERVICE = 'ui-order-service',
  ORDER_SERVICE_ERROR = 'ui-order-service-error',
  PROSPECTUS = 'ui-prospectus',
  PROSPECTUS_LINK = 'ui-prospectus-link',
  PROSPECTUS_LINK_ERROR = 'ui-prospectus-link-error',
  REAL_TIME_MARKET_DATA = 'ui-real-time-market-data',
  REAL_TIME_MARKET_DATA_ERROR = 'ui-real-time-market-data-error',
  RULES_CONTENT = 'ui-rules-content',
  RULE_ENGINE = 'ui-rule-engine',
  SYMBOL_LOOKUP = 'ui-symbol-lookup',
  TRADE_GUIDANCE = 'ui-trade-guidance',
}
