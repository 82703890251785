import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { selectActionIsSell, selectDollarValueOfSharesHeld } from '@app/etfs-equities/store';
import { TayneState } from '@app/etfs-equities/store/states/tayne.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HoldLimitDollarsValidator implements AsyncValidator {
  constructor(private readonly store: Store<TayneState>) {}

  validate(control: AbstractControl): Observable<ValidationErrors> {
    return this.store.pipe(
      withLatestFrom(this.store.select(selectDollarValueOfSharesHeld), this.store.select(selectActionIsSell)),
      take(1),
      map(([_action, balance, isSell]) => {
        return isSell && balance !== null && parseFloat(control.value) > +balance ? { holdLimitDollars: true } : null;
      })
    );
  }
}
