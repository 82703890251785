import { Component, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import { WindowService } from '@app/etfs-equities/services';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';

@Component({
  selector: 'twe-single-stock-price-bands-modal',
  templateUrl: './single-stock-price-bands-modal.component.html',
  styleUrls: ['./single-stock-price-bands-modal.component.scss'],
})
export class SingleStockPriceBandsModalComponent {
  //  Decorators...
  @ViewChild('modal') modal: ModalDialogComponent;

  //  Public variables...
  content: JsonContent = content;

  constructor(public readonly windowService: WindowService) {}

  open() {
    this.modal.openModalDialog();
  }
}
