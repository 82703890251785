import { HttpErrorResponse } from '@angular/common/http';
import { CostBasis, Order } from '@app/etfs-equities/models';
import { AcceptedRulesNextStep } from '@etfs-equities/enums/triggered-rule.enums';
import { Action } from '@ngrx/store';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum OrderActionTypes {
  VALIDATE_ORDER = '[Order] Validate',
  VALIDATE_ORDER_SUCCESS = '[Order] Validate Success',
  VALIDATE_ORDER_ERROR = '[Order] Validate Error',
  SUBMIT_ORDER = '[Order] Submit',
  SUBMIT_ORDER_SUCCESS = '[Order] Submit Success',
  SUBMIT_ORDER_ERROR = '[Order] Submit Error',
  CLEAR_ORDER = '[Order] Clear',
  ACCEPT_TRIGGERED_RULES = '[Order] Accept Triggered Rules',
  UPDATE_SELECTED_LOTS = '[Order] Update Selected Lots',
  LOAD_CHANGE_ORDER = '[Order] Load Change Order',
  LOAD_CHANGE_ORDER_SUCCESS = '[Order] Load Change Success',
  LOAD_CHANGE_ORDER_ERROR = '[Order] Load Change Error',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class ValidateOrderAction implements Action {
  readonly type = OrderActionTypes.VALIDATE_ORDER;
  constructor(public isChangeOrder: boolean, public selectedLots: CostBasis.SelectedLot[]) {}
}

export class ValidateOrderSuccessAction implements Action {
  readonly type = OrderActionTypes.VALIDATE_ORDER_SUCCESS;
  constructor(public payload: Order.Order) {}
}

export class ValidateOrderErrorAction implements Action {
  readonly type = OrderActionTypes.VALIDATE_ORDER_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export class SubmitOrderAction implements Action {
  readonly type = OrderActionTypes.SUBMIT_ORDER;
}

export class SubmitOrderSuccessAction implements Action {
  readonly type = OrderActionTypes.SUBMIT_ORDER_SUCCESS;
  constructor(public payload: Order.Order) {}
}

export class SubmitOrderErrorAction implements Action {
  readonly type = OrderActionTypes.SUBMIT_ORDER_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export class ClearOrderAction implements Action {
  readonly type = OrderActionTypes.CLEAR_ORDER;
}

export class AcceptTriggeredRulesAction implements Action {
  readonly type = OrderActionTypes.ACCEPT_TRIGGERED_RULES;
  constructor(public payload: Order.TriggeredRule[], public acceptedRulesNextStep: AcceptedRulesNextStep) {}
}

export class UpdateSelectedLotsAction implements Action {
  readonly type = OrderActionTypes.UPDATE_SELECTED_LOTS;
  constructor(public payload: CostBasis.SelectedLot[]) {}
}
export class LoadChangeOrderAction implements Action {
  readonly type = OrderActionTypes.LOAD_CHANGE_ORDER;
  constructor(public accountId: string, public orderId: string) {}
}
export class LoadChangeOrderSuccessAction implements Action {
  readonly type = OrderActionTypes.LOAD_CHANGE_ORDER_SUCCESS;
  constructor(public payload: Order.Order) {}
}
export class LoadChangeOrderErrorAction implements Action {
  readonly type = OrderActionTypes.LOAD_CHANGE_ORDER_ERROR;
  constructor(public error: HttpErrorResponse) {}
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createValidateOrderAction = (isChangeOrder: boolean, selectedLots?: CostBasis.SelectedLot[]) => {
  return new ValidateOrderAction(isChangeOrder, selectedLots);
};

export const createValidateOrderSuccessAction = (order: Order.Order) => {
  return new ValidateOrderSuccessAction(order);
};

export const createValidateOrderErrorAction = (error: HttpErrorResponse) => {
  return new ValidateOrderErrorAction(error);
};

export const createSubmitOrderAction = () => {
  return new SubmitOrderAction();
};

export const createSubmitOrderSuccessAction = (order: Order.Order) => {
  return new SubmitOrderSuccessAction(order);
};

export const createSubmitOrderErrorAction = (error: HttpErrorResponse) => {
  return new SubmitOrderErrorAction(error);
};

export const createClearOrderAction = () => {
  return new ClearOrderAction();
};

export const createAcceptTriggeredRulesAction = (
  rules: Order.TriggeredRule[],
  acceptedRulesNextStep: AcceptedRulesNextStep
) => {
  return new AcceptTriggeredRulesAction(rules, acceptedRulesNextStep);
};

export const createUpdateSelectedLotsAction = (lots: CostBasis.SelectedLot[]) => {
  return new UpdateSelectedLotsAction(lots);
};

export const createLoadChangeOrderAction = (accountId: string, orderId: string) => {
  return new LoadChangeOrderAction(accountId, orderId);
};

export const createLoadChangeOrderSuccessAction = (order: Order.Order) => {
  return new LoadChangeOrderSuccessAction(order);
};

export const createLoadChangeOrderErrorAction = (error: HttpErrorResponse) => {
  return new LoadChangeOrderErrorAction(error);
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type OrderActionTypesUnion =
  | ValidateOrderAction
  | ValidateOrderSuccessAction
  | ValidateOrderErrorAction
  | ClearOrderAction
  | SubmitOrderAction
  | SubmitOrderSuccessAction
  | SubmitOrderErrorAction
  | AcceptTriggeredRulesAction
  | UpdateSelectedLotsAction
  | LoadChangeOrderAction
  | LoadChangeOrderSuccessAction
  | LoadChangeOrderErrorAction;
