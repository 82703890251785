export enum TweOpenOrdersOrderCategoryTypesEnum {
  MARKET = 'MKT',
  LIMIT = 'LMT',
  STOP = 'STP',
  OR_BETTER = 'OB',
  STOP_LIMIT = 'STL',
  CLOSED = 'CLO',
  WITH_OR_WITHOUT = 'WOW',
  MARKET_ON_CLOSE = 'MCL',
}

export enum TweOpenOrdersOrderDurationTypesEnum {
  DAY = 'DAY',
  IMMEDIATE_OR_CANCEL = 'IOC',
  GOOD_TILL_DAY = 'GTD',
  SIXTY_DAY = 'GTC',
  EVENING = 'EVE',
  FILL_OR_KILL = 'FOK',
}

export enum TweOpenOrdersOrderStatusTypesEnum {
  OPEN = 'OPEN',
  IN_PROGRESS = 'INPR',
  ENTERED = 'ENTR',
  EXPIRED = 'EXPR',
  EXECUTED = 'EXEC',
  CANCELED = 'CNCL',
  DROPPED = 'DROP',
  REJECTED = 'REJC',
  PARTIAL_CANCEL = 'PCAN',
  PARTIAL_EXECUTION = 'EXEP',
  PENDING_CANCEL = 'PECN',
  PENDING_CHANGE = 'PCHG',
  COMPLETE = 'CMPL',
  PENDING = 'PNDG',
  UNKNOWN = 'UNKNOWN',
}

export enum TweOpenOrdersOrderStatusCodeTypesEnum {
  OPEN = 'OPEN',
  ENTERED = 'ENTR',
  CANCELED = 'CNCL',
  COMPLETE = 'CMPL',
  PENDING = 'PNDG',
  REJECTED = 'REJC',
  EXPIRED = 'EXPR',
}

export enum TweOpenOrderStatusDisplayText {
  OPEN = 'Open',
  IN_PROGRESS = 'In progress',
  ENTERED = 'Entered',
  EXPIRED = 'Expired',
  EXECUTED = 'Executed',
  CANCELED = 'Canceled',
  DROPPED = 'Dropped',
  REJECTED = 'Rejected',
  PARTIAL_CANCEL = 'Partial cancel',
  PARTIAL_EXECUTION = 'Partial execution',
  PENDING_CANCEL = 'Pending cancel',
  PENDING_CHANGE = 'Pending change',
  COMPLETE = 'Complete',
  PENDING = 'Pending',
  UNKNOWN = 'Unknown',
}

export enum TweOpenOrdersOrderInstructionCodeEnum {
  BUY = 'BUY',
  BUY_MINUS = 'BUYM',
  BUY_TO_COVER = 'BUYC',
  BUY_REVERSAL = 'BUYR',
  EXCHANGE = 'EXCH',
  EXCHANGE_ALL = 'EXAL',
  EXCHANGE_REVERSAL = 'EXCR',
  SELL = 'SEL',
  SELL_TRANSFER = 'SELT',
  SELL_PLUS = 'SELP',
  SELL_SHORT = 'SHOR',
  SELL_REVERSAL = 'SELR',
  SSLOCEX = 'SSLX',
}

export enum CardCapComponentBackgroundColors {
  YELLOW = 'yellow',
  TURQUOISE = 'turquoise',
  INK = 'ink',
  RED = 'red',
}
