import { Component, OnInit } from '@angular/core';
import { JsonContent, Order, Quote } from '@app/etfs-equities/models';
import { selectOrder, selectQuote, TayneState } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-executed-price',
  templateUrl: './executed-price.component.html',
  styleUrls: ['./executed-price.component.scss'],
})
export class ExecutedPriceComponent implements OnInit {
  quote$: Observable<Quote>;
  order$: Observable<Order.Order>;

  content: JsonContent = content;

  constructor(private readonly store: Store<TayneState>) {}

  ngOnInit() {
    this.quote$ = this.store.pipe(select(selectQuote));
    this.order$ = this.store.pipe(select(selectOrder));
  }
}
