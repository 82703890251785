export const MASKS = {
  FOUR_DECIMALS_CURRENCY: {
    mask: '$VALUE',
    lazy: false,
    blocks: {
      VALUE: {
        mask: Number,
        scale: 4,
        signed: false,
        thousandsSeparator: ',',
        padFractionalZeros: true,
        radix: '.',
      },
    },
  },
};
