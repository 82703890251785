import { Component } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import { TradeTicketService } from '@app/etfs-equities/services';
import content from '@content/content.json';

@Component({
  selector: 'twe-all-or-none-control',
  templateUrl: './all-or-none-control.component.html',
  styleUrls: ['./all-or-none-control.component.scss'],
})
export class AllOrNoneControlComponent {
  content: JsonContent = content;

  constructor(public tradeTicketService: TradeTicketService) {}
}
