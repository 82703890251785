import { Component, Input } from '@angular/core';
import { OrderEnums } from '@app/etfs-equities/enums';
import { JsonContent, Order } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { environment } from '@env/environment';

@Component({
  selector: 'twe-change-order-detail',
  templateUrl: './change-order-detail.component.html',
  styleUrls: ['./change-order-detail.component.scss'],
})
export class ChangeOrderDetailComponent {
  // Decorators...
  @Input() order: Order.Order;
  @Input() title: string;
  @Input() isChangeOrder = false;

  // Public variables...
  content: JsonContent = content;
  orderTypes = OrderEnums.Types;
  orderStatusLink = environment.secureSiteUrls.orderStatus;
}
