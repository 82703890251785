import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SymbolRetrievalType, TweLookupTypes } from '@app/etfs-equities/enums';
import { MarketDataService, TradeTicketService } from '@app/etfs-equities/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
  createLoadQuoteErrorAction,
  createLoadQuoteSuccessAction,
  LoadQuoteAction,
  LoadQuoteByCusipAction,
  LoadQuoteErrorAction,
  LoadQuoteSuccessAction,
  QuoteActionTypes,
} from '../../actions';
import { selectQuote } from '../../selectors';
import { TayneState } from '../../states';

@Injectable()
export class QuoteEffects {
  loadQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActionTypes.LOAD_QUOTE),
      switchMap((action: LoadQuoteAction) =>
        this.marketDataService.loadQuote(action.payload).pipe(
          map((quote) => createLoadQuoteSuccessAction(quote)),
          catchError((error: HttpErrorResponse) => of(createLoadQuoteErrorAction(error)))
        )
      )
    )
  );

  loadQuoteByCusip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActionTypes.LOAD_QUOTE_BY_CUSIP),
      switchMap((action: LoadQuoteByCusipAction) =>
        this.marketDataService.loadQuote(action.payload.cusip, TweLookupTypes.CUSIP, action.payload.symbol).pipe(
          map((quote) => createLoadQuoteSuccessAction(quote)),
          catchError((error: HttpErrorResponse) => of(createLoadQuoteErrorAction(error)))
        )
      )
    )
  );

  refreshQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActionTypes.REFRESH_QUOTE),
      withLatestFrom(this.store.select(selectQuote)),
      exhaustMap(([_action, selectedQuote]) =>
        this.marketDataService.refreshQuote(selectedQuote?.cusip).pipe(
          map((quote) => createLoadQuoteSuccessAction(quote)),
          catchError((error: HttpErrorResponse) => of(createLoadQuoteErrorAction(error)))
        )
      )
    )
  );

  handleQuoteError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuoteActionTypes.LOAD_QUOTE_ERROR),
        tap((action: LoadQuoteErrorAction) => {
          const errorResponse = action.payload;
          const data = errorResponse.error?.data;
          this.marketDataService.updateFlags();
          let searchWasForVgMutualFund: boolean;
          let searchWasForNonVgMutualFund: boolean;

          if (data) {
            searchWasForVgMutualFund = data.securityType === SymbolRetrievalType.MUTUAL_FUND && !data.isFundAccess;
            searchWasForNonVgMutualFund = data.securityType === SymbolRetrievalType.MUTUAL_FUND && data.isFundAccess;
          }

          if (
            (errorResponse.status === HttpStatusCode.NotFound || errorResponse.status === HttpStatusCode.MultiStatus) &&
            !searchWasForVgMutualFund &&
            !searchWasForNonVgMutualFund
          ) {
            this.marketDataService.symbolNotFound = true;
          } else {
            let type: SymbolRetrievalType = SymbolRetrievalType.ETF_EQUITY;

            if (searchWasForVgMutualFund) {
              type = SymbolRetrievalType.MUTUAL_FUND;
            }

            if (searchWasForNonVgMutualFund) {
              type = SymbolRetrievalType.NON_VG_MUTUAL_FUND;
            }

            this.marketDataService.symbolRetrievalError$.next({
              logEntry: {
                error: errorResponse,
                serviceName: 'QuoteEffects.handleQuoteError',
              },
              type,
            });
          }
        })
      ),
    { dispatch: false }
  );

  loadQuoteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuoteActionTypes.LOAD_QUOTE_SUCCESS),
        tap((action: LoadQuoteSuccessAction) => {
          const quoteTicker = action.payload.tickerSymbol;
          if (quoteTicker && this.tradeTicketService.getSymbol() !== quoteTicker) {
            this.tradeTicketService.setSymbol(quoteTicker, true);
            this.marketDataService.lastSymbol = quoteTicker;
          }
          this.tradeTicketService.quoteLoaded();
          this.marketDataService.emitLoadQuoteSuccess$.next();
        })
      ),
    { dispatch: false }
  );

  clearQuote$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuoteActionTypes.CLEAR_QUOTE),
        tap(() => {
          this.marketDataService.clearLastSymbolSearched();
          this.marketDataService.clearSymbolNotFoundError();
          this.tradeTicketService.quoteCleared();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly tradeTicketService: TradeTicketService,
    private readonly marketDataService: MarketDataService,
    private readonly actions$: Actions,
    private readonly store: Store<TayneState>
  ) {}
}
