import { HttpErrorResponse } from '@angular/common/http';
import { Quote } from '@app/etfs-equities/models';
import { Action } from '@ngrx/store';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum QuoteActionTypes {
  LOAD_QUOTE = '[Quote] Load',
  LOAD_QUOTE_BY_CUSIP = '[Quote] Load By Cusip',
  LOAD_QUOTE_SUCCESS = '[Quote] Load Success',
  LOAD_QUOTE_ERROR = '[Quote] Load Error',
  CLEAR_QUOTE = '[Quote] Clear',
  REFRESH_QUOTE = '[Quote] Refresh',
  SET_QUOTE = '[Quote] Set',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class LoadQuoteAction implements Action {
  readonly type = QuoteActionTypes.LOAD_QUOTE;
  constructor(public payload: string) {}
}

export class LoadQuoteByCusipAction implements Action {
  readonly type = QuoteActionTypes.LOAD_QUOTE_BY_CUSIP;
  constructor(public payload: { cusip: string; symbol: string }) {}
}

export class LoadQuoteSuccessAction implements Action {
  readonly type = QuoteActionTypes.LOAD_QUOTE_SUCCESS;
  constructor(public payload: Quote) {}
}

export class LoadQuoteErrorAction implements Action {
  readonly type = QuoteActionTypes.LOAD_QUOTE_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export class ClearQuoteAction implements Action {
  readonly type = QuoteActionTypes.CLEAR_QUOTE;
}

export class RefreshQuoteAction implements Action {
  readonly type = QuoteActionTypes.REFRESH_QUOTE;
}

export class SetQuoteAction implements Action {
  readonly type = QuoteActionTypes.SET_QUOTE;
  constructor(public payload: Quote) {}
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createLoadQuoteAction = (symbol: string) => {
  return new LoadQuoteAction(symbol);
};

export const createLoadQuoteByCusipAction = (cusip: string, symbol: string) => {
  return new LoadQuoteByCusipAction({ cusip, symbol });
};

export const createLoadQuoteSuccessAction = (quote: Quote) => {
  return new LoadQuoteSuccessAction(quote);
};

export const createLoadQuoteErrorAction = (error: HttpErrorResponse) => {
  return new LoadQuoteErrorAction(error);
};

export const createClearQuoteAction = () => {
  return new ClearQuoteAction();
};

export const createRefreshQuoteAction = () => {
  return new RefreshQuoteAction();
};

export const createSetQuoteAction = (quote: Quote) => {
  return new SetQuoteAction(quote);
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type QuoteActionTypesUnion =
  | LoadQuoteAction
  | LoadQuoteByCusipAction
  | LoadQuoteSuccessAction
  | LoadQuoteErrorAction
  | ClearQuoteAction
  | RefreshQuoteAction
  | SetQuoteAction;
