import { Injectable, NgZone } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';

@Injectable()
export class NgZoneUtility {
  constructor(private readonly ngZone: NgZone) {}

  runInZone(fn: () => void) {
    this.ngZone.run(fn);
  }

  bridgeZones<T>(): OperatorFunction<T, T> {
    return (input$) =>
      new Observable((subscriber) => {
        const subscription = input$.subscribe({
          next: (value) => this.runInZone(() => subscriber.next(value)),
          complete: () => this.runInZone(() => subscriber.complete()),
          error: (err) => this.runInZone(() => subscriber.error(err)),
        });

        return () => subscription.unsubscribe();
      });
  }
}
