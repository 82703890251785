import { Action } from '@ngrx/store';

export enum ScreenSizeActionTypes {
  SET_SCREEN_SIZE = '[Screen Size] Set',
}

export class SetScreenSizeAction implements Action {
  readonly type = ScreenSizeActionTypes.SET_SCREEN_SIZE;
  constructor(public payload: 'LG' | 'SM' | 'XS') {}
}

export const createSetScreenSizeAction = (screenSize: 'LG' | 'SM' | 'XS') => {
  return new SetScreenSizeAction(screenSize);
};

export type ScreenSizeActionsUnion = SetScreenSizeAction;
