import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CashMarginIndicatorTypes, OrderEnums } from '@app/etfs-equities/enums';
import { Account, JsonContent } from '@app/etfs-equities/models';
import { OrderCancel } from '@app/etfs-equities/models/order-cancel';
import { selectSelectedAccount, TayneState } from '@app/etfs-equities/store';
import {
  selectOrderCancel,
  selectOrderCancelFormatSharesDisplay,
} from '@app/etfs-equities/store/selectors/order-cancel/order-cancel.selector';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'twe-cancel-order-detail',
  templateUrl: './cancel-order-detail.component.html',
  styleUrls: ['./cancel-order-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancelOrderDetailComponent implements OnInit, OnDestroy {
  //  Public observables/subjects...

  orderCancel$: Observable<OrderCancel>;
  selectedAccount$: Observable<Account.Account>;
  formatSharesDisplay$: Observable<string>;
  unsubscribe$: Subject<void> = new Subject();

  //  Public variables...

  content: JsonContent = content;
  enumAmountTypes = OrderEnums.AmountTypes;
  enumActions = OrderEnums.TransactionTypes;
  marginIndicator = CashMarginIndicatorTypes.MARGIN;

  constructor(private readonly store: Store<TayneState>) {}

  ngOnInit(): void {
    this.orderCancel$ = this.store.pipe(select(selectOrderCancel));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.formatSharesDisplay$ = this.store.pipe(select(selectOrderCancelFormatSharesDisplay));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getDynamicContent(orderDetail: OrderCancel): string | null {
    switch (orderDetail?.orderType) {
      case OrderEnums.Types.MARKET:
        return orderDetail.amountType === this.enumAmountTypes.DOLLARS
          ? this.content.dynamicContentDollarBasedOrders
          : this.content.dynamicContentMarketOrder;
      case OrderEnums.Types.STOP:
        return this.content.dynamicContentStopOrders;
      case OrderEnums.Types.LIMIT:
      case OrderEnums.Types.STOP_LIMIT:
        return this.content.dynamicContentLimitAndStopOrders;
      default:
        return null;
    }
  }
}
