<div
  class="container-fluid"
  *ngIf="{
    order: (order$ | async),
    quote: (quote$ | async),
    userHistoryUrl: (userHistoryUrl$ | async)
  } as view"
>
  <twe-service-unavailable-msg *ngIf="isOrderDetailsError" iconType="warning">
    <p class="twe-c11n-color-ink-400 c11n-space-stack-0_5x" [innerHTML]="content?.editCostBasis.orderDetailsErrorMsg">
    </p>
    <p class="mt-4">
      <a
        c11n-link
        tabindex="0"
        (click)="handleBackClick(view.userHistoryUrl)"
        (keydown.enter)="handleBackClick(view.userHistoryUrl)"
      >
        {{ content?.labels.goBack }}
      </a>
    </p>
  </twe-service-unavailable-msg>

  <div class="row" *ngIf="!isOrderDetailsError">
    <twe-accounts-control
      [isChangeOrder]="isChangeOrder"
      (hasAccountRetrievalErrorEmitter)="setHasAccountRetrievalError($event)"
    ></twe-accounts-control>

    <twe-accounts-error [showAsPage]="false"></twe-accounts-error>

    <div class="col-lg-6 col-xxl-4 margin-sm">
      <div class="d-block d-xxl-none">
        <twe-funds-available> </twe-funds-available>
      </div>
      <div class="c11n-space-stack-3x d-block">
        <twe-change-order-detail [title]="content?.editCostBasis.title" [order]="view.order"></twe-change-order-detail>
      </div>
      <div class="c11n-space-stack-3x">
        <twe-cost-basis-control [isChangeOrder]="isChangeOrder"></twe-cost-basis-control>
      </div>
    </div>

    <div class="col-lg-6 col-xxl-4 margin-sm">
      <twe-executed-price class="d-block c11n-space-stack-3x"></twe-executed-price>

      <twe-trade-detail
        class="d-block d-xxl-none"
        [isChangeOrder]="isChangeOrder"
        [isEditCostBasis]="true"
        (preview)="validateCostBasis()"
      ></twe-trade-detail>
    </div>

    <div class="col-lg-6 col-xxl-4 c11n-space-stack-5x c11n-space--force d-none d-xxl-block">
      <div class="c11n-space-stack-3x">
        <twe-funds-available> </twe-funds-available>
      </div>

      <div>
        <twe-trade-detail
          [isChangeOrder]="isChangeOrder"
          [isEditCostBasis]="true"
          (preview)="validateCostBasis()"
        ></twe-trade-detail>
      </div>
    </div>
  </div>
</div>
<twe-no-changes-error-modal></twe-no-changes-error-modal>
