import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { UILogCode } from '@app/core/enums/ui-log-code-enum';
import { DataLayerEventsEnum, PageInitialize } from '@app/core/models/adobe-launch';
import { AdobeAnalyticsService, LoggerService } from '@app/core/services';
import { TradeRoute } from '@app/etfs-equities/models';
import { filter, map } from 'rxjs/operators';

import pkg from '../../../../../package.json';

@Component({
  selector: 'twe-adobe-analytics',
  template: '',
  styleUrls: [],
})
export class AdobeAnalyticsComponent implements OnInit {
  static APP_VERSION = pkg.version;

  constructor(
    private readonly adobeAnalytics: AdobeAnalyticsService,
    private readonly logger: LoggerService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    // Log errors that might occur.
    this.adobeAnalytics.errors$.subscribe((err: Error) => {
      this.logger.error(UILogCode.ADOBE_ERROR, {
        error: err.message,
      });
    });

    // Send track calls on route changes.
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map(() => this.router.routerState.snapshot.root),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter((route: ActivatedRouteSnapshot & TradeRoute) => !!route.data.analytics)
      )
      .subscribe((route) => {
        const page: PageInitialize = {
          pageTemplate: route.data.pageTitle,
          siteSection1: route.data.pageHeading,
        };

        this.adobeAnalytics.initializePage(page);

        if (route.data.analytics.processStartBrokeragePath) {
          this.adobeAnalytics.processPush(DataLayerEventsEnum.PROCESS_START, {
            processType: route.data.analytics.processType,
          });
        }

        if (route.data.analytics.processStep) {
          this.adobeAnalytics.processPush(DataLayerEventsEnum.PROCESS_STEP_SUCCESS, {
            processStep: route.data.analytics.processStep,
          });
        }
      });
  }
}
