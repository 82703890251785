<div class="rule-container">
  <ul [ngClass]="{ 'rule-without-bullets': triggeredRules?.length < 2 }">
    <li class="li-container c11n-space-stack-2x" *ngFor="let rule of triggeredRules">
      <div [innerHtml]="rule.ruleMessage | tweSanitizeHtml" data-testid="txt-order-error-message"></div>
      <div class="rule-hotspot">
        <div class="rule-tip" aria-hidden="true">{{ rule.ruleId }}</div>
      </div>
    </li>
  </ul>
</div>
