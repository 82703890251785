import { Component, Input, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import { WindowService } from '@app/etfs-equities/services';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';

@Component({
  selector: 'twe-cannot-modify-modal',
  templateUrl: './cannot-modify-modal.component.html',
  styleUrls: ['./cannot-modify-modal.component.scss'],
})
export class CannotModifyModalComponent {
  //  Decorators...

  @ViewChild('cannotModifyModal') cannotModifyModal: ModalDialogComponent;

  //  Public variables...

  content: JsonContent = content;

  // Private variables..

  private _showModal = false;

  constructor(private readonly windowService: WindowService) {}

  // Getters/Setters...

  get showModal() {
    return this._showModal;
  }

  @Input() set showModal(val) {
    if (val) {
      this.open();
    }
    this._showModal = val;
  }

  open() {
    this.cannotModifyModal.openModalDialog();
  }

  navigateToOrderStatus() {
    this.windowService.navigateToExternalLink(environment.secureSiteUrls.orderStatus);
  }
}
