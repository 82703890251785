import { Injectable } from '@angular/core';
import { Account } from '@app/etfs-equities/models';
import { AccountService, TradeTicketService } from '@app/etfs-equities/services';
import { parseAxiosToHttpError } from '@app/shared/utilities/error/error.util';
import { ExtendedHoursService } from '@etfs-equities/services/extended-hours/extended-hours.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ExtendedHoursAgreementResponseEcnStatusEnum } from '@vanguard/invest-api-client-typescript-axios';
import { AxiosError } from 'axios';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
  AccountActionTypes,
  createLoadAccountsSuccessAction,
  createLoadExtendedTradeAgreementErrorAction,
  createNoopAction,
  createRefreshAccountsErrorAction,
  createRefreshSelectedAccountAction,
  createSetClientIdAction,
  createSetExtendedTradeAgreementAction,
  SelectAccountAction,
} from '../../actions';
import { selectExtendedTradeAgreement } from '../../selectors';

@Injectable()
export class AccountEffects {
  loadAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActionTypes.LOAD_ACCOUNTS),
      exhaustMap(() =>
        this.accountService
          .fetchAccounts()
          .pipe(
            switchMap((response: Account.MidTierResponse) =>
              of(createLoadAccountsSuccessAction(response), createSetClientIdAction(response.vgClientPoid))
            )
          )
      )
    )
  );

  refreshAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActionTypes.REFRESH_ACCOUNTS),
      exhaustMap(() =>
        this.accountService.refreshAccounts().pipe(
          switchMap((response: Account.MidTierResponse) => [
            createLoadAccountsSuccessAction(response),
            createRefreshSelectedAccountAction(response),
            createSetClientIdAction(response.vgClientPoid),
          ]),
          catchError(() => [createRefreshAccountsErrorAction()])
        )
      )
    )
  );

  selectAccount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActionTypes.SELECT_ACCOUNT),
        tap((action: SelectAccountAction) => this.tradeTicketService.setBrokerageAccountNumber(action.payload))
      ),
    { dispatch: false }
  );

  setExtendedTradeAgreement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActionTypes.LOAD_EXTENDED_TRADE_AGREEMENT),
      withLatestFrom(this.store.select(selectExtendedTradeAgreement)),
      switchMap(([_, agreement]) => {
        return agreement !== null
          ? of(createNoopAction())
          : this.extendedHoursService.getExtendedHoursAgreement().pipe(
              filter((response) => !!response),
              map((response) => {
                const agreementStatus = response.ecnStatus === ExtendedHoursAgreementResponseEcnStatusEnum.ACPT;
                return createSetExtendedTradeAgreementAction(agreementStatus);
              }),
              catchError((error: AxiosError) => {
                this.store.dispatch(createLoadExtendedTradeAgreementErrorAction(parseAxiosToHttpError(error)));
                return of(createSetExtendedTradeAgreementAction(null));
              })
            );
      })
    )
  );

  constructor(
    private readonly accountService: AccountService,
    private readonly tradeTicketService: TradeTicketService,
    private readonly extendedHoursService: ExtendedHoursService,
    private readonly actions$: Actions,
    private readonly store: Store
  ) {}
}
