import { ExtendedHoursState } from '@etfs-equities/store/states/extended-hours.state';
import { createSelector } from '@ngrx/store';

import { TayneState } from '../../states';
import { selectTayneState } from '../tayne/tayne.selectors';

export const selectExtendedHoursState = createSelector(selectTayneState, (state: TayneState) => state.extendedHours);

export const selectEcnHours = createSelector(selectExtendedHoursState, (state: ExtendedHoursState) => state.ecnHours);

export const selectEcnHoursOpenTime = createSelector(selectEcnHours, (ecnHours) => ecnHours?.openTime);

export const selectEcnHoursCloseTime = createSelector(selectEcnHours, (ecnHours) => ecnHours?.closeTime);
