<c11n-modal-dialog
  #cancelErrorModal
  [headingText]="content.alertsModal.title"
  modalId="cancelErrorModal"
  variant="error"
  [primaryButtonLabel]="content.labels?.goToOrderStatus"
  (primaryClick)="navigateToOrderStatus.emit()"
>
  <div c11n-modal-dialog-body>
    <twe-rule-content [triggeredRules]="hardStopRules"></twe-rule-content>
  </div>
</c11n-modal-dialog>
