import { Component, OnInit, ViewChild } from '@angular/core';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@app/core/services';
import { JsonContent } from '@app/etfs-equities/models';
import { FocusUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-fractional-shares-info-modal',
  templateUrl: './fractional-shares-info-modal.component.html',
  styleUrls: ['./fractional-shares-info-modal.component.scss'],
})
export class FractionalSharesInfoModalComponent implements OnInit {
  @ViewChild('fractionalSharesInfoModal')
  fractionalSharesInfoModal: ModalDialogComponent;

  // Public variables...
  content: JsonContent | undefined = content;

  // Public observables/subjects...
  dollarBasedTradesToggleEnabled$: Observable<boolean>;

  constructor(private readonly gatekeeperService: GatekeeperService) {}

  ngOnInit(): void {
    this.dollarBasedTradesToggleEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_DOLLAR_BASED_TRADES
    );
  }

  open($event): void {
    FocusUtil.setTooltipFocusElement($event);
    this.fractionalSharesInfoModal.openModalDialog();
  }

  onClose() {
    FocusUtil.setFocus();
  }
}
