import { computed, Injectable, signal } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import { LoadingStatusNames } from '@app/etfs-equities/models/loading.model';
import content from '@content/content.json';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  content: JsonContent = content;
  #loadingSignal = signal<number>(0);
  #spinnerStatus = signal<string>(content.loading.loading);

  isLoading = computed(() => this.#loadingSignal() > 0);
  spinnerStatus = this.#spinnerStatus.asReadonly();

  loadingOn(spinnerStatusName?: LoadingStatusNames) {
    this.setSpinnerStatus(spinnerStatusName);
    this.#loadingSignal.update((loadingVal) => loadingVal + 1);
  }

  setSpinnerStatus(spinnerStatusName: LoadingStatusNames) {
    const spinnerStatusText = content.loading[spinnerStatusName];
    if (spinnerStatusText && spinnerStatusText !== this.#spinnerStatus()) {
      this.#spinnerStatus.set(spinnerStatusText);
    }
  }

  isSubmittingOrder() {
    return this.#spinnerStatus() === content?.loading.submit;
  }

  loadingOff() {
    this.#spinnerStatus.set(content.loading.loading);
    if (this.#loadingSignal() > 0) {
      this.#loadingSignal.update((loadingVal) => loadingVal - 1);
    } else {
      this.#loadingSignal.set(0);
    }
  }
}
