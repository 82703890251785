import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { AdobeAnalyticsService, GatekeeperService } from '@app/core/services';
import { OrderEnums } from '@app/etfs-equities/enums';
import { JsonContent, Quote } from '@app/etfs-equities/models';
import { TradeTicketService } from '@app/etfs-equities/services';
import {
  selectActionIsBuyToCoverOrSellShort,
  selectIsChangeOrderState,
  selectQuote,
  selectQuoteIsVgETF,
  selectTradeTicketSellAll,
  TayneState,
} from '@app/etfs-equities/store';
import { FormUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { InputMask } from '@vg-constellation/angular-17/input';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';
import { combineLatest, distinctUntilChanged, map, Observable, Subject, tap } from 'rxjs';

@Component({
  selector: 'twe-shares-control',
  templateUrl: './shares-control.component.html',
  styleUrls: ['./shares-control.component.scss'],
})
export class SharesControlComponent implements OnInit {
  @ViewChild('fractionalSharesInfoModal')
  fractionalSharesInfoModal: ModalDialogComponent;

  @ViewChild('dollarsToSharesModal')
  dollarsToSharesModal: ModalDialogComponent;

  @Input() isChangeOrder: boolean;
  @Input() parentGroup?: FormGroup;
  @Input() isExtendedTrading: boolean;
  @Output() amountEvent: Subject<void> = new Subject<void>();

  static errorMapShares = {
    required: content.validation.shares.empty,
    max: content.validation.shares.max,
    pattern: content.validation.numeric,
    decimals: content.validation.shares.integer,
    holdSharesLimit: content.validation.shares.holdLimit,
  };

  static errorMapDollars = {
    required: content.validation.dollars.empty,
    holdLimitDollars: content.validation.dollars.amountLimit,
    min: content.validation.dollars.min,
    max: content.validation.dollars.max,
    decimals: content.validation.dollars.decimals,
    pattern: content.validation.invalidChars,
    sharesLimitExceeded: content.validation.dollars.sharesLimit,
  };

  // Public variables...
  content: JsonContent | undefined = content;
  amountTypes = OrderEnums.AmountTypes;
  miniToggleButtons = [
    {
      id: 'radioShares',
      value: OrderEnums.AmountTypes.SHARES,
      label: content.amount.shares,
    },
    {
      id: 'radioDollars',
      value: OrderEnums.AmountTypes.DOLLARS,
      label: content.amount.dollars,
    },
  ];
  amountLabel: string;

  //  Public observables/subjects...
  actionIsBuyToCoverOrSellShort$: Observable<boolean>;
  dollarBasedTradesEnabled$: Observable<boolean>;
  isIncapacitated$: Observable<boolean>;
  quote$: Observable<Quote>;
  quoteIsVgEtf$: Observable<boolean>;
  showAmountTypeToggle$: Observable<boolean>;
  inputMaskShares = InputMask.NUMBER;
  inputMaskDollars = InputMask.CURRENCY;

  constructor(
    public tradeTicketService: TradeTicketService,
    public adobeService: AdobeAnalyticsService,
    private readonly store: Store<TayneState>,
    private readonly gatekeeperService: GatekeeperService
  ) {
    this.inputMaskShares.blocks.VALUE.scale = 4;
  }

  ngOnInit(): void {
    this.actionIsBuyToCoverOrSellShort$ = this.store.pipe(select(selectActionIsBuyToCoverOrSellShort));
    this.quote$ = this.store.pipe(select(selectQuote));
    this.quoteIsVgEtf$ = this.store.pipe(select(selectQuoteIsVgETF));

    this.dollarBasedTradesEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_DOLLAR_BASED_TRADES
    );

    // Subscriptions...
    this.setAmountTypeToggleObservable();
  }

  emitAmountEvent() {
    this.amountEvent.next();
  }

  handleSharesOrDollarsToggleClick = (event: Event) => {
    const elementId = (event.target as HTMLInputElement).id;

    if (elementId === this.miniToggleButtons[0].id) {
      this.adobeService.sendAdobeLaunchProcess('Select Share');
    }

    if (elementId === this.miniToggleButtons[1].id) {
      this.adobeService.sendAdobeLaunchProcess('Select Dollar');
    }
  };

  selectSharesMinErrorMessage(): string {
    const { brokerageAccountNumber, symbol } = this.tradeTicketService.tradeTicket.controls;
    const { min, sellAllNoSharesHeld, sellAllNoSymbolOrAccount } = content.validation.shares;

    if (!this.tradeTicketService.isSellAllChecked()) {
      return min;
    }

    if (brokerageAccountNumber.valid && symbol.valid) {
      return sellAllNoSharesHeld;
    }

    return sellAllNoSymbolOrAccount;
  }

  updateShareAmountValue(event) {
    if (event.target.value) {
      this.tradeTicketService.setAmount(event.target.value.toString().replaceAll(',', ''));
      this.tradeTicketService.tradeTicket.controls.amount.markAsDirty();
      this.tradeTicketService.tradeTicket.controls.amount.markAsTouched();
    }
  }

  emitBlur(event) {
    if (event.target) {
      event.target.blur();
    }
  }

  getAmountErrorText(): string | void {
    if (this.tradeTicketService.amountTypeIsShares()) {
      if (this.tradeTicketService.controlHasError('amount', 'min')) {
        return this.selectSharesMinErrorMessage();
      }
      return FormUtil.getErrorContent(this.tradeTicketService, SharesControlComponent.errorMapShares, 'amount');
    } else if (this.tradeTicketService.amountTypeIsDollars()) {
      return FormUtil.getErrorContent(this.tradeTicketService, SharesControlComponent.errorMapDollars, 'amount');
    }
  }

  private setAmountTypeToggleObservable() {
    this.showAmountTypeToggle$ = combineLatest([
      this.quoteIsVgEtf$,
      this.dollarBasedTradesEnabled$,
      this.actionIsBuyToCoverOrSellShort$,
      this.store.pipe(select(selectTradeTicketSellAll)),
      this.store.pipe(select(selectIsChangeOrderState)),
    ]).pipe(
      map(
        ([isVgEtf, dollarBasedEnabled, actionIsBuyToCoverOrSellShort, isSellAll, isChangeOrder]) =>
          isVgEtf && dollarBasedEnabled && !actionIsBuyToCoverOrSellShort && !isChangeOrder && !isSellAll
      ),
      distinctUntilChanged(),
      tap((val) => {
        this.amountLabel = val ? content?.amount.amount : content?.labels.numberOfShares;
        if (val) {
          this.adobeService.sendAdobeLaunchProcess('Default Share');
        }
      })
    );
  }
}
