import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, KeywordSearchResult } from '@app/etfs-equities/models';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, pluck, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KeywordSearchService {
  isSearchingByKeyword = false;

  hasSearched = false;

  isLoading = false;

  lastKeyword: string;

  hasError = false;

  results: KeywordSearchResult[] = [];

  constructor(private readonly http: HttpClient, private readonly envService: EnvironmentService) {}

  get noResultsFound(): boolean {
    return (
      this.isSearchingByKeyword && this.hasSearched && !this.isLoading && !this.hasError && this.results.length === 0
    );
  }

  // A generic search method that does not manipulate service-layer state.
  // Intended to be used if you want to manually control loading state,
  // results, and errors separately from the inline search experience.
  search(keyword: string): Observable<KeywordSearchResult[]> {
    return this.http
      .get<ApiResponse<KeywordSearchResult[]>>(this.envService.getApiUrlBaseOnRoute() + `api/search?q=${keyword}`, {
        withCredentials: true,
      })
      .pipe(
        pluck('data'),
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
  }

  // A search intended to be used with the "inline" keyword search.
  // This handles service-layer state as a convenience.
  inlineSearch(keyword: string): Observable<KeywordSearchResult[]> {
    if (!keyword || !keyword.trim().length || keyword === this.lastKeyword) {
      return EMPTY;
    }

    this.clearKeywordSearchResults();

    this.lastKeyword = keyword;
    this.hasSearched = true;
    this.isLoading = true;

    return this.search(keyword).pipe(
      tap((results) => {
        this.results = results;
        this.isLoading = false;
        this.hasError = false;
      }),
      catchError(() => {
        this.isLoading = false;
        this.hasError = true;
        return EMPTY;
      })
    );
  }

  toggleKeywordSearch() {
    this.isSearchingByKeyword = !this.isSearchingByKeyword;
    this.hasError = false;
  }

  clearKeywordSearchResults() {
    this.hasSearched = false;
    this.lastKeyword = null;
    this.results = [];
  }

  handleError(error: HttpErrorResponse) {
    return throwError(() => error);
  }
}
