<div class="service-unavailable-msg d-flex justify-content-center align-items-center">
  <div class="text-center d-flex align-items-center flex-column" data-testid="information">
    <tcx-custom-icon
      *ngIf="iconType === 'default'"
      class="c11n-space-stack-2x"
      [size]="'medium'"
      [name]="'info'"
      [altText]="content.altText.info"
    ></tcx-custom-icon>

    <c11n-icon
      *ngIf="iconType === 'warning'"
      class="c11n-space-stack-2x"
      name="warning"
      size="medium"
      scale="normal"
    ></c11n-icon>
    <ng-content></ng-content>
  </div>
</div>
