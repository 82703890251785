<ng-container
  *ngIf="{
    ecnHoursOpenTime: (ecnHoursOpenTime$ | async),
    ecnHoursCloseTime: (ecnHoursCloseTime$ | async),
  } as view"
>
  <tcx-announcement-banner
    iconName="time-outline"
    iconSize="small"
    color="YELLOW"
    announcementId="extendedHoursBanner"
    [showCloseButton]="false"
  >
    <div class="d-flex c11n-space-inline-right-3x c11n-space--force">
      <p class="c11n-text-md mb-0"
        >{{ content?.extendedTrading.announcementBanner.message }}
        {{ view.ecnHoursOpenTime }}
        {{ content?.extendedTrading.announcementBanner.to }}
        {{ view.ecnHoursCloseTime }}
        {{ content?.extendedTrading.announcementBanner.easternTime }}
        <span>
          <twe-tooltip [tooltipHeader]="content?.extendedTrading.announcementBanner.tooltipHeader">
            <p class="c11n-text-md">{{ content?.extendedTrading.announcementBanner.tooltipMessage1 }}</p>
            <p class="c11n-text-md">{{ content?.extendedTrading.announcementBanner.tooltipMessage2 }}</p>
          </twe-tooltip>
        </span>
      </p>
    </div>
  </tcx-announcement-banner>
</ng-container>
