<c11n-modal-dialog
  #modal
  modalId="extended-trading-expired-modal"
  [headingText]="content?.extendedHoursExpiredModal?.title"
  [primaryButtonLabel]="content?.labels?.ok"
  (primaryClick)="navigateToTradePage()"
  (closeEvent)="navigateToTradePage()"
>
  <div c11n-modal-dialog-body>
    {{ content?.extendedHoursExpiredModal?.body }}
  </div>
</c11n-modal-dialog>
