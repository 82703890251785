import { Component, ViewChild } from '@angular/core';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { JsonContent } from '@app/etfs-equities/models';
import { environment } from '@env/environment';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';
@Component({
  selector: 'twe-ipo-modal',
  templateUrl: './ipo-modal.component.html',
  styleUrls: ['./ipo-modal.component.scss'],
})
export class IpoModalComponent {
  @ViewChild('ipoModal')
  ipoModal: ModalDialogComponent;

  ipoLink = `${environment.investorDomain}${CONSTANTS.IPO_EDUCATION}`;

  content: JsonContent = require('@content/content.json');
}
