<twe-balances-guidelet-modal #balancesGuideletModal></twe-balances-guidelet-modal>

<c11n-modal-dialog #modal [headingText]="content.balancesMarginModal.title">
  <div c11n-modal-dialog-body>
    <div>
      <p
        class="twe-c11n-color-ink-800 c11n-space-stack-3x"
        [innerHtml]="content.balancesMarginModal.settlementFund"
      ></p>
      <p
        class="twe-c11n-color-ink-800 c11n-space-stack-3x"
        [innerHtml]="content.balancesMarginModal.marginCashAvailable"
      ></p>
      <p
        class="twe-c11n-color-ink-800 c11n-space-stack-3x"
        [innerHtml]="content.balancesMarginModal.fundsAvailable"
      ></p>
      <p
        class="twe-c11n-color-ink-800 c11n-space-stack-3x"
        [innerHtml]="content.balancesMarginModal.marginBuyingPower"
      ></p>
      <p class="twe-c11n-color-ink-800 c11n-space-stack-3x" [innerHtml]="content.balancesMarginModal.marginCall"> </p>
    </div>
    <div>
      <button c11n-link (click)="balancesGuideletModal.open(); modal.close()">{{
        content.guidelet.balances.linkToModal
      }}</button>
    </div>
  </div>
</c11n-modal-dialog>
