import { CONSTANTS } from '@app/etfs-equities/constants';
import { TradeTicketForm } from '@app/etfs-equities/models/trade-ticket-form.model';

export interface QueryParams {
  accountId?: string;
  orderId?: string;
}

export interface TabLink {
  url: string;
  queryParams: QueryParams | null;
}

export interface TradeTicketState {
  tradeTicket: TradeTicketForm | null;
  tabLink: TabLink;
  extendedTradingTabLink: TabLink;
}

export const initialTradeTicketState: TradeTicketState = {
  tradeTicket: null,
  tabLink: {
    url: CONSTANTS.TRADE_PATH,
    queryParams: null,
  },
  extendedTradingTabLink: {
    url: CONSTANTS.EXTENDED_TRADING_PATH,
    queryParams: null,
  },
};
