import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';
import { Observable } from 'rxjs';

import { TriggeredTradingRule } from '../../models/order-cancel';

@Component({
  selector: 'twe-cancel-error-modal',
  templateUrl: './cancel-error-modal.component.html',
  styleUrls: ['./cancel-error-modal.component.scss'],
})
export class CancelErrorModalComponent {
  content: JsonContent = content;

  @Input() hardStopRules: Observable<TriggeredTradingRule[]>;

  @ViewChild('cancelErrorModal') cancelErrorModal: ModalDialogComponent;

  @Output() navigateToOrderStatus = new EventEmitter();

  open() {
    this.cancelErrorModal.openModalDialog();
  }
}
