import { OrderEnums } from '@app/etfs-equities/enums';
import { Order, Quote } from '@app/etfs-equities/models';

export class PrincipalUtil {
  /**
   * Calculate the estimated principal for the given order and quote.
   */
  static calculatePrincipal(order: Order.Order, quote: Quote): number {
    if (PrincipalUtil.cannotCalculatePrincipal(order, quote)) {
      return null;
    }

    let principal: number;

    switch (order.orderType) {
      case OrderEnums.Types.MARKET:
        if (order.amountType === OrderEnums.AmountTypes.SHARES) {
          principal = PrincipalUtil.calculateMarketOrderPrincipal(order, quote);
        } else {
          principal = order.shares;
        }
        break;
      case OrderEnums.Types.LIMIT:
      case OrderEnums.Types.STOP_LIMIT:
        principal = order.shares * parseFloat(order.limitPrice);
        break;
      case OrderEnums.Types.STOP:
        principal = order.shares * parseFloat(order.stopPrice);
        break;
    }

    return Math.round((principal + Number.EPSILON) * 100) / 100;
  }

  /**
   * Verify that the principal CAN be calculcated for the given order and quote.
   */
  private static canCalculatePrincipal(order: Order.Order, quote: Quote): boolean {
    // Required fields regardless of order type or transaction type.
    if (
      !order ||
      !quote ||
      !PrincipalUtil.orderTypeIsValid(order) ||
      !PrincipalUtil.transactionTypeIsValid(order) ||
      !order.shares ||
      order.shares <= 0
    ) {
      return false;
    }

    // Market order required fields.
    if (order.orderType === OrderEnums.Types.MARKET) {
      return PrincipalUtil.quoteHasPrice(quote, order);
    }

    // Stop order required fields.
    if (order.orderType === OrderEnums.Types.STOP && !order.stopPrice) {
      return false;
    }

    // Limit and stop limit order required fields.
    if (
      (order.orderType === OrderEnums.Types.LIMIT || order.orderType === OrderEnums.Types.STOP_LIMIT) &&
      parseFloat(order.limitPrice) <= 0
    ) {
      return false;
    }

    return true;
  }

  /**
   * Verify that the principal CANNOT be calculated for the given order and quote.
   */
  private static cannotCalculatePrincipal(order: Order.Order, quote: Quote): boolean {
    return !PrincipalUtil.canCalculatePrincipal(order, quote);
  }

  /**
   * Verify that the given order's order type is valid.
   */
  private static orderTypeIsValid(order: Order.Order) {
    // Order type is falsy.
    if (!order.orderType) {
      return false;
    }

    // Order type is of an unknown value.
    if (
      order.orderType !== OrderEnums.Types.LIMIT &&
      order.orderType !== OrderEnums.Types.MARKET &&
      order.orderType !== OrderEnums.Types.STOP &&
      order.orderType !== OrderEnums.Types.STOP_LIMIT
    ) {
      return false;
    }

    return true;
  }

  /**
   * Verify that the given order's transaction type is valid.
   */
  private static transactionTypeIsValid(order: Order.Order) {
    // Transaction type is falsy.
    if (!order.transactionType) {
      return false;
    }

    // Transaction type is of an unknown value.
    if (
      order.transactionType !== OrderEnums.TransactionTypes.BUY &&
      order.transactionType !== OrderEnums.TransactionTypes.SELL &&
      order.transactionType !== OrderEnums.TransactionTypes.BUY_TO_COVER &&
      order.transactionType !== OrderEnums.TransactionTypes.SELL_SHORT
    ) {
      return false;
    }

    return true;
  }

  private static quoteHasPrice(quote: Quote, order: Order.Order) {
    let hasPrice = false;
    switch (order.transactionType) {
      // Buy market order required fields.
      case OrderEnums.TransactionTypes.BUY:
      case OrderEnums.TransactionTypes.BUY_TO_COVER:
        hasPrice = !!quote.askPrice || !!quote.lastTradePrice || !!quote.previousClosePrice;
        break;

      // Sell market order required fields.
      case OrderEnums.TransactionTypes.SELL:
      case OrderEnums.TransactionTypes.SELL_SHORT:
        hasPrice = !!quote.bidPrice || !!quote.lastTradePrice || !!quote.previousClosePrice;
        break;
    }
    return hasPrice;
  }

  /**
   * Calculate the estimated principal for a market order. Note that the formula
   * is slightly different depending on whether the order is a buy or sell.
   */
  private static calculateMarketOrderPrincipal(order: Order.Order, quote: Quote) {
    let price: number;
    switch (order.transactionType) {
      case OrderEnums.TransactionTypes.BUY:
      case OrderEnums.TransactionTypes.BUY_TO_COVER:
        if (Number(quote.askPrice)) {
          price = parseFloat(quote.askPrice);
        } else if (Number(quote.lastTradePrice)) {
          price = parseFloat(quote.lastTradePrice);
        } else {
          price = parseFloat(quote.previousClosePrice);
        }
        break;

      case OrderEnums.TransactionTypes.SELL:
      case OrderEnums.TransactionTypes.SELL_SHORT:
        if (Number(quote.bidPrice)) {
          price = parseFloat(quote.bidPrice);
        } else if (Number(quote.lastTradePrice)) {
          price = parseFloat(quote.lastTradePrice);
        } else {
          price = parseFloat(quote.previousClosePrice);
        }
        break;
    }
    return order.shares * price;
  }
}
