import { Injectable } from '@angular/core';
import { KeywordSearchService, TradeTicketService } from '@app/etfs-equities/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

import { createClearQuoteAction, TradeTicketActionTypes } from '../../actions';
import { TayneState } from '../../states';

@Injectable()
export class TradeTicketEffects {
  resetTradeTicket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TradeTicketActionTypes.RESET_TRADE_TICKET),
        tap(() => {
          this.tradeTicketService.resetForm();
          this.store.dispatch(createClearQuoteAction());
          this.keywordSearchService.clearKeywordSearchResults();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly tradeTicketService: TradeTicketService,
    private readonly keywordSearchService: KeywordSearchService,
    private readonly actions$: Actions,
    private readonly store: Store<TayneState>
  ) {}
}
