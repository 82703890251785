<ng-container
  *ngIf="{
    selectedAccount: (selectedAccount$ | async),
    order: (order$ | async),
    shouldDisplayProspectusLink: (shouldDisplayProspectusLink$ | async),
    isSellingAllHeldShares: (isSellingAllHeldShares$ | async),
    dollarBasedTradesEnabled: (dollarBasedTradesEnabled$ | async),
    isEditCostBasis: (isEditCostBasis$ | async),
    isTplus1Enabled: (isTplus1Enabled$ | async),
    isScreenXSmall: (isScreenXSmall$ | async),
    isEveningOrder: (isEveningOrder$ | async),
  } as view"
>
  <twe-triggered-rules-modal [acceptedRulesNextStep]="AcceptedRulesNextStep.SUBMIT"></twe-triggered-rules-modal>

  <twe-generic-error-modal [isChangeOrder]="!!view.order?.orderId"></twe-generic-error-modal>

  <twe-timeout-error-modal #timeoutErrorModal></twe-timeout-error-modal>

  <div class="container-fluid" data-testid="preview-container">
    <twe-selected-account [account]="view.selectedAccount" class="d-block c11n-space-stack-3x"></twe-selected-account>

    <div class="row c11n-space-stack-5x c11n-space--force">
      <div class="col-lg-5">
        <twe-funds-available class="funds-available-card"></twe-funds-available>

        <twe-quote
          class="d-none d-lg-block"
          *ngIf="!view.isEditCostBasis"
          [isAfterMarket]="view.isEveningOrder"
        ></twe-quote>
        <twe-executed-price class="d-none d-lg-block" *ngIf="view.isEditCostBasis"></twe-executed-price>
      </div>

      <div class="col-lg-7">
        <div class="twe-card">
          <h2 class="twe-card-header">{{ content.headings.previewAndSubmit }}</h2>

          <twe-order-details></twe-order-details>

          <p
            class="notice-text c11n-space-stack-2_5x"
            *ngIf="
              !view.isEditCostBasis && view.order?.transactionType === transactionTypes.SELL && !view.isTplus1Enabled
            "
            >{{ content.dynamicPreviewSellFooter }}</p
          >
          <p
            class="notice-text c11n-space-stack-2_5x"
            *ngIf="
              !view.isEditCostBasis && view.order?.transactionType === transactionTypes.SELL && view.isTplus1Enabled
            "
            >{{ content.dynamicPreviewSellFooterTplus1 }}</p
          >

          <p
            class="notice-text c11n-space-stack-2_5x"
            *ngIf="!view.isEditCostBasis && view.dollarBasedTradesEnabled && view.isSellingAllHeldShares"
          >
            {{ content.dynamicPreviewSellAll }}
          </p>

          <div
            class="notice-text c11n-stack-space-6x"
            *ngIf="
              !view.isEditCostBasis &&
              (view.order?.transactionType === transactionTypes.BUY || view.order?.amountType === amountTypes.DOLLARS)
            "
            data-testid="txt-prospectus-notice"
          >
            <p
              *ngIf="view.order?.transactionType === transactionTypes.BUY && !view.isTplus1Enabled"
              class="c11n-stack-space-3x"
              >{{ content.submitOrderDisclaimer.body1 }}</p
            >
            <p
              *ngIf="view.order?.transactionType === transactionTypes.BUY && view.isTplus1Enabled"
              class="c11n-stack-space-3x"
              >{{ content.submitOrderDisclaimer.body1Tplus1 }}</p
            >
            <p
              *ngIf="
                view.order?.amountType === amountTypes.DOLLARS ||
                (!view.order?.orderId && view.shouldDisplayProspectusLink)
              "
              class="mb-0"
              >{{ content.submitOrderDisclaimer.body2 }}
            </p>
            <ul>
              <li *ngIf="view.order?.amountType === amountTypes.DOLLARS">
                {{ content.submitOrderDisclaimer.dollarBasedTrading1 }}
                <button
                  c11n-link
                  fontWeight="normal"
                  variant="primary-reinforced"
                  (click)="
                    appComponent.tradeHelpModal.setOption(tradeHelpDollarBasedTradingTab);
                    appComponent.tradeHelpModal.open()
                  "
                >
                  {{ content.submitOrderDisclaimer.dollarBasedTermsLink }}</button
                >{{ content.submitOrderDisclaimer.dollarBasedTrading2 }}
              </li>
              <li *ngIf="view.shouldDisplayProspectusLink">
                <p>
                  {{ content.submitOrderDisclaimer.prospectusLinkContent }}
                  <twe-prospectus-link *ngIf="!isBeacon" [order]="view.order"></twe-prospectus-link>
                </p>
              </li>
            </ul>
          </div>

          <div class="twe-flex-button-wrap">
            <c11n-button
              [attr.data-testid]="'btn-preview-edit'"
              class="twe-flex-button-wrap__button"
              buttonType="button"
              size="medium"
              variant="secondary"
              [fullWidth]="view.isScreenXSmall"
              [isInvalid]="orderService?.isSubmitting"
              [labelText]="content.labels.edit"
              (clickEvent)="edit(view.isEditCostBasis)"
            ></c11n-button>

            <c11n-button
              appSubmitButtonOnce
              class="twe-flex-button-wrap__button"
              data-testid="btn-preview-submit"
              buttonType="button"
              size="medium"
              variant="primary"
              [fullWidth]="view.isScreenXSmall"
              [isWaiting]="orderService?.isSubmitting"
              [labelText]="content.labels.submit"
              [disabled]="loadingService?.isLoading()"
              (clickFunc)="submit()"
            ></c11n-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
