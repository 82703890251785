import { TradeTicketActionTypes, TradeTicketActionTypesUnion } from '../../actions/';
import { initialTradeTicketState, TradeTicketState } from '../../states';

export const tradeTicketReducer = (
  state: TradeTicketState = initialTradeTicketState,
  action: TradeTicketActionTypesUnion
): TradeTicketState => {
  switch (action.type) {
    case TradeTicketActionTypes.UPDATE_TRADE_TICKET:
      return { ...state, tradeTicket: action.payload };

    case TradeTicketActionTypes.UPDATE_TRADE_TICKET_TAB_LINK:
      return { ...state, tabLink: action.payload };

    case TradeTicketActionTypes.UPDATE_EXTENDED_TRADING_TAB_LINK:
      return { ...state, extendedTradingTabLink: action.payload };

    case TradeTicketActionTypes.CLEAR_TRADE_TICKET:
      return { ...state, tradeTicket: null };

    default:
      return state;
  }
};
