<twe-ipo-modal #ipoModal></twe-ipo-modal>

<ng-container
  *ngIf="{
    displayQuoteBanner: (displayQuoteBanner$ | async),
    quoteIsHalted: (quoteIsHalted$ | async),
    buyOrderRestriction: (buyOrderRestriction$ | async),
    showNoBuyOrdersBannerForLowTierOtc: (showNoBuyOrdersBannerForLowTierOtc$ | async),
    showLowLiquidityHint: (showLowLiquidityHint$ | async),
    isQuoteIpo: (quoteIsIpo$ | async),
  } as view"
>
  <div
    [ngClass]="{
      'twe-lt-lg-mb-3': !view.displayQuoteBanner,
      'twe-lt-lg-mb-2': view.displayQuoteBanner
    }"
    class="c11n-space-stack-3x"
  >
    <twe-symbol-search-control [isChangeOrder]="isChangeOrder" [hidden]="keywordSearchService.isSearchingByKeyword">
    </twe-symbol-search-control>
    <twe-keyword-search-control
      [parentGroup]="parentGroup"
      (externalFocusEvent)="focusSymbolControl()"
    ></twe-keyword-search-control>
  </div>
  <div>
    <ng-container *ngTemplateOutlet="haltBanner"></ng-container>

    <div class="d-sm-block d-lg-none">
      <ng-container *ngTemplateOutlet="noBuyOrdersBanner; context: { $implicit: false }"></ng-container>
    </div>

    <div class="d-lg-block d-none">
      <ng-container *ngTemplateOutlet="noBuyOrdersBanner; context: { $implicit: true }"></ng-container>
    </div>

    <ng-container *ngTemplateOutlet="ipoBanner"></ng-container>
  </div>

  <ng-template #haltBanner>
    <c11n-banner
      *ngIf="view.quoteIsHalted"
      class="c11n-space-stack-3x"
      size="small"
      [hasCloseControl]="false"
      [hasDetails]="false"
      variant="basic"
      [iconName]="content.haltAlert.icon"
    >
      <div class="c11n-text-md--crop">
        <strong> {{ content.haltAlert.notice }} </strong>
        <span>{{ content.haltAlert.content }}</span>
      </div>
    </c11n-banner>
  </ng-template>
  <ng-template #noBuyOrdersBanner let-expanded>
    <c11n-banner
      *ngIf="view.buyOrderRestriction || (view.showNoBuyOrdersBannerForLowTierOtc && !view.showLowLiquidityHint)"
      class="mb-4"
      size="medium"
      [hasCloseControl]="false"
      [hasDetails]="true"
      variant="basic"
      [iconName]="'warning'"
      [headingText]="content.noBuyOrderBanner.header"
      [isDetailsExpanded]="expanded"
    >
      <div class="c11n-text-md--crop">
        <span>{{ content.noBuyOrderBanner.body }} </span>
      </div>
    </c11n-banner>
  </ng-template>

  <ng-template #ipoBanner>
    <c11n-banner
      *ngIf="view.isQuoteIpo"
      class="mb-4"
      size="small"
      [hasCloseControl]="false"
      [hasDetails]="false"
      variant="basic"
      [iconName]="content.validation.symbol.icon"
    >
      <div class="c11n-text-md--crop">
        {{ content.validation.symbol.ipoInlineErrorMessage }}
        <button
          c11n-link
          data-testid="btn-ipo-modal"
          colorMode="on-light"
          type="button"
          (click)="ipoModal.ipoModal.openModalDialog($event)"
        >
          {{ content.labels.ipoLink }}
        </button>
      </div>
    </c11n-banner>
  </ng-template>
</ng-container>
