import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SymbolRetrievalType } from '@app/etfs-equities/enums';
import { Account, JsonContent } from '@app/etfs-equities/models';
import { SymbolRetrievalError } from '@app/etfs-equities/models/symbol-retrieval-error.model';
import { MarketDataService, WindowService } from '@app/etfs-equities/services';
import { selectSelectedAccount, TayneState } from '@app/etfs-equities/store';
import { selectEnvironment } from '@app/etfs-equities/store/selectors/environment/environment.selectors';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';
import { filter, Observable, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';

@Component({
  selector: 'twe-mutual-fund-error-modal',
  templateUrl: './mutual-fund-error-modal.component.html',
  styleUrls: ['./mutual-fund-error-modal.component.scss'],
})
export class MutualFundErrorModalComponent implements OnInit, OnDestroy {
  //  Decorators...

  @Input() symbol: string;
  @Input() isChangeOrder = false;

  @ViewChild('modal')
  modal: ModalDialogComponent;

  //  Public observables/subjects...

  selectedAccount$: Observable<Account.Account>;
  env$: Observable<any>;

  //  Public variables...

  content: JsonContent = content;
  mfQueryParams: string;
  isBeacon = false;

  // Private observables/subjects...

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly windowService: WindowService,
    public marketDataService: MarketDataService,
    private readonly store: Store<TayneState>
  ) {}

  ngOnInit(): void {
    this.env$ = this.store.pipe(select(selectEnvironment));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.isBeacon = this.windowService.getIsBeacon();

    this.watchForMutualFundSymbolError();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setMarketDataIsLoading(value: boolean) {
    this.marketDataService.setIsLoading(value);
  }

  private watchForMutualFundSymbolError() {
    this.marketDataService.symbolRetrievalError$
      .pipe(
        filter((error: SymbolRetrievalError) => !this.isChangeOrder && error.type === SymbolRetrievalType.MUTUAL_FUND),
        withLatestFrom(this.selectedAccount$),
        tap(([_symbolRetrievalError, account]) => {
          this.mfQueryParams = '';
          if (!!account?.accountId) {
            this.mfQueryParams += `&accountId=${account.accountId}`;
          }
          if (!!this.symbol) {
            this.mfQueryParams += `&ticker=${this.symbol.toUpperCase()}`;
          }
          this.modal.openModalDialog();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
