import { Component, OnInit } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import { selectEcnHoursCloseTime, selectEcnHoursOpenTime } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'twe-extended-hours-banner',
  templateUrl: './extended-hours-banner.component.html',
  styleUrl: './extended-hours-banner.component.scss',
})
export class ExtendedHoursBannerComponent implements OnInit {
  content: JsonContent = content;
  ecnHoursOpenTime$: Observable<string>;
  ecnHoursCloseTime$: Observable<string>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.ecnHoursOpenTime$ = this.store.pipe(
      select(selectEcnHoursOpenTime),
      map((time) => this.convertTo12HourFormat(time))
    );
    this.ecnHoursCloseTime$ = this.store.pipe(
      select(selectEcnHoursCloseTime),
      map((time) => this.convertTo12HourFormat(time))
    );
  }

  // isolate the time component from a string representing UTC time and convert to 12-hour format, with the appropriate am/pm suffix
  convertTo12HourFormat(time: string): string {
    return time ? moment(time).tz('America/New_York').format('h:mma').replace('m', '.m.') : '';
  }
}
