<ng-container>
  <div
    *ngIf="hasAccountRetrievalError"
    [ngClass]="{ 'accounts-error-container': true, 'accounts-error-page': showAsPage }"
  >
    <div role="alert" class="accounts-error c11n-space-stack-5x c11n-space--force">
      <c11n-icon name="warning-fill-red" size="medium" scale="normal"></c11n-icon>
      <p class="twe-c11n-color-ink-400 c11n-space-stack-0_5x" [innerHTML]="content.accounts.accountsError.body"></p>
      <p class="mb-0">
        <button c11n-link type="button" (click)="retry()">
          {{ content.retry }}
        </button>
      </p>
    </div>
  </div>
</ng-container>
