import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tweSubDollarCurrency',
})
export class SubDollarCurrency extends CurrencyPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      const parseValue = parseFloat(value);
      const digitsInfo = value >= 1 ? '1.2-2' : '1.2-4';
      return super.transform(parseValue, 'USD', 'symbol', digitsInfo);
    }
    return null;
  }
}
