import { CancelOrderDuration } from '@app/etfs-equities/enums';
import { OrderUtil } from '@app/etfs-equities/utils';
import { createSelector } from '@ngrx/store';

import { OrderCancelState, TayneState } from '../../states';
import { selectTayneState } from '../tayne/tayne.selectors';

export const selectOrderCancelState = createSelector(selectTayneState, (state: TayneState) => state.orderCancel);

export const selectOrderCancel = createSelector(selectOrderCancelState, (state: OrderCancelState) => state.orderCancel);

export const selectOrderCancelLoadError = createSelector(
  selectOrderCancelState,
  (state: OrderCancelState) => state.orderLoadError
);

export const selectOrderCancelSecuritySymbol = createSelector(selectOrderCancel, (orderCancel) => {
  if (!orderCancel?.ticker) {
    return null;
  }
  return orderCancel.ticker;
});

export const selectOrderCancelBrokerageAccountNumber = createSelector(selectOrderCancel, (orderCancel) => {
  if (!orderCancel) {
    return null;
  }
  return orderCancel.brokerageAccountNumber;
});

export const selectOrderCancelFormatSharesDisplay = createSelector(selectOrderCancel, (orderCancel) => {
  if (!orderCancel?.shares) {
    return null;
  }
  return OrderUtil.formatSharesDisplay(orderCancel.shares);
});

export const selectOrderCancelResponse = createSelector(
  selectOrderCancelState,
  (state: OrderCancelState) => state.orderCancelResponse
);

export const selectOrderCancelDuration = createSelector(selectOrderCancel, (orderCancel) => {
  if (!orderCancel) {
    return null;
  }
  return orderCancel.orderDuration;
});

export const selectOrderCancelEveningDuration = createSelector(selectOrderCancelDuration, (orderCancel) => {
  if (!orderCancel) {
    return null;
  }
  return orderCancel === CancelOrderDuration.EVENING;
});
