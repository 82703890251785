import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrokerageExchangeAgreement } from '@app/core/models/brokerage-exchange-agreement/brokerage-exchange-agreement.model';
import { ApiResponse } from '@app/etfs-equities/models';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BrokerageExchangeAgreementService {
  //  Public variables...

  constructor(private readonly http: HttpClient, private readonly envService: EnvironmentService) {}

  fetchBrokerageExchangeAgreement() {
    return this.http
      .get<ApiResponse<BrokerageExchangeAgreement>>(
        this.envService.getApiUrlBaseOnRoute() + `api/brokerage-exchange-agreement`,
        { withCredentials: true }
      )
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
