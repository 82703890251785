import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DecimalsValidatorTypes } from '@app/etfs-equities/enums/decimals-validator-types.enum';

import { decimalsValidator } from '../decimals/decimals.validator';

export const subDollarDecimalsValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.value || control.value === '.') {
      return null;
    }

    if (parseFloat(control.value) < 1) {
      const validatorFn = decimalsValidator({
        expectedNumberOfDecimals: 4,
        matchType: DecimalsValidatorTypes.MAX,
        errorName: 'subDollarDecimals',
      });
      return validatorFn(control);
    }

    return decimalsValidator({ expectedNumberOfDecimals: 2, matchType: DecimalsValidatorTypes.MAX })(control);
  };
};
