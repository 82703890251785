<div
  role="table"
  class="twe-card-cap"
  [class.short-cap]="isShortCap"
  [class]="backgroundColor && 'twe-card-cap--' + backgroundColor"
>
  <div role="row" class="d-flex align-items-center">
    <div *ngIf="leftContent" role="cell" class="twe-card-cap__left">{{ leftContent }}</div>
    <div *ngIf="rightContent" role="cell" [class.short-cap__right]="isShortCap">{{ rightContent }}</div>
    <ng-content></ng-content>
  </div>
</div>
