import { Component, ViewChild } from '@angular/core';
import content from '@content/content.json';
import { JsonContent } from '@etfs-equities/models';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';

import { OrderTypesModalComponent } from '../order-types-modal/order-types-modal.component';

@Component({
  selector: 'twe-tips-for-trading-volatility-modal',
  templateUrl: './tips-for-trading-volatility-modal.component.html',
  styleUrls: ['./tips-for-trading-volatility-modal.component.scss'],
})
export class TipsForTradingVolatilityModalComponent {
  //  Decorators...
  @ViewChild('modal')
  modal: ModalDialogComponent;

  @ViewChild('orderTypesModal')
  orderTypesModal: OrderTypesModalComponent;

  //  Public variables...
  content: JsonContent = content;

  open() {
    this.modal.openModalDialog();
  }

  close() {
    this.modal.closeDialogModal();
  }

  openOrderTypesModal($event) {
    this.close();
    this.orderTypesModal.open();
    $event.preventDefaults();
  }
}
