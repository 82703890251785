<ng-container
  *ngIf="{
    accounts: (accounts$ | async),
    selectedAccount: (selectedAccount$ | async)
  } as view"
>
  <div class="account-selector" *ngIf="!hasAccountRetrievalError">
    <ng-container *ngIf="view.selectedAccount?.accountName && isChangeOrder">
      <span class="d-block c11n-text-xs">{{ content.labels.account }}</span>

      <div
        class="account-selector__title"
        [innerHTML]="
          (view.selectedAccount?.accountName | tweLongNameSplit) +
          (view.selectedAccount?.marginCode === marginIndicator
            ? (' (' + view.selectedAccount.marginCode + ')' | titlecase)
            : '')
        "
        data-testid="selected-account-label"
      ></div>
    </ng-container>

    <div *ngIf="!isChangeOrder" data-testid="account-selector">
      <label c11n-label for="account-selector">{{ content.labels.account }}</label>
      <account-selector
        [accountSelectorConfigProvider]="accountSelectorConfigProvider"
        [brokerageAccountProvider]="brokerageAccountProvider"
        [formControlProvider]="formControlProvider"
        [accountSelectorControlPlaneProvider]="accountSelectorControlPlaneProvider"
      ></account-selector>
    </div>
  </div>
</ng-container>
