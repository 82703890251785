import { ScreenSizeActionsUnion, ScreenSizeActionTypes } from '../../actions/screen-size/screen-size.actions';
import { initialScreenSizeState, ScreenSizeState } from '../../states/screen-size.state';

export function screenSizeReducer(
  state: ScreenSizeState = initialScreenSizeState,
  action: ScreenSizeActionsUnion
): ScreenSizeState {
  switch (action.type) {
    case ScreenSizeActionTypes.SET_SCREEN_SIZE:
      return { screenSize: action.payload };
    default:
      return state;
  }
}
