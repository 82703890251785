import { Component, Input, OnInit } from '@angular/core';
import { AdobeAnalyticsService } from '@app/core/services';
import { OrderEnums } from '@app/etfs-equities/enums';
import { JsonContent } from '@app/etfs-equities/models';
import { TradeTicketService } from '@app/etfs-equities/services';
import content from '@content/content.json';

@Component({
  selector: 'twe-duration-control',
  templateUrl: './duration-control.component.html',
  styleUrls: ['./duration-control.component.scss'],
})
export class DurationControlComponent implements OnInit {
  // Decorators...
  @Input()
  inEveningOrderFlow = false;

  //  Public variables...

  content: JsonContent | undefined = content;

  durations = OrderEnums.Durations;

  constructor(public tradeTicketService: TradeTicketService, public adobeService: AdobeAnalyticsService) {}

  ngOnInit(): void {
    this.setEveningDuration();
  }

  get durationType() {
    if (
      (this.tradeTicketService?.orderTypeIsMarket() || this.tradeTicketService?.transactionTypeIsSellShort()) &&
      !this.inEveningOrderFlow
    ) {
      return this.content?.labels.day;
    }
    if (this.inEveningOrderFlow) {
      return this.content?.labels.evening;
    }
    return null;
  }

  setEveningDuration() {
    if (
      this.tradeTicketService.tradeTicket.controls.duration.value !== OrderEnums.Durations.EVENING &&
      this.inEveningOrderFlow
    ) {
      this.tradeTicketService.tradeTicket.controls.duration.setValue(OrderEnums.Durations.EVENING);
    }
  }
}
