import { Account } from '@app/etfs-equities/models';
import { SingleErrorFormControl } from '@vanguard/trade-standard-forms-lib-ng-17';
import { Observable, Subject } from 'rxjs';

// models: https://bitbucket.opst.c1.vanguard.com/projects/TRADECX/repos/trade-micro-front-ends.frontend/browse/projects/account-selector/models/account-selector.component.model.ts

export interface AccountSelectorAccount extends Omit<Account.Account, 'accountId'> {
  accountId: string;
}

export interface BrokerageAccountProvider {
  getBrokerageAccounts: () => Observable<Array<AccountSelectorAccount>>;
}

export interface FormControlProvider {
  getFormControl: () => SingleErrorFormControl<string>;
}

export interface AccountSelectorControlPlaneProvider {
  getControlPlane: () => Subject<ControlPlaneEvent>;
}

export interface ControlPlaneEvent {
  eventType: ControlPlaneEventType;
  eventData?: any;
}

export enum ControlPlaneEventType {
  NOOP = 'NOOP',
  REFRESH_FUNDS_AVAILABLE = 'REFRESH_FUNDS_AVAILABLE',
  REFRESH_BROKERAGE_ACCOUNTS = 'REFRESH_BROKERAGE_ACCOUNTS',
  FORCE_CHANGE_DETECTION = 'FORCE_CHANGE_DETECTION',
}
