import { FundsAvailable } from '@app/etfs-equities/models/funds-available.model';

export interface FundsAvailableState {
  fundsAvailable: FundsAvailable | null;
  isLoading: boolean;
  loadingFailed: boolean;
  panelIsOpen: boolean;
}

export const initialFundsAvailableState: FundsAvailableState = {
  fundsAvailable: null,
  isLoading: false,
  loadingFailed: false,
  panelIsOpen: false,
};
