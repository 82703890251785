import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingContext } from '@app/core/interceptors/loading-interceptor';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import {
  OrderCancel,
  OrderCancelResponse,
  OrderCancelSubmissionData,
  OrderCancelValidationResponse,
} from '@etfs-equities/models/order-cancel';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrderCancelService {
  // Inform subscribers that triggered rules should be displayed.
  triggeredRules$ = new Subject<OrderCancelValidationResponse | OrderCancelResponse>();

  // Inform subscribers that a server error has occurred during validation.
  httpError$ = new Subject<void>();

  // Loading states...
  hasSubmitTriggeredRules = false;
  loadCancelOrderFailed = false;
  content: JsonContent = content;

  constructor(
    private readonly http: HttpClient,
    private readonly cookieService: CookieService,
    private readonly envService: EnvironmentService
  ) {}

  validate(orderId): Observable<OrderCancelValidationResponse> {
    return this.http
      .get<OrderCancelValidationResponse>(
        this.envService.getApiUrlBaseOnRoute() + `api/trade-cancel/validate?orderId=${orderId}`,
        {
          headers: new HttpHeaders({
            'TWE-XSRF-TOKEN': this.cookieService.get('tweXsrfToken'),
          }),
          withCredentials: true,
          context: new HttpContext().set(LoadingContext, { showLoading: true, status: 'submitRequest' }),
        }
      )
      .pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  submit(orderId, acceptedWarningRules?: OrderCancelSubmissionData): Observable<OrderCancelResponse> {
    return this.http
      .post<OrderCancelResponse>(
        this.envService.getApiUrlBaseOnRoute() + `api/trade-cancel/submit?orderId=${orderId}`,
        acceptedWarningRules,
        {
          headers: new HttpHeaders({
            'TWE-XSRF-TOKEN': this.cookieService.get('tweXsrfToken'),
          }),
          withCredentials: true,
          context: new HttpContext().set(LoadingContext, { showLoading: true, status: 'submitRequest' }),
        }
      )
      .pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  loadCancelOrder(accountId: string, orderId: string, type = 'cancel'): Observable<OrderCancel> {
    const url = `${this.envService.getApiUrlBaseOnRoute()}api/order-details?accountId=${accountId}&orderId=${orderId}&type=${type}`;

    return this.http
      .get<OrderCancel>(url, {
        withCredentials: true,
        context: new HttpContext().set(LoadingContext, { showLoading: true }),
      })
      .pipe(
        tap(() => {
          this.loadCancelOrderFailed = false;
        }),
        catchError((error: HttpErrorResponse) => {
          this.loadCancelOrderFailed = true;
          return throwError(() => error);
        })
      );
  }

  private handleHttpError(error: HttpErrorResponse) {
    this.httpError$.next();
    return throwError(() => error);
  }
}
