<c11n-modal-dialog #modal [headingText]="content.symbolKeywordModal.title" (closeEvent)="onClose()">
  <div c11n-modal-dialog-body>
    <p class="twe-c11n-color-ink-400" [innerHTML]="content.symbolKeywordModal.description"></p>

    <p [innerHTML]="content.symbolKeywordModal.xyzCompany" class="c11n-text-md c11n-space-stack-2x twe-text-bold"></p>
    <div class="d-flex justify-content-between border-bottom-666 c11n-space-stack-2x">
      <p
        class="c11n-space-stack-1x c11n-space--force c11n-text-xs twe-c11n-color-ink-400"
        [innerHTML]="content.symbolKeywordModal.leftColumnTitle"
      ></p>
      <p
        class="c11n-space-stack-1x c11n-space--force c11n-text-xs twe-c11n-color-ink-400"
        [innerHTML]="content.symbolKeywordModal.rightColumnTitle"
      ></p>
    </div>

    <div
      class="d-flex justify-content-between modal-security-type-row c11n-space-stack-2x"
      *ngFor="let stock of content.symbolKeywordModal.securityType; let last = last"
      [ngClass]="{ 'border-bottom-666': last }"
    >
      <p class="c11n-space-stack-2x c11n-text-sm twe-text-bold" [innerHTML]="stock.name"></p>
      <p class="c11n-space-stack-2x c11n-text-sm twe-text-bold" [innerHTML]="stock.ticker"></p>
    </div>

    <p class="c11n-text-sm mb-3 mt-4 twe-c11n-color-ink-400" [innerHTML]="content.symbolKeywordModal.note"></p>
  </div>
</c11n-modal-dialog>
