import { Component, Input } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import { WindowService } from '@app/etfs-equities/services';
import content from '@content/content.json';

@Component({
  selector: 'twe-service-unavailable-msg',
  templateUrl: './service-unavailable-msg.component.html',
  styleUrls: ['./service-unavailable-msg.component.scss'],
})
export class ServiceUnavailableMsgComponent {
  public content: JsonContent = content;

  @Input() iconType = 'default';

  constructor(public readonly windowService: WindowService) {}
}
