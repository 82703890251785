import { Component, Input } from '@angular/core';
import { CardCapComponentBackgroundColors } from '@app/etfs-equities/enums/open-orders/open-orders.enum';

@Component({
  selector: 'twe-card-cap',
  templateUrl: './card-cap.component.html',
  styleUrl: './card-cap.component.scss',
})
export class CardCapComponent {
  @Input() isShortCap = false;
  @Input() backgroundColor!: CardCapComponentBackgroundColors;
  @Input() leftContent: string;
  @Input() rightContent: string;
}
