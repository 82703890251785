import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EcnHours, EcnHoursApiResponse } from '@etfs-equities/models';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExchangeService {
  private readonly MSECS_PER_DAY = 24 * 60 * 60 * 1000;

  constructor(private readonly envService: EnvironmentService, private readonly http: HttpClient) {}

  getEcnHours(): Observable<EcnHours> {
    const url = `${this.envService.getApiUrlBaseOnRoute()}api/extended-hours`;

    return this.http.get<EcnHoursApiResponse>(url, { withCredentials: true }).pipe(
      map((response) => {
        return {
          openTime: response.openTime,
          closeTime: response.closeTime,
          clockOffset: moment.utc().diff(moment(response.serverTime)) % this.MSECS_PER_DAY, // in case client clock's calendar day is off
        };
      }),
      catchError(() => {
        // If the midtier call fails, return null values -- this will disallow EH trading
        return of({
          openTime: null,
          closeTime: null,
          clockOffset: 0,
        });
      })
    );
  }
}
