import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tweLongNameSplit',
})
export class LongNameSplit implements PipeTransform {
  transform(value: any) {
    const longNameSplit = value.split('—');
    return `<span class="twe-text-bold"> ${longNameSplit[0]}</span> — ${longNameSplit[1]} — ${longNameSplit[2]}`;
  }
}
