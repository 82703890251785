<ng-container
  *ngIf="{
    env: (env$ | async)
  }"
>
  <c11n-modal-dialog
    #nonVanguardMutualFundModal
    [headingText]="content.nonVanguardMutualFund.heading"
    [primaryButtonLabel]="content.nonVanguardMutualFund.tradeNonVanguarMutualFundsButton"
    [secondaryButtonLabel]="content.nonVanguardMutualFund.cancelButton"
    (primaryClick)="goToExternalLink()"
    (secondaryClick)="cancel()"
    (closeEvent)="cancel()"
  >
    <div c11n-modal-dialog-body>
      <p class="c11n-text-md mb-0" role="alert">{{ content?.nonVanguardMutualFund.body }}</p>
    </div>
  </c11n-modal-dialog>
</ng-container>
