<c11n-modal-dialog
  #genericErrorModal
  (closeEvent)="setMarketDataIsLoading(false)"
  [headingText]="content.genericErrorModal.title"
  [primaryButtonLabel]="content.labels.ok"
  modalId="genericErrorModal"
  variant="error"
>
  <div c11n-modal-dialog-body>
    <p>{{ content.genericErrorModal.body }}</p>
  </div>
</c11n-modal-dialog>
