import { QuoteSecurityTypeEnum } from '@app/etfs-equities/enums/quote.enum';
import { Account, Quote } from '@app/etfs-equities/models';
import { isQuoteVgETF } from '@app/etfs-equities/utils';
import { createSelector } from '@ngrx/store';
import { MarketDataResponseVendorEnum } from '@vanguard/invest-api-client-typescript-axios';

import { QuoteState, TayneState } from '../../states';
import { selectAccountIsMargin, selectHoldings } from '../account/account.selectors';
import { selectTayneState } from '../tayne/tayne.selectors';

export const selectQuoteState = createSelector(selectTayneState, (state: TayneState) => state.quote);

export const selectQuote = createSelector(selectQuoteState, (state: QuoteState) => state.quote);

export const selectHoldingsForCurrentQuote = createSelector(
  selectHoldings,
  selectQuote,
  (holdings: Account.Holding[], quote: Quote): Account.Holding[] => {
    if (!holdings.length || !quote || !quote.romCusip) {
      return [];
    }

    return holdings.filter((h) => h.cusip && h.cusip.toLowerCase() === quote.romCusip.toLowerCase());
  }
);

export const selectBalanceForCurrentQuote = createSelector(
  selectHoldingsForCurrentQuote,
  (holdings: Account.Holding[]): string => {
    return holdings[0]?.balance ?? '';
  }
);

export const selectCashAccountHoldingsForCurrentQuote = createSelector(
  selectHoldingsForCurrentQuote,
  (holdings: Account.Holding[]): Account.Holding[] => {
    const cashAccountIndicator = '1';

    return holdings.filter((holding) => holding.brokeragePosition.accountTypeCode === cashAccountIndicator);
  }
);

export const selectMarginAccountHoldingsForCurrentQuote = createSelector(
  selectHoldingsForCurrentQuote,
  (holdings: Account.Holding[]): Account.Holding[] => {
    const marginAccountIndicator = '2';

    return holdings.filter((holding) => holding.brokeragePosition.accountTypeCode === marginAccountIndicator);
  }
);

export const selectShortAccountHoldingsForCurrentQuote = createSelector(
  selectHoldingsForCurrentQuote,
  (holdings: Account.Holding[]): Account.Holding[] => {
    const shortAccountIndicator = '3';

    return holdings.filter((holding) => holding.brokeragePosition.accountTypeCode === shortAccountIndicator);
  }
);

export const selectIsCashOnlyHoldings = createSelector(
  selectCashAccountHoldingsForCurrentQuote,
  selectMarginAccountHoldingsForCurrentQuote,
  selectShortAccountHoldingsForCurrentQuote,
  (cashHoldings, marginHoldings, shortHoldings): boolean => {
    return !!(cashHoldings.length && !marginHoldings.length && !shortHoldings.length);
  }
);

export const selectIsMarginOrShortOnlyHoldings = createSelector(
  selectCashAccountHoldingsForCurrentQuote,
  selectMarginAccountHoldingsForCurrentQuote,
  selectShortAccountHoldingsForCurrentQuote,
  selectAccountIsMargin,
  (cashHoldings, marginHoldings, shortHoldings, accountIsMargin): boolean => {
    return accountIsMargin && !!(!cashHoldings.length && (marginHoldings.length || shortHoldings.length));
  }
);

export const selectIsCashAndMarginOrShortHoldings = createSelector(
  selectCashAccountHoldingsForCurrentQuote,
  selectMarginAccountHoldingsForCurrentQuote,
  selectShortAccountHoldingsForCurrentQuote,
  selectAccountIsMargin,
  (cashHoldings, marginHoldings, shortHoldings, accountIsMargin): boolean => {
    return accountIsMargin && !!(cashHoldings.length && (marginHoldings.length || shortHoldings.length));
  }
);

export const selectSharesHeldForCash = createSelector(
  selectCashAccountHoldingsForCurrentQuote,
  (holdings: Account.Holding[]): number => (holdings.length > 0 ? holdings[0].quantity : 0)
);

export const selectSharesHeldForMargin = createSelector(
  selectMarginAccountHoldingsForCurrentQuote,
  (holdings: Account.Holding[]): number => (holdings.length > 0 ? holdings[0].quantity : 0)
);

export const selectSharesHeldForShort = createSelector(
  selectShortAccountHoldingsForCurrentQuote,
  (holdings: Account.Holding[]): number => (holdings.length > 0 ? holdings[0].quantity : 0)
);

export const selectQuoteIsEtf = createSelector(
  selectQuote,
  (quote: Quote) => !!quote && quote.isETF && quote.isETF !== 'false'
);

export const selectQuoteIsSubPenny = createSelector(
  selectQuote,
  (quote: Quote) => quote && quote.previousClosePrice && parseFloat(quote.previousClosePrice) < 0.01
);

export const selectQuoteIsIpo = createSelector(
  selectQuote,
  (quote: Quote) =>
    !!(
      quote &&
      (quote.securityType === QuoteSecurityTypeEnum.ETF || quote.securityType === QuoteSecurityTypeEnum.COMMON_STOCK) &&
      (!quote.outStandingShares || quote.outStandingShares === '0')
    )
);

export const selectQuoteIsVgETF = createSelector(selectQuote, (quote: Quote) => isQuoteVgETF(quote));

export const selectQuoteIsHalted = createSelector(
  selectQuote,
  (quote: Quote) => !!(quote && (quote.isTradingHalt === true || quote.isTradingHalt === 'true'))
);

export const selectIsFactset = createSelector(
  selectQuote,
  (quote: Quote) => (!!quote && quote.vendor === MarketDataResponseVendorEnum.FACTSET) || !quote
);
