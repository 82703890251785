import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '@etfs-equities/constants';
import {
  selectIsChangeOrderState,
  selectIsEditCostBasisState,
  selectOrderEveningDuration,
  selectTradeTicketTabLink,
  TayneState,
} from '@etfs-equities/store';
import { Store } from '@ngrx/store';
import { Subject, withLatestFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EditService implements OnDestroy {
  // private observables/subjects
  private readonly unsubscribe$ = new Subject<void>();

  // public observables/subjects
  public readonly editOrder$ = new Subject<void>();

  constructor(private readonly store: Store<TayneState>, private readonly router: Router) {
    this.watchForEditOrder();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private watchForEditOrder() {
    this.editOrder$
      .pipe(
        withLatestFrom(
          this.store.select(selectIsEditCostBasisState),
          this.store.select(selectTradeTicketTabLink),
          this.store.select(selectOrderEveningDuration),
          this.store.select(selectIsChangeOrderState)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([_editOrder, isEditCostBasis, tabLink, isEveningOrder, isChangeOrder]) => {
        const path = isEditCostBasis
          ? CONSTANTS.EDIT_COST_BASIS_PATH
          : isEveningOrder && !isChangeOrder
          ? CONSTANTS.EXTENDED_TRADING_PATH
          : CONSTANTS.TRADE_PATH;
        this.router.navigate([path], { queryParams: tabLink?.queryParams });
      });
  }
}
