import { AbstractControl, FormGroup } from '@angular/forms';
import { OrderEnums } from '@app/etfs-equities/enums';
import { TradeTicketService } from '@app/etfs-equities/services';

export class FormUtil {
  static controlHasError(formControl: AbstractControl, ruleName?: string): boolean {
    return (
      FormUtil.controlIsTouchedAndDirty(formControl) &&
      formControl.errors !== null &&
      (ruleName ? formControl.hasError(ruleName) : true)
    );
  }

  static controlIsTouchedAndDirty(formControl: AbstractControl): boolean {
    return formControl.dirty && formControl.touched;
  }

  static groupHasError(formGroup: FormGroup, ruleName?: string): boolean {
    if (!formGroup) {
      return false;
    }

    return ruleName ? formGroup.hasError(ruleName) : formGroup.errors !== null;
  }

  static getErrorContent(service: TradeTicketService, errorMap: object, controlName: string): string | void {
    if (!service || !errorMap || !controlName) return;

    for (const errorType in errorMap) {
      if (service.controlHasError(controlName, errorType)) return errorMap[errorType];
    }
  }

  static actionIsSellOrBuyToCover(action: OrderEnums.TransactionTypes): boolean {
    return action === OrderEnums.TransactionTypes.SELL || action === OrderEnums.TransactionTypes.BUY_TO_COVER;
  }
}
