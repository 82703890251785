import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { TayneState } from '@app/etfs-equities/store';
import { selectOrderCancel } from '@app/etfs-equities/store/selectors/order-cancel/order-cancel.selector';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { WindowService } from '../../services';

export const cancelSuccessGuard: CanActivateFn = (): Observable<boolean> => {
  const windowService = inject(WindowService);
  const store = inject(Store<TayneState>);
  return store.pipe(
    take(1),
    select(selectOrderCancel),
    map((orderCancel) => {
      if (!orderCancel) {
        windowService.navigateToExternalLink(environment.secureSiteUrls.orderStatus);
        return false;
      }
      return true;
    })
  );
};
