import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AdobeAnalyticsService } from '@app/core/services';
import {
  JsonContent,
  ProductResultSelection,
  ProductSearchConfigProvider,
  SearchResultsSelectionOptionEnum,
} from '@app/etfs-equities/models';
import { KeywordSearchService, TradeTicketService } from '@app/etfs-equities/services';
import { TayneState } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { selectIsIncapacitated } from '@etfs-equities/store/selectors/client-data/client-data.selectors';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-keyword-search-control',
  templateUrl: './keyword-search-control.component.html',
  styleUrls: ['./keyword-search-control.component.scss'],
})
export class KeywordSearchControlComponent implements OnInit, AfterViewInit {
  @ViewChild('productSearchComponent', { static: false })
  productSearchComponent!: ElementRef;

  @Input() parentGroup?: FormGroup;

  @Output() externalFocusEvent = new EventEmitter<void>();

  productSearchInput: HTMLInputElement;

  isIncapacitated$: Observable<boolean>;

  content: JsonContent = content;

  readonly productSearchConfigService: ProductSearchConfigProvider = {
    getConfig: () => ({
      productTypes: new Set(),
      displayResultsPerPageCount: 5,
      showChipsInAllSearchResults: true,
      minimumCharactersToSearch: 1,
      searchResultsSelectionOption: SearchResultsSelectionOptionEnum.LINE_SELECT,
      displaySearchColumns: {
        tickerAndLongName: true,
        minInitialInvestment: false,
        cusip: false,
        fundId: false,
      },
    }),
  };

  constructor(
    public tradeTicketService: TradeTicketService,
    public keywordSearchService: KeywordSearchService,
    public readonly adobeService: AdobeAnalyticsService,
    private readonly store: Store<TayneState>
  ) {}

  ngOnInit() {
    this.isIncapacitated$ = this.store.pipe(select(selectIsIncapacitated));
  }

  ngAfterViewInit() {
    if (this.productSearchComponent) {
      this.productSearchInput = this.productSearchComponent.nativeElement.querySelector('input');
    }
  }

  focusSymbolKeywordInput() {
    if (this.keywordSearchService.isSearchingByKeyword) {
      this.focusKeywordInput();
    } else {
      this.externalFocusEvent.emit();
    }
  }

  handleProductResultSelection(event: CustomEvent<ProductResultSelection>) {
    const { ticker, cusip } = event.detail;

    this.setValues(ticker, cusip);
    this.toggleAndClearKeywordSearch();
  }

  private focusKeywordInput() {
    if (this.productSearchInput) {
      this.productSearchInput.value = '';
      setTimeout(() => this.productSearchInput.focus());
    }
  }

  private setValues(ticker, cusip) {
    this.tradeTicketService.setKeyword(ticker);
    this.tradeTicketService.setSymbol(ticker);
    this.tradeTicketService.setKeywordCusip(cusip);
    this.tradeTicketService.handleSelectedKeyword();
  }

  private toggleAndClearKeywordSearch() {
    this.keywordSearchService.toggleKeywordSearch();
    this.keywordSearchService.clearKeywordSearchResults();

    this.externalFocusEvent.emit();
  }
}
