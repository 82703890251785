import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionCheckService } from '@app/core/services/session-check/session-check.service';
import { Order } from '@app/etfs-equities/models';
import { TradeTicketService, WindowService } from '@app/etfs-equities/services';
import { selectOrder, TayneState } from '@app/etfs-equities/store';
import { selectIsExitBtnClicked } from '@app/etfs-equities/store/selectors/client-data/client-data.selectors';
import { select, Store } from '@ngrx/store';
import { filter, fromEvent, Observable, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';

@Component({
  selector: 'twe-exit-dialog',
  templateUrl: './exit-dialog.component.html',
})
export class ExitDialogComponent implements OnInit, OnDestroy {
  // Public observables/subjects...

  isExitBtnClicked$: Observable<boolean>;
  order$: Observable<Order.Order>;

  //  Public variables...

  isBeacon = false;

  // Private observables/subjects...

  private readonly unsubscribe$ = new Subject<void>();
  private readonly _beforeunloadWindow$ = fromEvent(window, 'beforeunload');

  constructor(
    private readonly store: Store<TayneState>,
    private readonly sessionCheckService: SessionCheckService,
    private readonly tradeTicketService: TradeTicketService,
    private readonly windowService: WindowService
  ) {}

  // Getters/Setters...

  get beforeunloadWindow$() {
    return this._beforeunloadWindow$;
  }

  get tradeTicketIsTouchedAndDirty() {
    return this.tradeTicketService.tradeTicket.touched && this.tradeTicketService.tradeTicket.dirty;
  }

  ngOnInit(): void {
    this.isExitBtnClicked$ = this.store.pipe(select(selectIsExitBtnClicked));
    this.order$ = this.store.pipe(select(selectOrder));
    this.isBeacon = this.windowService.getIsBeacon();

    this.beforeunloadWindow$
      .pipe(
        withLatestFrom(this.order$, this.isExitBtnClicked$),
        filter(([_event, currentOrder, isExitBtnClicked]) => {
          return this.confirmExitDialogFlag(currentOrder, isExitBtnClicked);
        }),
        tap(([event]) => {
          if (/^((mailto:)|(tel:)).+/i.test(this.windowService.$document.activeElement?.getAttribute('href'))) {
            (this.windowService.$document.activeElement as HTMLElement).blur();
          } else {
            event.returnValue = true;
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  confirmExitDialogFlag(currentOrder: Order.Order, isExitBtnClicked: boolean): boolean {
    const activeElement = this.windowService.$document.activeElement;
    const isIdleModalRedirect = activeElement?.id === 'idle-timer-modal-yes-button';
    const hasNoShowAttribute = activeElement?.getAttribute('data-exit-dialog') === 'no';

    const noShowConditions =
      isIdleModalRedirect ||
      hasNoShowAttribute ||
      isExitBtnClicked ||
      this.isBeacon ||
      this.sessionCheckService.sessionFailed ||
      this.tradeTicketService.isOrderEqualTradeTicketForm(currentOrder);

    return this.tradeTicketIsTouchedAndDirty && !noShowConditions;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
