import { Injectable } from '@angular/core';
import { AdobeAnalyticsService } from '@app/core/services/adobe-analytics/adobe-analytics.service';

import { MedalliaService } from '../medallia/medallia.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(readonly medalliaService: MedalliaService, readonly adobeService: AdobeAnalyticsService) {}
}
