<ng-container
  *ngIf="{
    dollarBasedEnabled: (dollarBasedEnabled$ | async),
    quoteIsVgEtf: (quoteIsVgEtf$ | async),
  } as view"
>
  <div
    *ngIf="!tradeTicketService?.tradeFormIsValid() || marketDataService?.symbolNotFound"
    class="c11n-space-stack-3x c11n-space--force"
    data-testid="trade-details-alert"
  >
    <c11n-banner [headingText]="content?.validation.errorSummary" [hasCloseControl]="false" variant="error">
      <div class="c11n-text-md--crop">
        <ul>
          <li *ngIf="tradeTicketService?.controlHasError('brokerageAccountNumber')">{{ content?.labels.account }}</li>
          <li *ngIf="tradeTicketService?.controlHasError('action')">{{ content?.labels.transaction }}</li>
          <li
            *ngIf="
              tradeTicketService?.controlHasError('symbol') ||
              tradeTicketService.controlHasError('hasQuote') ||
              marketDataService?.symbolNotFound
            "
            >{{ content?.labels.symbol }}</li
          >
          <li *ngIf="tradeTicketService?.controlHasError('keyword')">{{ content?.labels.keyword }}</li>
          <li *ngIf="tradeTicketService?.controlHasError('amount') && view.quoteIsVgEtf && view.dollarBasedEnabled">{{
            content?.labels.amount
          }}</li>
          <li
            *ngIf="tradeTicketService?.controlHasError('amount') && (!view.quoteIsVgEtf || !view.dollarBasedEnabled)"
            >{{ content?.labels.numberOfShares }}</li
          >
          <li *ngIf="tradeTicketService?.controlHasError('orderType') && tradeTicketService.orderTypeIsVisible()">{{
            content?.labels.orderType
          }}</li>
          <li *ngIf="tradeTicketService?.limitPriceHasError() && tradeTicketService?.limitPriceIsVisible()">{{
            content?.labels.limitPrice
          }}</li>
          <li *ngIf="tradeTicketService?.stopPriceHasError() && tradeTicketService?.stopPriceIsVisible()">{{
            content?.labels.stopPrice
          }}</li>
          <li *ngIf="tradeTicketService?.controlHasError('duration') && tradeTicketService.durationIsVisible()">{{
            content?.labels.duration
          }}</li>
          <li *ngIf="tradeTicketService?.costBasisMethodHasError()">{{ content?.labels.costBasis }}</li>
          <li *ngIf="tradeTicketService?.controlHasError('securityAccountType')">{{
            content?.labels.marginTradeType
          }}</li>
        </ul>
      </div>
    </c11n-banner>
  </div>
</ng-container>
