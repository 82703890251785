<c11n-modal-dialog
  #costBasisMethodUnavailableModal
  [modalId]="'costBasisMethodUnavailable'"
  [variant]="'error'"
  [headingText]="content.costBasisMethodUnavailableModal.title"
  [primaryButtonLabel]="content.labels.ok"
>
  <div c11n-modal-dialog-body>
    <p class="c11n-text-md" [innerHtml]="content.costBasisMethodUnavailableModal.body1"></p>
    <p class="c11n-text-md" [innerHtml]="content.costBasisMethodUnavailableModal.body2"></p>
    <p class="c11n-text-md" [innerHtml]="content.costBasisMethodUnavailableModal.body3"></p>
  </div>
</c11n-modal-dialog>
