export enum Durations {
  DAY = 'Day',
  GTC = '60 Day (GTC)',
  EVENING = 'Evening',
}

export enum TransactionTypes {
  BUY = 'Buy',
  SELL = 'Sell',
  BUY_TO_COVER = 'Buy to Cover',
  SELL_SHORT = 'Sell Short',
}

export enum Types {
  LIMIT = 'Limit',
  MARKET = 'Market',
  STOP = 'Stop',
  STOP_LIMIT = 'Stop Limit',
}

export enum AmountTypes {
  DOLLARS = 'DOLLARS',
  SHARES = 'SHARES',
}

export enum AmountIndicators {
  DOLLARS = '1',
  SHARES = '2',
}

export enum ParentOrderStatus {
  OPEN = 'OPEN',
  ENTERED = 'ENTERED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export enum SubmitOrderPath {
  BUY = 'buy',
  SELL = 'sell',
}

export enum QueryParamsForTransactionType {
  BUY = 'buy',
  SELL = 'sel',
  BUY_TO_COVER = 'buyc',
  SELL_SHORT = 'shor',
}

export enum BusinessWorkStreamEnum {
  AUTO_INVEST_INTO_ETFS = 'AUTO_INVEST_INTO_ETFS',
}
