<twe-generic-tooltip-modal
  #changeOrderTooltipModal
  id="change-order-tooltip-modal"
  data-testid="change-order-tooltip-modal"
  [headingText]="content?.changeOrderModal?.title"
>
  <p>{{ content?.changeOrderModal?.changeOrderBody1 }}</p>
  <p>
    <span>{{ content?.changeOrderModal?.changeOrderBody2 }}</span>
    <a c11n-link variant="primary-reinforced" [attr.href]="orderStatusLink"> {{ content?.openOrders?.orderStatus }}</a>
    <span>{{ content?.changeOrderModal?.changeOrderBody3 }}</span>
  </p>
</twe-generic-tooltip-modal>

<twe-generic-tooltip-modal
  #editCostBasisTooltipModal
  id="edit-cost-basis-tooltip-modal"
  data-testid="edit-cost-basis-tooltip-modal"
  [headingText]="content?.guidelet?.editCostBasis?.title"
>
  <p>{{ content?.guidelet?.editCostBasis?.body1 }}</p>
  <p>{{ content?.guidelet?.editCostBasis?.body2 }}</p>
  <p>{{ content?.guidelet?.editCostBasis?.body3 }}</p>
</twe-generic-tooltip-modal>

<div class="details details--border-bottom-gray c11n-space-stack-2x c11n-space--force">
  <div class="d-flex align-items-center">
    <h2 class="twe-responsive-h2-header">{{ title }}</h2>
    <c11n-icon-button
      *ngIf="isChangeOrder"
      data-testid="change-order--guidelet"
      id="change-order--guidelet"
      [labelText]="content?.changeOrderModal.linkToModal"
      buttonType="button"
      size="small"
      variant="ghost"
      iconName="help"
      (clickEvent)="changeOrderTooltipModal.modal.openModalDialog($event)"
    ></c11n-icon-button>

    <c11n-icon-button
      *ngIf="!isChangeOrder"
      data-testid="edit-cost-basis--guidelet"
      id="edit-cost-basis--guidelet"
      [labelText]="content?.guidelet?.editCostBasis?.ariaLabel"
      buttonType="button"
      size="small"
      variant="ghost"
      iconName="help"
      (clickEvent)="editCostBasisTooltipModal.modal.openModalDialog($event)"
    ></c11n-icon-button>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="item-heading c11n-space-stack-1x" data-testid="change-order-transaction">{{
        content?.changeOrder.transaction
      }}</div>
      <span class="item-data">{{ order?.transactionType || '&mdash;' }}</span>
      <span class="item-heading c11n-space-stack-1x c11n-space--force" *ngIf="order?.securityAccountType">{{
        order?.securityAccountType
      }}</span>
    </div>
    <div class="col-4">
      <div class="item-heading c11n-space-stack-1x">{{ content?.labels.symbol }}</div>
      <span class="item-data">{{ order?.ticker || '&mdash;' }}</span>
    </div>
    <div class="col-4" *ngIf="isChangeOrder">
      <div class="item-heading c11n-space-stack-1x">{{ content?.changeOrder.orderNumber }}</div>
      <span class="item-data">{{ order?.orderId || '&mdash;' }}</span>
    </div>
    <div class="col-4" *ngIf="!isChangeOrder">
      <div class="item-heading c11n-space-stack-1x">{{ content?.labels.shares }}</div>
      <span class="item-data">{{ order?.shares || '&mdash;' }}</span>
    </div>
  </div>
  <div class="row" *ngIf="!isChangeOrder">
    <div class="col-4">
      <div class="item-heading c11n-space-stack-1x">{{ content?.labels.orderType }}</div>
      <span class="item-data">{{ order?.orderType || '&mdash;' }}</span>
    </div>
    <div class="col-4" *ngIf="order?.orderType === orderTypes.STOP || order?.orderType === orderTypes.STOP_LIMIT">
      <div class="item-heading c11n-space-stack-1x">{{ content?.labels.stopPrice }}</div>
      <span class="item-data">{{ order?.stopPrice || '&mdash;' }}</span>
    </div>
    <div class="col-4" *ngIf="order?.orderType === orderTypes.LIMIT || order?.orderType === orderTypes.STOP_LIMIT">
      <div class="item-heading c11n-space-stack-1x">{{ content?.labels.limitPrice }}</div>
      <span class="item-data">{{ order?.limitPrice || '&mdash;' }}</span>
    </div>
  </div>
  <div class="row" *ngIf="!isChangeOrder">
    <div class="col-4">
      <div class="item-heading c11n-space-stack-1x">{{ content?.labels.duration }}</div>
      <span class="item-data">{{ order?.orderDuration || '&mdash;' }}</span>
    </div>
    <div class="col-4">
      <div class="item-heading c11n-space-stack-1x">{{ content?.changeOrder.orderNumber }}</div>
      <span class="item-data">{{ order?.orderId || '&mdash;' }}</span>
    </div>
  </div>
</div>
