export enum DataLayerEventsEnum {
  CALL_TO_ACTION = 'callToAction',
  PAGE_LOADED = 'pageLoaded',
  PAGE_LOAD_COMPLETED = 'pageLoadCompleted',
  PROCESS_ABANDONED = 'processAbandoned',
  PROCESS_COMPLETE = 'processComplete',
  PROCESS_START = 'processStart',
  PROCESS_STEP_SUCCESS = 'processStepSuccess',
  PROCESS_SIDE_STEP_SUCCESS = 'processSideStepSuccess',
  PROCESS_ERROR = 'processError',
  SUB_PAGE_VIEWED = 'subPageViewed',
}
