import { OrderCancel, OrderCancelResponse } from '@etfs-equities/models/order-cancel';

export interface OrderCancelState {
  orderCancel: OrderCancel | null;
  orderCancelResponse: OrderCancelResponse | null;
  orderLoadError: boolean;
}

export const initialOrderCancelResponseState: OrderCancelState = {
  orderCancel: null,
  orderCancelResponse: null,
  orderLoadError: false,
};
