import { Action } from '@ngrx/store';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum AppActionTypes {
  NOOP_ACTION = '[App] No operation Action',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class NoopAction implements Action {
  readonly type = AppActionTypes.NOOP_ACTION;
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createNoopAction = () => {
  return new NoopAction();
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type AppActionTypesUnion = NoopAction;
