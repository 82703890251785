import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { AdobeAnalyticsService, GatekeeperService } from '@app/core/services';
import { CancelErrorModalComponent } from '@app/etfs-equities/components/cancel-error-modal/cancel-error-modal.component';
import { CancelWarningModalComponent } from '@app/etfs-equities/components/cancel-warning-modal/cancel-warning-modal.component';
import { GenericErrorModalComponent } from '@app/etfs-equities/components/generic-error-modal/generic-error-modal.component';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { OrderEnums, TriggeredRuleEnums } from '@app/etfs-equities/enums';
import { Account, JsonContent, Quote } from '@app/etfs-equities/models';
import { AccountService, WindowService } from '@app/etfs-equities/services';
import { OrderCancelService } from '@app/etfs-equities/services/order-cancel/order-cancel.service';
import {
  createSubmitOrderCancelAction,
  createValidateOrderCancelAction,
} from '@app/etfs-equities/store/actions/order-cancel/order-cancel.action';
import {
  selectOrderCancel,
  selectOrderCancelBrokerageAccountNumber,
  selectOrderCancelFormatSharesDisplay,
  selectOrderCancelSecuritySymbol,
} from '@app/etfs-equities/store/selectors/order-cancel/order-cancel.selector';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { OrderCancel, TriggeredTradingRule } from '@etfs-equities/models/order-cancel';
import {
  createLoadAccountsAction,
  createLoadQuoteAction,
  createSelectAccountAction,
  selectAccounts,
  selectQuote,
  selectSelectedAccount,
  TayneState,
} from '@etfs-equities/store';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { filter, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'twe-cancel',
  templateUrl: './cancel-page.component.html',
  styleUrls: ['./cancel-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancelPageComponent implements OnInit, OnDestroy {
  //  Decorators...

  @ViewChild(CancelWarningModalComponent)
  cancelWarningModal: CancelWarningModalComponent | undefined;

  @ViewChild(CancelErrorModalComponent)
  errorModal: CancelErrorModalComponent | undefined;

  @ViewChild(GenericErrorModalComponent)
  cancelGenericErrorModal: GenericErrorModalComponent | undefined;

  //  Public observables/subjects..

  unsubscribe$: Subject<void> = new Subject();
  orderCancel$: Observable<OrderCancel>;
  accounts$: Observable<Account.Account[]>;
  selectedAccount$: Observable<Account.Account>;
  quote$: Observable<Quote>;
  formatSharesDisplay$: Observable<string>;
  hardStopRules$: Observable<TriggeredTradingRule[]>;
  changeOrderEnabled$: Observable<boolean>;

  //  Public variables..

  readonly TRADE_PATH = CONSTANTS.TRADE_PATH;

  triggeredWarningRules: TriggeredTradingRule[] = [];
  triggeredReviewReleaseRules: TriggeredTradingRule[] = [];
  isProcessing = false;
  hasAccountRetrievalError = false;
  paramsValid = true;
  isAccountExist = false;
  isSubmitWithTriggeredRules = false;
  content: JsonContent = content;
  amountTypes = OrderEnums.AmountTypes;

  constructor(
    private readonly store: Store<TayneState>,
    private readonly route: ActivatedRoute,
    private readonly accountService: AccountService,
    private readonly orderCancelService: OrderCancelService,
    private readonly windowService: WindowService,
    private readonly adobeService: AdobeAnalyticsService,
    private readonly gatekeeperService: GatekeeperService
  ) {}

  // Getters/Setters...

  get hasCriticalOrderCancelError() {
    return this.orderCancelService.loadCancelOrderFailed || this.hasAccountRetrievalError || !this.paramsValid;
  }

  ngOnInit() {
    this.orderCancel$ = this.store.pipe(select(selectOrderCancel));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.accounts$ = this.store.pipe(select(selectAccounts));
    this.quote$ = this.store.pipe(select(selectQuote));
    this.formatSharesDisplay$ = this.store.pipe(select(selectOrderCancelFormatSharesDisplay));

    this.changeOrderEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_CHANGE_ORDER);

    this.watchForOrderCancelingError();
    this.fetchPageData();
    this.watchForSelectedAccount();
    this.watchForAccountRetrievalError();

    this.hardStopRules$ = this.orderCancelService.triggeredRules$.pipe(
      map((rules) =>
        rules.triggeredTradingRules.filter((rule) => rule.ruleAction === TriggeredRuleEnums.RuleActions.ERROR)
      ),
      tap((stopRules) => {
        if (stopRules.length) {
          this.errorModal.open();
        }
      })
    );

    this.orderCancelService.triggeredRules$
      .pipe(
        withLatestFrom(this.hardStopRules$),
        tap(([_rules, hardRules]) => {
          if (hardRules.length) {
            this.adobeService.sendAdobeLaunchProcessError(
              hardRules.map((rule) => rule.ruleId).join(','),
              `${CONSTANTS.ADOBE_PROCESS_TYPE} - Errors`
            );
          }
        }),
        map(([rules, hardRules]) => (!hardRules.length ? rules.triggeredTradingRules : [])),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((rules) => {
        if (rules.length) {
          this.isSubmitWithTriggeredRules = this.orderCancelService.hasSubmitTriggeredRules;
          this.handleNonErrorTriggeredRules(rules);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submitOrderCancel() {
    this.store.dispatch(
      createSubmitOrderCancelAction({
        allAcceptedWarningRules: this.takeRulesIds(),
      })
    );
  }

  navigateToOrderStatus() {
    this.windowService.navigateToExternalLink(environment.secureSiteUrls.orderStatus);
  }

  handleKeepOrder(): void {
    this.windowService.router.navigate([CONSTANTS.OPEN_ORDERS_PATH]);
  }

  onValidateOrderCancel() {
    if (!this.isProcessing) {
      this.isProcessing = true;
      this.validateOrderCancel();
      timer(500)
        .pipe(take(1))
        .subscribe(() => {
          this.isProcessing = false;
        });
    }
  }

  validateOrderCancel() {
    if (!this.isAccountExist) {
      this.cancelGenericErrorModal.modal.openModalDialog();
      return;
    }
    this.store.dispatch(createValidateOrderCancelAction());
  }

  private loadOrderDetailsAndAccounts() {
    this.route.queryParamMap
      .pipe(
        map((params) => [params.get('accountId'), params.get('orderId')]),
        filter(([accountId, orderId]) => !!accountId && !!orderId),
        tap(([accountId, orderId]) => {
          this.paramsValid = accountId !== 'null' && orderId !== 'null';
          if (this.paramsValid) {
            this.store.dispatch(createLoadAccountsAction());
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private loadQuote() {
    this.store
      .pipe(select(selectOrderCancelSecuritySymbol))
      .pipe(
        tap((symbol) => {
          if (symbol) {
            this.store.dispatch(createLoadQuoteAction(symbol));
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private selectAccount() {
    combineLatest([this.store.select(selectAccounts), this.store.select(selectOrderCancelBrokerageAccountNumber)])
      .pipe(
        tap(([accounts, orderDetailsBrokerageAccountNumber]) => {
          if (accounts.length && orderDetailsBrokerageAccountNumber) {
            this.store.dispatch(createSelectAccountAction(orderDetailsBrokerageAccountNumber));
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private fetchPageData() {
    this.loadOrderDetailsAndAccounts();
    this.loadQuote();
    this.selectAccount();
  }

  private handleNonErrorTriggeredRules(rules: TriggeredTradingRule[]) {
    this.triggeredWarningRules = rules.filter((rule) => rule.ruleAction === TriggeredRuleEnums.RuleActions.WARNING);
    this.triggeredReviewReleaseRules = rules.filter(
      (rule) => rule.ruleAction === TriggeredRuleEnums.RuleActions.REVIEW_RELEASE
    );

    if (this.triggeredWarningRules.length) {
      this.cancelWarningModal.open();
      this.adobeService.sendAdobeLaunchProcessError(
        this.triggeredWarningRules.map((rule) => rule.ruleId).join(','),
        `${CONSTANTS.ADOBE_PROCESS_TYPE} - Alerts`
      );
    } else {
      this.submitOrderCancel();
    }
  }

  private takeRulesIds(): string[] {
    const triggeredReviewReleaseRuleIds = this.triggeredReviewReleaseRules.map((rule) => rule.ruleId);
    const triggeredWarningRuleIds = this.triggeredWarningRules.map((rule) => rule.ruleId);

    return [...triggeredReviewReleaseRuleIds, ...triggeredWarningRuleIds];
  }

  private watchForSelectedAccount() {
    this.selectedAccount$
      .pipe(
        filter((account) => !!account),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((account: Account.Account) => {
        this.isAccountExist = Object.getOwnPropertyNames(account).length > 0;
        if (!this.isAccountExist) {
          this.cancelGenericErrorModal.modal.openModalDialog();
        }
      });
  }

  private watchForAccountRetrievalError() {
    this.accountService.accountRetrievalError$
      .pipe(
        tap(() => (this.hasAccountRetrievalError = true)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.accountService.transactableAccountsRetrievalError$
      .pipe(
        tap(() => this.cancelGenericErrorModal.modal.openModalDialog()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForOrderCancelingError() {
    this.orderCancelService.httpError$
      .pipe(
        tap(() => this.cancelGenericErrorModal.modal.openModalDialog()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
