import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { UnauthorizedInterceptor } from './unauthorized-interceptor';

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UnauthorizedInterceptor,
    multi: true,
  },
];
