// import { ScreenSizeState, selectTayneState, TayneState } from '@etfs-equities/store';
// import { createSelector } from '@ngrx/store';

import { createSelector } from '@ngrx/store';

import { ScreenSizeState, TayneState } from '../../states';
import { selectTayneState } from '../tayne/tayne.selectors';

export const selectScreenSize = createSelector(selectTayneState, (state: TayneState) => state.screenSize);

export const selectIsScreenLarge = createSelector(
  selectScreenSize,
  (state: ScreenSizeState) => state?.screenSize === 'LG'
);

export const selectIsScreenSmall = createSelector(
  selectScreenSize,
  (state: ScreenSizeState) => state?.screenSize === 'SM'
);

export const selectIsScreenXSmall = createSelector(
  selectScreenSize,
  (state: ScreenSizeState) => state?.screenSize === 'XS'
);
