import { HttpContextToken, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoadingContextModel } from '@app/etfs-equities/models/loading.model';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { finalize } from 'rxjs';

export const LoadingContext = new HttpContextToken<LoadingContextModel>(() => ({
  showLoading: false,
  status: undefined,
}));

export const loadingInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const loadingService = inject(LoadingService);
  const loadingContext = req.context.get(LoadingContext);

  if (!loadingContext.showLoading) {
    return next(req);
  }

  const spinnerStatus = loadingContext.status;
  loadingService.loadingOn(spinnerStatus);

  return next(req).pipe(finalize(() => loadingService.loadingOff()));
};
