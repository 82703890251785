<ng-container
  *ngIf="{
    quote: (quote$ | async),
    order: (order$ | async)
  } as view"
>
  <div class="twe-card" data-testid="executedPrice">
    <div class="d-flex">
      <h2 class="twe-card-header">{{ content?.executedPrice.title }}</h2>
    </div>
    <div class="c11n-text-md c11n-space-stack-1x" data-testid="txt-quote-name">
      {{ (view.quote | tweQuoteTickerLongName) || '&mdash;' }}
    </div>
    <div
      class="c11n-text-xl twe-text-bold c11n-space-stack-1_5x"
      data-testid="txt-quote-value"
      [innerHTML]="(view.order?.executedPrice | tweSubDollarCurrency) || '$&mdash;'"
    ></div>
    <twe-shares-held> </twe-shares-held>
  </div>
</ng-container>
