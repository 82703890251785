export enum SecurityClassTypes {
  NULL = -99,
  COMMON_STOCK = 1,
  PREFERRED_STOCK = 2,
  CONVERTIBLE_PREFERRED_STOCK = 3,
  RIGHTS = 4,
  WARRANT = 5,
  UNITS = 6,
  MUTUAL_FUND = 7,
  LIMITED_PARTNERSHIP = 8,
  CALL_OPTION = 10,
  PUT_OPTION = 11,
  BANK_CALL_OPTION = 12,
  ESCROW = 13,
  CORPORATE_BOND = 20,
  CONVERTIBLE_BOND = 21,
  MUNICIPAL_BOND = 22,
  SHORT_TERM_PAPER = 23,
  BOND_UNIT = 24,
  MUNI_ASSESSMENT_DIST = 25,
  US_TREASURY_BILL = 30,
  US_TREASURY_NOTE = 31,
  US_TREASURY_BOND = 32,
  OTHER_GOVERNMENT = 33,
  US_TREASURY_ZERO_COUPON = 34,
  GOVERNMENT_MORTGAGE = 35,
  COLLATERALIZED_MORTGAGE_OBLIGATION = 36,
  FUND_UNIT = 40,
  TAX_SHELTER = 41,
  FUTURE = 50,
  COMMODITY = 51,
  WHEN_AS_AND_IF_ISSUED_EQUITY = 52,
  WHEN_AS_AND_IF_ISSUED_BOND = 53,
  PRECIOUS_METALS = 54,
  ESCROW_RECEIPTS = 60,
  BANKERS_ACCEPTANCE = 65,
  CERTIFICATES_OF_DEPOSIT = 68,
  COMMERCIAL_PAPER = 71,
  FIXED_INCOME_UNITS = 75,
  ETF = 76,
  VANGUARD_ETF = 77,
  MARKET_INDEX = 200,
  MISCELLANEOUS_FIXED_INCOME = 201,
  MISCELLANEOUS_STOCK = 202,
}
