<ng-container
  *ngIf="{
    isSellingAllHeldShares: (isSellingAllHeldShares$ | async),
    selectedAccount: (selectedAccount$ | async),
  } as view"
>
  <twe-input-label
    id="cost-basis-guidelet"
    data-testid="cost-basis-guidelet"
    [labelText]="content.labels.costBasis"
    [tooltipHeader]="content.guidelet.costBasis.title"
  >
    <p [innerHtml]="content.guidelet.costBasis.body"></p>
  </twe-input-label>

  <fieldset data-testid="costbasis-unavailable" [attr.aria-invalid]="tradeTicketService?.costBasisMethodHasError()">
    <legend class="visually-hidden">{{ content.labels.costBasis }}</legend>

    <div class="twe-c11n-color-ink-400 twe-flex-center-items" *ngIf="costBasisService.isLoadingTradeMethods">
      {{ content.loading.loading }}
      <c11n-spinner size="small" [spinnerStatus]="content.loading.loading"></c11n-spinner>
    </div>

    <div
      class="read-only c11n-text-md-bold c11n-space-stack-1_5x"
      data-testid="selected-cost-basis-label"
      *ngIf="!costBasisService?.isLoadingTradeMethods && !!tradeTicketService?.getCostBasisMethod()"
    >
      {{ (tradeTicketService?.getCostBasisMethod() | tweCostBasisLongName).label }}
      <button
        c11n-link
        type="button"
        iconName="edit"
        class="c11n-space-inline-left-0_5x c11n-space--force"
        (click)="loadCostBasisMethods()"
        *ngIf="!methodIsAvgCost"
        data-testid="edit-cost-basis-button"
        attr.aria-label="{{
          content.costBasisSelection.editCostBasis +
            ' ' +
            tradeTicketService?.tradeTicket?.controls.costBasisMethod.value +
            content.costBasisSelection.currentlySelected
        }}"
      >
        {{ content.labels.edit }}
      </button>
    </div>
    <div
      class="read-only c11n-text-md-bold c11n-space-stack-1_5x"
      *ngIf="!costBasisService?.isLoadingTradeMethods && !tradeTicketService?.getCostBasisMethod()"
    >
      {{ content.labels.unavailable }}
      <button
        c11n-link
        type="button"
        variant="primary-reinforced"
        class="c11n-space-inline-left-0_5x c11n-space--force"
        (click)="loadCostBasisMethods()"
      >
        {{ content.costBasisMethodUnavailableModal.viewDetails }}
      </button>
    </div>

    <div id="cost-basis-not-required-message" class="c11n-text-sm twe-c11n-color-ink-400 c11n-space-stack-1_5x">
      <p
        *ngIf="view.isSellingAllHeldShares && !methodIsAvgCost"
        [innerHTML]="
          tradeTicketService?.isSellAllLessThanOne()
            ? content.specIdNotAvailableSellAllUnderOne
            : content.costBasisMethodSelectionNotRequired
        "
      ></p>
    </div>

    <div id="dollar-based-cost-basis-hint" class="c11n-text-sm twe-c11n-color-ink-400 c11n-space-stack-1_5x">
      <p *ngIf="tradeTicketService?.amountTypeIsDollars() && !methodIsAvgCost">
        {{ content.dollarBasedCostBasisHint }}
      </p>
    </div>

    <div id="cost-basis-prev-avg-cost-sale-message" class="c11n-text-sm twe-c11n-color-ink-400 c11n-space-stack-1_5x">
      <p *ngIf="methodIsAvgCost">
        {{ content.costBasisPrevAvgCostSale.part1 }}
        <ng-container *ngIf="isBeacon">
          {{ content.costBasisPrevAvgCostSale.linkText }}
        </ng-container>
        <ng-container *ngIf="!isBeacon">
          <a
            c11n-link
            variant="primary-reinforced"
            iconName="pop-window"
            href="{{ costBasisUrl }}{{ view.selectedAccount?.accountId || '' }}"
            target="_blank"
            >{{ content.costBasisPrevAvgCostSale.linkText }}</a
          >
        </ng-container>
        {{ content.costBasisPrevAvgCostSale.part2 }}
      </p>
    </div>

    <div id="cost-basis-taxable-method-unavailable-message">
      <p
        *ngIf="
          costBasisUnavailableForHolding ||
          (!costBasisService?.isLoadingTradeMethods && !tradeTicketService?.getCostBasisMethod())
        "
        class="c11n-text-sm twe-c11n-color-ink-400 mt-2 mb-0"
      >
        {{ content.costTaxableDefaultMethodUnavailable.part1 }}
        <a c11n-link href="tel:+1-800-992-8327">800-992-8327</a>
        {{ content.costTaxableDefaultMethodUnavailable.part2 }}
      </p>
    </div>
  </fieldset>

  <twe-cost-basis-lot-error-modal></twe-cost-basis-lot-error-modal>

  <!-- Cost Basis Modal -->
  <twe-cost-basis-modal [isSellAll]="view.isSellingAllHeldShares"></twe-cost-basis-modal>

  <!-- Cost Basis Unavailable Modal -->
  <twe-cost-basis-unavailable-modal></twe-cost-basis-unavailable-modal>
</ng-container>
