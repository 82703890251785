<div class="d-flex align-items-center twe-mt-n075">
  <p class="c11n-text-xs mb-0"> {{ content?.labels.duration }}</p>
  <c11n-icon-button
    data-testid="guidelet-duration"
    id="duration-guidelet"
    [labelText]="content?.guidelet.duration.ariaLabel"
    buttonType="button"
    size="small"
    variant="ghost"
    iconName="help"
    (clickEvent)="
      durationTooltipModal.modal.openModalDialog($event);
      adobeService.sendAdobeTrackingOnClick('Duration - Tooltip', 'tooltip')
    "
  ></c11n-icon-button>
</div>

<div
  class="read-only c11n-text-md-bold c11n-space-stack-1_5x"
  data-testid="duration-day-read-only"
  *ngIf="
    tradeTicketService?.orderTypeIsMarket() || tradeTicketService?.transactionTypeIsSellShort() || inEveningOrderFlow;
    else durationToggle
  "
>
  {{ durationType }}
</div>

<ng-template #durationToggle>
  <fieldset class="mb-4" [attr.aria-invalid]="tradeTicketService?.controlHasError('duration')">
    <c11n-segmented-control
      color="yellow"
      size="small"
      [legendText]="content?.labels.duration"
      data-testid="radio-btn-group-duration"
      appSegmentControlReset
    >
      <c11n-segment [labelText]="content?.labels.day">
        <input
          c11n-segment-input
          id="durationDay"
          [formControl]="tradeTicketService?.tradeTicket?.controls?.duration"
          name="duration"
          type="radio"
          aria-describedby="duration-required-error"
          [value]="durations.DAY"
          (click)="adobeService.sendAdobeLaunchProcess('equityETFTicket-capture: Select Day duration')"
        />
      </c11n-segment>

      <c11n-segment [labelText]="content?.labels.sixtyDayGTC">
        <input
          c11n-segment-input
          id="durationGtc"
          [formControl]="tradeTicketService?.tradeTicket?.controls?.duration"
          name="duration"
          type="radio"
          aria-describedby="duration-required-error"
          [value]="durations.GTC"
          (click)="adobeService.sendAdobeLaunchProcess('equityETFTicket-capture: Select 60-Day duration')"
        />
      </c11n-segment>
    </c11n-segmented-control>

    <c11n-hint-error
      [hasError]="tradeTicketService?.controlHasError('duration', 'required')"
      [errorText]="content?.validation.duration"
      [hintErrorId]="'duration-required-error'"
      data-testid="txt-input-validation"
      [ngClass]="{ 'm-0': !tradeTicketService?.controlHasError('duration') }"
    ></c11n-hint-error>
  </fieldset>
</ng-template>
<div id="market-order-execution-hint" *ngIf="!tradeTicketService?.transactionTypeIsSellShort()">
  <p *ngIf="tradeTicketService?.orderTypeIsMarket() && !inEveningOrderFlow" class="c11n-text-sm twe-c11n-color-ink-400">
    {{ content?.executionHint.marketOrder }}
  </p>
</div>
<div id="sell-short-order-hint" *ngIf="tradeTicketService?.transactionTypeIsSellShort() && !inEveningOrderFlow">
  <p class="c11n-text-sm twe-c11n-color-ink-400" [innerHtml]="content?.guidelet.duration.sellShortOrder"></p>
</div>

<div id="evening-trade-execution-hint" *ngIf="inEveningOrderFlow">
  <p class="c11n-text-sm twe-c11n-color-ink-400" [innerHtml]="content?.executionHint.eveningOrder"></p>
</div>

<twe-duration-modal #durationModal></twe-duration-modal>
<twe-generic-tooltip-modal #durationTooltipModal [headingText]="content?.guidelet.duration.title">
  <p [innerHtml]="content?.guidelet.duration.dayOrder"></p>
  <p [innerHtml]="content?.guidelet.duration.goodTillCanceled"></p>
  <p [innerHtml]="content?.guidelet.duration.eveningOrder"></p>
  <button
    c11n-link
    type="button"
    (click)="durationTooltipModal.modal.closeDialogModal($event); durationModal.open('duration-guidelet')"
  >
    {{ content?.guidelet.duration.linkToModal }}
  </button>
</twe-generic-tooltip-modal>
