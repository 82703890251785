import {
  ExtendedHoursActions,
  ExtendedHoursActionTypes,
} from '@etfs-equities/store/actions/extended-hours/extended-hours.actions';
import { ExtendedHoursState, initialExtendedHoursState } from '@etfs-equities/store/states/extended-hours.state';

export const extendedHoursReducer = (
  state: ExtendedHoursState = initialExtendedHoursState,
  action: ExtendedHoursActions
): ExtendedHoursState => {
  switch (action.type) {
    case ExtendedHoursActionTypes.LOAD_ECNHOURS_SUCCESS:
      return {
        ...state,
        ecnHours: action.payload.ecnHours,
      };
    case ExtendedHoursActionTypes.ACCEPT_ECN_AGREEMENT:
      return {
        ...state,
        ecnAgreement: null,
      };
    case ExtendedHoursActionTypes.ACCEPT_ECN_AGREEMENT_SUCCESS:
      return {
        ...state,
        ecnAgreement: {
          status: action.payload,
          error: null,
        },
      };
    case ExtendedHoursActionTypes.ACCEPT_ECN_AGREEMENT_FAILED:
      return {
        ...state,
        ecnAgreement: {
          status: null,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
