<twe-balances-guidelet-modal #balancesGuideletModal></twe-balances-guidelet-modal>
<twe-balances-margin-modal #balancesMarginModal></twe-balances-margin-modal>

<twe-generic-tooltip-modal #balancesTooltipModal [headingText]="content.guidelet.balances.title">
  <p [innerHtml]="content.guidelet.balances.fundsAvailable"></p>
  <p [innerHtml]="content.guidelet.balances.settlementFund"></p>
  <button
    c11n-link
    type="button"
    (click)="
      balancesTooltipModal.modal.closeDialogModal($event);
      adobeService.sendAdobeTrackingOnClick('Balances-Learn more about balances & holdings', 'link', 'BalancesToolTip');
      balancesGuideletModal.open()
    "
  >
    {{ content.guidelet.balances.linkToModal }}
  </button>
</twe-generic-tooltip-modal>

<ng-container
  *ngIf="{
    funds: (fundsAvailable$ | async),
    account: (selectedAccount$ | async),
    accountIsMargin: (accountIsMargin$ | async),
    panelIsOpen: (panelIsOpen$ | async),
    isLoading: (isLoading$ | async),
    loadingFailed: (loadingFailed$ | async),
    accountIsPatternDayTrader: (accountIsPatternDayTrader$ | async),
    showShowDetailsBtn: (showShowDetailsBtn$ | async)
  } as view"
>
  <div class="twe-card" data-testid="fundsAvailable">
    <div class="d-flex align-items-baseline">
      <h2 class="twe-card-header">{{ content.fundsAvailable.cardTitle }}</h2>
      <c11n-icon-button
        class="c11n-text-lg-bold"
        [labelText]="content?.guidelet.balances.linkToModal"
        *ngIf="!view.accountIsMargin"
        buttonType="button"
        size="small"
        variant="ghost"
        iconName="help"
        (clickEvent)="
          balancesTooltipModal.modal.openModalDialog($event);
          adobeService.sendAdobeTrackingOnClick('clickTooltip-Balances', 'tooltip')
        "
      ></c11n-icon-button>

      <c11n-icon-button
        class="c11n-text-lg-bold"
        [labelText]="content?.balancesMarginModal?.balancesAltText"
        *ngIf="view.accountIsMargin"
        buttonType="button"
        size="small"
        variant="ghost"
        iconName="help"
        (clickEvent)="balancesMarginModal.open()"
      ></c11n-icon-button>
    </div>

    <div
      *ngIf="view.loadingFailed"
      class="c11n-text-sm twe-c11n-color-ink-400"
      [innerHtml]="content.fundsAvailable.loadingFailed"
    ></div>

    <div class="flex-column-container">
      <div>
        <twe-refresh-button
          *ngIf="view.account"
          [isLoading]="view.isLoading"
          [hasFailed]="view.loadingFailed"
          (refresh)="refresh()"
          [title]="'Refresh'"
          [ariaLabel]="'Account balances, as of ' + (view.funds?.asOfDate | tweDate)"
        >
          <span class="c11n-text-xs tds-mr-2 d-inline-block">
            {{ content.labels.asOf }} {{ view.funds?.asOfDate | tweDate }}
          </span>
        </twe-refresh-button>
      </div>
    </div>
    <div class="tds-list-group c11n-space-stack-1x" data-testid="balancesStatic" *ngIf="!view.loadingFailed">
      <div
        class="tds-list-group__item"
        *ngIf="view.accountIsMargin"
        [class.tds-list-group__item--revert-border-color]="view.panelIsOpen"
      >
        {{ content.fundsAvailable.settlementFund }}
        <div class="tds-list-group__data">{{ (view.funds?.settlementFundBalance | currency) || '$-' }}</div>
      </div>

      <div
        class="tds-list-group__item"
        *ngIf="view.accountIsMargin"
        [class.tds-list-group__item--revert-border-color]="view.panelIsOpen"
      >
        {{ content.fundsAvailable.marginCashAvailable }}
        <div class="tds-list-group__data">{{ (view.funds?.marginCashAvailable | currency) || '$-' }}</div>
      </div>

      <div class="tds-list-group__item" [class.tds-list-group__item--revert-border-color]="view.panelIsOpen">
        {{ content.fundsAvailable.fundsAvailableToTrade }}
        <div class="tds-list-group__data">
          {{
            ((view.funds?.hasRestriction ? view.funds?.adjustedFundsAvailable90day : view.funds?.fundsAvailableToTrade)
              | currency) || '$-'
          }}
        </div>
      </div>

      <div
        class="tds-list-group__item bottom-border-black"
        *ngIf="view.accountIsMargin"
        [class.tds-list-group__item--revert-border-color]="view.panelIsOpen"
      >
        <div>
          {{ content.fundsAvailable.marginBuyingPower }}<br />
          <span class="c11n-text-sm">{{ content.fundsAvailable.marginBuyingPowerSub }}</span>
        </div>
        <div class="tds-list-group__data">{{ (view.funds?.buyingPowerValue | currency) || '$-' }}</div>
      </div>
    </div>

    <c11n-details
      class="balances-details"
      data-testid="balancesDetails"
      *ngIf="view.showShowDetailsBtn && !view.loadingFailed"
      [collapseLabelText]="content.fundsAvailable.hideDetails"
      [expandLabelText]="content.fundsAvailable.showDetails"
      [isExpanded]="initialPanelState"
      [labelAlign]="'right'"
      [labelWeight]="'normal'"
      (detailsToggle)="handlePanelToggle($event)"
    >
      <div class="tds-list-group">
        <div class="tds-list-group__item balances-details-row" *ngIf="!view.accountIsMargin">
          {{ content.fundsAvailable.settlementFund }}
          <div class="tds-list-group__data">{{ (view.funds?.settlementFundBalance | currency) || '$-' }}</div>
        </div>

        <div
          class="tds-list-group__item balances-details-row"
          *ngIf="view.funds?.totalCreditsAndDebits !== null && view.funds?.totalCreditsAndDebits !== 0"
        >
          {{ content.fundsAvailable.totalCreditsAndDebits }}
          <div class="tds-list-group__data">{{ view.funds?.totalCreditsAndDebits | currency }}</div>
        </div>

        <div class="tds-list-group__item balances-details-row" *ngIf="view.accountIsPatternDayTrader">
          {{ content.fundsAvailable.nyseSurplus }}
          <div class="tds-list-group__data">{{ view.funds?.exchangeSurplus | currency }}</div>
        </div>

        <div
          class="tds-list-group__item balances-details-row"
          *ngIf="view.funds?.openBuyMarketableOrderValue !== null && view.funds?.openBuyMarketableOrderValue !== 0"
        >
          {{ content.fundsAvailable.openBuyMarketableOrder }}
          <div class="tds-list-group__data" data-testid="txt-open-market-order-value">
            {{ view.funds?.openBuyMarketableOrderValue | currency }}
          </div>
        </div>

        <div
          *ngIf="view.funds?.openLimitOrderValue !== null && view.funds?.openLimitOrderValue !== 0"
          class="tds-list-group__item balances-details-row"
        >
          <div>
            <ng-container *ngIf="!isViewingConfirm">
              <span aria-hidden="true">{{ content.fundsAvailable.valueOf }}</span>

              <a
                c11n-link
                routerLink="/trade/orders"
                routerLinkActive="active"
                fontWeight="normal"
                [attr.aria-label]="content.fundsAvailable.valueOf + ' ' + content.fundsAvailable.openBuyLimitOrder"
              >
                {{ content.fundsAvailable.openBuyLimitOrder }}
              </a>
            </ng-container>

            <ng-container *ngIf="isViewingConfirm">
              {{ content.fundsAvailable.openLimitOrder }}
            </ng-container>
          </div>

          <div class="tds-list-group__data" data-testid="txt-open-order-value">
            {{ view.funds?.openLimitOrderValue | currency }}
          </div>
        </div>
      </div>
    </c11n-details>

    <div
      class="tds-mt-5 tds-mb-6"
      *ngIf="view.accountIsMargin && !!view.funds?.marginCall && !view.funds?.fundsAvailableToTrade"
    >
      <c11n-banner [headingText]="content?.marginCall.title" iconName="announcement">
        <div class="c11n-text-md--crop">
          <a c11n-link [attr.href]="content?.marginCall.link.url" target="_blank">{{
            content?.marginCall.link.text
          }}</a>
        </div>
      </c11n-banner>
    </div>
  </div>
</ng-container>
