import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Account, JsonContent, Order, Quote, TradeTicketForm } from '@app/etfs-equities/models';
import {
  AccountService,
  MarketDataService,
  OrderService,
  TradeTicketService,
  WindowService,
} from '@app/etfs-equities/services';
import {
  createLoadFundsAvailableAction,
  createLoadLotsAction,
  createLoadLotsErrorAction,
  createLoadQuoteAction,
  createSetHistoryUrlAction,
  createSetIsChangeOrderAction,
  createSetIsEditCostBasisAction,
  createValidateOrderSuccessAction,
  selectAccountIsMargin,
  selectHistory,
  selectOrder,
  selectOrigOrder,
  selectPositionForCurrentQuoteBySecurityType,
  selectQuote,
  selectSelectedAccount,
  selectTradeTicket,
  TayneState,
} from '@app/etfs-equities/store';
import { selectClientId } from '@app/etfs-equities/store/selectors/client-data/client-data.selectors';
import { OrderUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, take, takeUntil, withLatestFrom } from 'rxjs';

import { NoChangesErrorModalComponent } from '../trade/modals/change-order/no-changes-error-modal/no-changes-error-modal.component';
@Component({
  selector: 'twe-edit-cost-basis',
  templateUrl: './edit-cost-basis.component.html',
  styleUrls: ['./edit-cost-basis.component.scss'],
})
export class EditCostBasisComponent implements OnInit, OnDestroy {
  @ViewChild(NoChangesErrorModalComponent) noChangesErrorModal?: NoChangesErrorModalComponent;

  content: JsonContent = content;
  isChangeOrder = true;

  order$: Observable<Order.Order>;
  origOrder$: Observable<Order.Order>;
  quote$: Observable<Quote>;
  userHistoryUrl$: Observable<string>;
  selectedAccount$: Observable<Account.Account>;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly store: Store<TayneState>,
    private readonly route: ActivatedRoute,
    private readonly orderService: OrderService,
    private marketDataService: MarketDataService,
    private accountService: AccountService,
    private readonly windowService: WindowService,
    public tradeTicketService: TradeTicketService
  ) {
    store.dispatch(createSetIsChangeOrderAction(true));
  }

  get isOrderDetailsError(): boolean {
    return this.orderService.loadOrderFailed;
  }

  ngOnInit() {
    this.order$ = this.store.pipe(select(selectOrder));
    this.origOrder$ = this.store.pipe(select(selectOrigOrder));
    this.quote$ = this.store.pipe(select(selectQuote));
    this.userHistoryUrl$ = this.store.pipe(select(selectHistory));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));

    this.watchForLoadedOrder();
    this.watchForAccount();

    // store user history url
    this.store.dispatch(createSetHistoryUrlAction(this.windowService.userNavigationUrl()));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleBackClick(url = environment.secureSiteUrls.orderStatus): void {
    this.windowService.navigateToExternalLink(url);
  }

  /**
   * This method checks if changes were made for CostBasisMethod:
   * if yes: will check current CostBasisMethod and depending on it will call validation for Select Shares Page or navigate to Preview Order Page
   * else: will trigger no changes error modal
   */
  validateCostBasis() {
    combineLatest([
      this.store.pipe(select(selectOrigOrder)),
      this.selectedAccount$,
      this.store.pipe(select(selectPositionForCurrentQuoteBySecurityType)),
      this.quote$,
      this.store.pipe(select(selectAccountIsMargin)),
      this.store.pipe(select(selectClientId)),
    ])
      .pipe(take(1))
      .subscribe(([order, { accountId }, holding, { romCusip: cusip }, isMarginAccount, clientId]) => {
        if (this.tradeTicketService.specIdIsSelected()) {
          this.validateAndSendToSelectShares(order, accountId, holding);
        } else {
          if (this.tradeTicketService.isOrderEqualTradeTicketFormCostBasis(order)) {
            this.noChangesErrorModal.modal.openModalDialog();
            return;
          }

          this.store.dispatch(
            createValidateOrderSuccessAction({
              ...order,
              validated: true,
              cusip,
              costBasisMethod: this.tradeTicketService.tradeTicket.controls.costBasisMethod.value,
              isMarginAccount,
              clientId,
            })
          );
        }
      });
  }

  /**
   * This method checks is holding position id provided and depending on it will show Error Lots modal or call LoadLots and navigate to Select Shares Page
   *
   * @param order Origin Order that comes from order details call
   * @param accountId Account Id
   * @param holding Holding for current Security Type
   */
  validateAndSendToSelectShares(order: Order.Order, accountId: number, holding: Account.Holding): void {
    if (!holding?.positionId) {
      this.store.dispatch(createLoadLotsErrorAction(new HttpErrorResponse({})));
      return;
    }

    this.store.dispatch(createLoadLotsAction(accountId, holding.positionId, order.orderId, order.transactionType));
  }

  private updateTradeTicketStoreState(order: Order.Order) {
    const orderForm = OrderUtil.makeChangeOrderForm({
      ...order,
    });

    this.tradeTicketService.tradeTicket.patchValue(
      {
        ...orderForm,
      },
      { emitEvent: false }
    );

    // this is going to dispatch the createUpdateTradeTicketAction and update the store
    this.tradeTicketService.tradeTicket.updateValueAndValidity();
  }

  private watchForLoadedOrder() {
    this.origOrder$
      .pipe(withLatestFrom(this.store.pipe(select(selectTradeTicket))), takeUntil(this.unsubscribe$))
      .subscribe(([order, tradeTicket]: [Order.Order, TradeTicketForm]) => {
        if (order && order.orderId !== tradeTicket?.orderId) {
          this.store.dispatch(createLoadQuoteAction(order.ticker));
          this.tradeTicketService.setCostBasisMethod(tradeTicket?.costBasisMethod || order.costBasisMethod);
          this.updateTradeTicketStoreState(order);
          this.store.dispatch(createSetIsEditCostBasisAction(true));
        }
      });
  }

  private watchForAccount() {
    this.selectedAccount$.pipe(takeUntil(this.unsubscribe$)).subscribe((account: Account.Account) => {
      if (account?.accountId) {
        this.store.dispatch(createLoadFundsAvailableAction(account.accountId));
      }
    });
  }
}
