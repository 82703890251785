import { Component } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';

@Component({
  selector: 'twe-extended-trading-dislocure',
  templateUrl: './extended-trading-dislocure.component.html',
  styleUrls: ['./extended-trading-dislocure.component.scss'],
})
export class ExtendedTradingDislocureComponent {
  content: JsonContent = content;
}
