import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CashMarginIndicatorTypes } from '@app/etfs-equities/enums';
import { Account, JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';

@Component({
  selector: 'twe-selected-account',
  templateUrl: './selected-account.component.html',
  styleUrls: ['./selected-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedAccountComponent {
  @Input()
  account: Account.Account;

  content: JsonContent = content;

  marginIndicator = CashMarginIndicatorTypes.MARGIN;
}
