import { Action } from '@ngrx/store';

import { EnvironmentState } from '../../states/environment.state';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum EnvironmentActionTypes {
  LOAD_ENVIRONMENT = '[Environment] Load',
  LOAD_ENVIRONMENT_SUCCESS = '[Environment] Load Success',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class LoadEnvironmentAction implements Action {
  readonly type = EnvironmentActionTypes.LOAD_ENVIRONMENT;
}

export class LoadEnvironmentSuccessAction implements Action {
  readonly type = EnvironmentActionTypes.LOAD_ENVIRONMENT_SUCCESS;
  constructor(public payload: EnvironmentState) {}
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createLoadEnvironmentAction = () => {
  return new LoadEnvironmentAction();
};

export const createLoadEnvironmentSuccessAction = (environment: EnvironmentState) => {
  return new LoadEnvironmentSuccessAction(environment);
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type EnvironmentActionTypesUnion = LoadEnvironmentAction | LoadEnvironmentSuccessAction;
