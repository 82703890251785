export interface ClientDataState {
  isIncapacitated: boolean;
  isExitBtnClicked: boolean;
  clientId: number | null;
}

export const initialClientDataState: ClientDataState = {
  isIncapacitated: false,
  isExitBtnClicked: false,
  clientId: null,
};
