import { DefaultCostBasisMethodForHolding } from '@app/etfs-equities/models/cost-basis.model';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-17';
import { cloneDeep } from 'lodash';

import {
  CostBasisActionTypes,
  CostBasisActionTypesUnion,
  SetPreselectedCostbasisMethodAction,
} from '../../actions/cost-basis/cost-basis.actions';
import { CostBasisState, initialCostBasisState } from '../../states';

const setPreselectedCostBasisMethodState = (action: SetPreselectedCostbasisMethodAction, state: CostBasisState) => {
  const accounts = cloneDeep(state.accounts);

  const selectedAccount = accounts.find((account) => account.accountId === action.payload.accountId);
  const selectedHolding = selectedAccount?.holdings?.find(
    (holding) => holding.holdingIdentifier === action.payload.holdingId
  );

  const newHolding: DefaultCostBasisMethodForHolding = {
    holdingIdentifier: action.payload.holdingId,
    costBasisMethodCode: action.payload.preSelectedMethod,
    isTradeDefaultMethodUpdateEligible: action.payload.preSelectedMethod !== CostBasisMethod.AVG_COST,
    clientSelectedDefaultMethod: action.payload.preSelectedMethod !== CostBasisMethod.FIFO,
  };

  if (selectedAccount?.holdings && !selectedHolding) {
    selectedAccount.holdings.push(newHolding);
  }

  if (selectedAccount && !selectedAccount.holdings) {
    selectedAccount.holdings = [newHolding];
  }

  if (selectedHolding && !selectedHolding.costBasisMethodCode) {
    selectedHolding.costBasisMethodCode = action.payload.preSelectedMethod;
  }

  return {
    ...state,
    accounts: accounts,
  };
};

export const costBasisReducer = (
  state: CostBasisState = initialCostBasisState,
  action: CostBasisActionTypesUnion
): CostBasisState => {
  switch (action.type) {
    case CostBasisActionTypes.LOAD_LOTS_SUCCESS:
      return {
        ...state,
        lots: action.payload,
      };

    case CostBasisActionTypes.LOAD_LOTS_ERROR:
      return {
        ...state,
        lotsLoadError: action.payload,
      };

    case CostBasisActionTypes.CLEAR_LOAD_LOTS_ERROR:
      return {
        ...state,
        lotsLoadError: null,
      };

    case CostBasisActionTypes.UPDATE_LOT_FORM:
      return {
        ...state,
        lotForm: action.payload,
      };

    case CostBasisActionTypes.LOAD_AVAILABLE_METHODS_SUCCESS:
      const newState = { ...state.tradeMethods };
      newState[`${action.payload.accountId}_${action.payload.holdingId}`] = {
        tradeMethods: action.payload.availableTradingMethods,
        preSelectedMethod: action.payload.preSelectedMethod,
      };
      return {
        ...state,
        tradeMethods: newState,
        tradeMethodsError: null,
      };

    case CostBasisActionTypes.LOAD_AVAILABLE_METHODS_ERROR:
      return {
        ...state,
        tradeMethodsError: action.payload.error,
      };

    case CostBasisActionTypes.AVAILABLE_METHODS_LOADED:
    case CostBasisActionTypes.CLEAR_LOAD_AVAILABLE_METHODS_ERROR:
      return {
        ...state,
        tradeMethodsError: null,
      };

    case CostBasisActionTypes.AUTO_SELECT_METHOD:
      return {
        ...state,
        methodAutoSelection: action.payload,
      };

    case CostBasisActionTypes.CLEAR_AVAILABLE_METHODS:
      return {
        ...state,
        tradeMethods: null,
      };

    case CostBasisActionTypes.SET_IS_EDIT_COST_BASIS:
      return { ...state, isEditCostBasis: action.payload };

    case CostBasisActionTypes.LOAD_COST_BASIS_FOR_ACCOUNT_SUCCESS:
      const response = action.payload;
      // Replace any existing entities that match the response's account ID. This will happen when we need to
      // force a reload of the data because a default cost basis method was changed after submitting an order.
      const newAccountList = state.accounts.filter((account) => response && account.accountId !== response.accountId);

      if (response) newAccountList.push(response);

      return {
        ...state,
        accounts: newAccountList,
      };

    case CostBasisActionTypes.SET_PRESELECTED_COSTBASIS_METHOD:
      return setPreselectedCostBasisMethodState(action, state);

    default:
      return state;
  }
};
