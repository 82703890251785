import { HttpErrorResponse } from '@angular/common/http';
import { Account } from '@app/etfs-equities/models';
import { Action } from '@ngrx/store';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum AccountActionTypes {
  LOAD_ACCOUNTS = '[Accounts] Load',
  LOAD_ACCOUNTS_SUCCESS = '[Accounts] Load Success',
  LOAD_ACCOUNTS_ERROR = '[Accounts] Load Error',
  SELECT_ACCOUNT = '[Accounts] Select',
  REFRESH_ACCOUNTS = '[Accounts] Refresh',
  REFRESH_ACCOUNTS_ERROR = '[Accounts] Refresh Error',
  REFRESH_SELECTED_ACCOUNT = '[Accounts] Refresh Selected Account',
  LOAD_EXTENDED_TRADE_AGREEMENT = '[Accounts] Load Extended Trade Agreement',
  LOAD_EXTENDED_TRADE_AGREEMENT_ERROR = '[Accounts] Load Extended Trade Agreement Error',
  SET_EXTENDED_TRADE_AGREEMENT = '[Accounts] Set Extended Trade Agreement',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class LoadAccountsAction implements Action {
  readonly type = AccountActionTypes.LOAD_ACCOUNTS;
}

export class LoadAccountsSuccessAction implements Action {
  readonly type = AccountActionTypes.LOAD_ACCOUNTS_SUCCESS;
  constructor(public payload: Account.MidTierResponse) {}
}

export class LoadAccountsErrorAction implements Action {
  readonly type = AccountActionTypes.LOAD_ACCOUNTS_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export class SelectAccountAction implements Action {
  readonly type = AccountActionTypes.SELECT_ACCOUNT;
  constructor(public payload: string) {}
}

export class RefreshAccountsAction implements Action {
  readonly type = AccountActionTypes.REFRESH_ACCOUNTS;
}

export class RefreshAccountsErrorAction implements Action {
  readonly type = AccountActionTypes.REFRESH_ACCOUNTS_ERROR;
}

export class RefreshSelectedAccountAction implements Action {
  readonly type = AccountActionTypes.REFRESH_SELECTED_ACCOUNT;
  constructor(public payload: Account.MidTierResponse) {}
}

export class LoadExtendedTradeAgreementAction implements Action {
  readonly type = AccountActionTypes.LOAD_EXTENDED_TRADE_AGREEMENT;
}

export class LoadExtendedTradeAgreementErrorAction implements Action {
  readonly type = AccountActionTypes.LOAD_EXTENDED_TRADE_AGREEMENT_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export class SetExtendedTradeAgreementAction implements Action {
  readonly type = AccountActionTypes.SET_EXTENDED_TRADE_AGREEMENT;
  constructor(public payload: boolean) {}
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createLoadAccountsAction = () => {
  return new LoadAccountsAction();
};

export const createLoadAccountsSuccessAction = (accounts: Account.MidTierResponse) => {
  return new LoadAccountsSuccessAction(accounts);
};

export const createLoadAccountsErrorAction = (error: HttpErrorResponse) => {
  return new LoadAccountsErrorAction(error);
};

export const createSelectAccountAction = (brokerageAccountNumber: string) => {
  return new SelectAccountAction(brokerageAccountNumber);
};

export const createRefreshAccountsAction = () => {
  return new RefreshAccountsAction();
};

export const createRefreshAccountsErrorAction = () => {
  return new RefreshAccountsErrorAction();
};

export const createRefreshSelectedAccountAction = (accounts: Account.MidTierResponse) => {
  return new RefreshSelectedAccountAction(accounts);
};

export const createLoadExtendedTradeAgreementAction = () => {
  return new LoadExtendedTradeAgreementAction();
};

export const createLoadExtendedTradeAgreementErrorAction = (error: HttpErrorResponse) => {
  return new LoadExtendedTradeAgreementErrorAction(error);
};

export const createSetExtendedTradeAgreementAction = (extendedTradeAgreement: boolean | null) => {
  return new SetExtendedTradeAgreementAction(extendedTradeAgreement);
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type AccountActionTypesUnion =
  | LoadAccountsAction
  | LoadAccountsSuccessAction
  | LoadAccountsErrorAction
  | SelectAccountAction
  | RefreshAccountsAction
  | RefreshAccountsErrorAction
  | RefreshSelectedAccountAction
  | LoadExtendedTradeAgreementAction
  | LoadExtendedTradeAgreementErrorAction
  | SetExtendedTradeAgreementAction;
