import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { Order } from '@app/etfs-equities/models';
import { selectOrder, TayneState } from '@app/etfs-equities/store';
import { OrderUtil } from '@app/etfs-equities/utils';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export const previewGuard: CanActivateFn = (): Observable<boolean> => {
  const store = inject(Store<TayneState>);
  const router = inject(Router);
  return store.pipe(
    take(1),
    select(selectOrder),
    map((order: Order.Order) => {
      if (!OrderUtil.readyForPreview(order)) {
        router.navigateByUrl(CONSTANTS.TRADE_PATH);
        return false;
      }

      return true;
    })
  );
};
