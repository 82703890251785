import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SymbolSearchControlComponent } from '@app/etfs-equities/components/symbol-search-control/symbol-search-control.component';
import { JsonContent } from '@app/etfs-equities/models';
import { KeywordSearchService } from '@app/etfs-equities/services';
import {
  selectBuyOrderRestriction,
  selectDisplayQuoteBanner,
  selectQuoteIsHalted,
  selectQuoteIsIpo,
  selectShowLowLiquidityHint,
  selectShowNoBuyOrdersBannerForLowTierOtc,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-trade-symbol-keyword',
  templateUrl: './trade-symbol-keyword.component.html',
  styleUrls: ['./trade-symbol-keyword.component.scss'],
})
export class TradeSymbolKeywordComponent implements OnInit {
  @ViewChild(SymbolSearchControlComponent) symbolControl?: SymbolSearchControlComponent;

  // Observable variables...
  displayQuoteBanner$: Observable<boolean>;
  quoteIsHalted$: Observable<boolean>;
  buyOrderRestriction$: Observable<boolean>;
  showNoBuyOrdersBannerForLowTierOtc$: Observable<boolean>;
  showLowLiquidityHint$: Observable<boolean>;
  quoteIsIpo$: Observable<boolean>;

  content: JsonContent = content;

  @Input() parentGroup?: FormGroup;
  @Input() isChangeOrder?: false;

  constructor(public keywordSearchService: KeywordSearchService, private store: Store) {}

  ngOnInit(): void {
    this.displayQuoteBanner$ = this.store.pipe(select(selectDisplayQuoteBanner));
    this.quoteIsHalted$ = this.store.pipe(select(selectQuoteIsHalted));
    this.buyOrderRestriction$ = this.store.pipe(select(selectBuyOrderRestriction));
    this.showNoBuyOrdersBannerForLowTierOtc$ = this.store.pipe(select(selectShowNoBuyOrdersBannerForLowTierOtc));
    this.showLowLiquidityHint$ = this.store.pipe(select(selectShowLowLiquidityHint));
    this.quoteIsIpo$ = this.store.pipe(select(selectQuoteIsIpo));
  }

  focusSymbolControl = () => {
    this.symbolControl.focusInput();
  };
}
