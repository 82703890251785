import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { OrderEnums } from '@app/etfs-equities/enums';
import { AccountService } from '@app/etfs-equities/services';
import {
  securityAccountType,
  selectAction,
  selectIsCashAndMarginOrShortHoldings,
  selectQuote,
  selectSelectedAccount,
  selectSharesHeld,
  selectSharesHeldForCash,
  selectSharesHeldForMargin,
} from '@app/etfs-equities/store';
import { TayneState } from '@app/etfs-equities/store/states/tayne.state';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HoldLimitSharesValidator implements AsyncValidator {
  constructor(private readonly store: Store<TayneState>, private readonly accountService: AccountService) {}

  validate(control: AbstractControl): Observable<ValidationErrors> {
    return combineLatest([
      this.store.pipe(select(selectIsCashAndMarginOrShortHoldings)),
      this.store.pipe(select(selectSharesHeld)),
      this.store.pipe(select(selectSharesHeldForCash)),
      this.store.pipe(select(selectSharesHeldForMargin)),
      this.store.pipe(select(securityAccountType)),
      this.store.pipe(select(selectAction)),
      this.store.pipe(select(selectQuote)),
      this.store.pipe(select(selectSelectedAccount)),
    ]).pipe(
      map(
        ([
          hasCashAndMarginOrShort,
          sharesHeld,
          cashSharesHeld,
          sharesMargin,
          securityAccountTypeValue,
          action,
          quote,
          selectedAccount,
        ]) => {
          const enteredAmount = Number(control.value);

          const insufficientHolds =
            hasCashAndMarginOrShort && securityAccountTypeValue === null
              ? enteredAmount > cashSharesHeld && enteredAmount > sharesMargin
              : enteredAmount > sharesHeld;

          // If hasCriticalError is true the holding shares limit validator should be ignored
          return !this.accountService.hasCriticalHoldingError &&
            selectedAccount?.holdings?.length >= 0 &&
            insufficientHolds &&
            (action === OrderEnums.TransactionTypes.SELL || action === OrderEnums.TransactionTypes.BUY_TO_COVER) &&
            quote
            ? { holdSharesLimit: true }
            : null;
        }
      ),
      take(1)
    );
  }
}
