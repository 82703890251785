import { Injectable } from '@angular/core';
import { parseAxiosToHttpError } from '@app/shared/utilities/error/error.util';
import { EcnHours } from '@etfs-equities/models/exchange.model';
import { ExchangeService } from '@etfs-equities/services/exchange/exchange.service';
import { ExtendedHoursService } from '@etfs-equities/services/extended-hours/extended-hours.service';
import {
  createAcceptEcnAgreementFailed,
  createAcceptEcnAgreementSuccess,
  createLoadEcnHoursFailure,
  createLoadEcnHoursSuccess,
  createSetExtendedTradeAgreementAction,
  ExtendedHoursActionTypes,
} from '@etfs-equities/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SetExtendedHoursAgreementRequestEcnStatusEnum } from '@vanguard/invest-api-client-typescript-axios';
import { AxiosError } from 'axios';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class ExtendedHoursEffects {
  loadECNHours$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtendedHoursActionTypes.LOAD_ECNHOURS),
      switchMap(() =>
        this.exchangeService.getEcnHours().pipe(
          map((ecnHours: EcnHours) => createLoadEcnHoursSuccess(ecnHours)),
          catchError((error: Error) => of(createLoadEcnHoursFailure(error)))
        )
      )
    )
  );

  acceptECNAgreement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtendedHoursActionTypes.ACCEPT_ECN_AGREEMENT),
      switchMap(() =>
        this.extendedHoursService.acceptExtendedHoursAgreement().pipe(
          map(() => createAcceptEcnAgreementSuccess(SetExtendedHoursAgreementRequestEcnStatusEnum.ACPT)),
          catchError((error: AxiosError) => {
            return of(createAcceptEcnAgreementFailed(parseAxiosToHttpError(error)));
          })
        )
      )
    )
  );

  acceptECNAgreementSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtendedHoursActionTypes.ACCEPT_ECN_AGREEMENT_SUCCESS),
      map(() => createSetExtendedTradeAgreementAction(true))
    )
  );

  constructor(
    private actions$: Actions,
    private readonly exchangeService: ExchangeService,
    private readonly extendedHoursService: ExtendedHoursService
  ) {}
}
