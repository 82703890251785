import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Account } from '@app/etfs-equities/models';
import { FundsAvailableService } from '@app/etfs-equities/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import {
  createLoadFundsAvailableAction,
  createLoadFundsAvailableErrorAction,
  createLoadFundsAvailableSuccessAction,
  FundsAvailableActionTypes,
  LoadFundsAvailableAction,
} from '../../actions';
import { selectSelectedAccount } from '../../selectors';
import { TayneState } from '../../states';

@Injectable()
export class FundsAvailableEffects {
  loadFundsAvailable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FundsAvailableActionTypes.LOAD_FUNDS_AVAILABLE),
      switchMap((action: LoadFundsAvailableAction) =>
        this.fundsAvailableService.fetchFundsAvailable(action.payload).pipe(
          map((fundsAvailable) => createLoadFundsAvailableSuccessAction(fundsAvailable)),
          catchError((error: HttpErrorResponse) => of(createLoadFundsAvailableErrorAction(error)))
        )
      )
    )
  );

  refreshFundsAvailable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FundsAvailableActionTypes.REFRESH_FUNDS_AVAILABLE),
      withLatestFrom(this.store.pipe(select(selectSelectedAccount))),
      switchMap(([_action, account]: [LoadFundsAvailableAction, Account.Account]) =>
        of(createLoadFundsAvailableAction(account.accountId))
      )
    )
  );

  constructor(
    private readonly fundsAvailableService: FundsAvailableService,
    private readonly store: Store<TayneState>,
    private readonly actions$: Actions
  ) {}
}
