import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { CONSTANTS } from '@app/etfs-equities/constants';

export const openOrdersHoldingsGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean => {
  const router = inject(Router);
  if (route.queryParams && route.queryParams.accountId && route.queryParams.orderId) {
    router.navigateByUrl(
      `${CONSTANTS.TRADE_PATH}?accountId=${route.queryParams.accountId}&orderId=${route.queryParams.orderId}`
    );
    return false;
  }
  return true;
};
