import { OrderEnums, SecurityAccountTypes } from '@app/etfs-equities/enums';
import { InstrumentClassExtendedEnum, TradingRestrictionEnum } from '@app/etfs-equities/enums/quote.enum';
import { Account, Quote, TradeTicketForm } from '@app/etfs-equities/models';
import { Holding } from '@app/etfs-equities/models/account.model';
import {
  calculateDollarValueOfSharesHeld,
  getSharesQuantity,
  OrderUtil,
  PrincipalUtil,
} from '@app/etfs-equities/utils';
import { SharesUtil } from '@app/etfs-equities/utils/shares/shares.util';
import { createSelector } from '@ngrx/store';
import { InstrumentClassSetInstrumentClassesEnum } from '@vanguard/invest-api-client-typescript-axios';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-17';
import { floor } from 'lodash';

import { TayneState, TradeTicketState } from '../../states';
import { selectAccountIsMargin } from '../account/account.selectors';
import {
  selectCashAccountHoldingsForCurrentQuote,
  selectMarginAccountHoldingsForCurrentQuote,
  selectQuote,
  selectQuoteIsHalted,
  selectQuoteIsIpo,
  selectShortAccountHoldingsForCurrentQuote,
} from '../quote/quote.selectors';
import { selectTayneState } from '../tayne/tayne.selectors';

export const selectTradeTicketState = createSelector(selectTayneState, (state: TayneState) => state.tradeTicket);

export const selectTradeTicket = createSelector(selectTradeTicketState, (state: TradeTicketState) => state.tradeTicket);

export const selectPrincipal = createSelector(
  selectTradeTicket,
  selectQuote,
  (tradeTicket: TradeTicketForm, quote: Quote) => {
    const order = OrderUtil.makeFromTradeTicket(tradeTicket);
    return PrincipalUtil.calculatePrincipal(order, quote);
  }
);

export const selectEstimatedShares = createSelector(
  selectTradeTicket,
  selectQuote,
  (tradeTicket: TradeTicketForm, quote: Quote) => {
    const order = OrderUtil.makeFromTradeTicket(tradeTicket);
    return SharesUtil.calculateShares(order, quote);
  }
);

export const selectTradeTicketTabLink = createSelector(
  selectTradeTicketState,
  (state: TradeTicketState) => state.tabLink
);

export const selectExtendedTradingTabLink = createSelector(
  selectTradeTicketState,
  (state: TradeTicketState) => state.extendedTradingTabLink
);

export const selectAmount = createSelector(selectTradeTicketState, (state: TradeTicketState): number =>
  Number(state.tradeTicket?.amount)
);

export const selectAmountType = createSelector(
  selectTradeTicketState,
  (state: TradeTicketState): string | null => state.tradeTicket?.amountType || null
);

export const selectAction = createSelector(
  selectTradeTicketState,
  (state: TradeTicketState): OrderEnums.TransactionTypes | null => state.tradeTicket?.action || null
);

export const selectOrderType = createSelector(
  selectTradeTicketState,
  (state: TradeTicketState): OrderEnums.Types | null => state.tradeTicket?.orderType || null
);

export const selectActionIsBuy = createSelector(
  selectAction,
  (action: OrderEnums.TransactionTypes): boolean => action === OrderEnums.TransactionTypes.BUY
);

export const selectActionIsSell = createSelector(
  selectAction,
  (action: OrderEnums.TransactionTypes): boolean => action === OrderEnums.TransactionTypes.SELL
);

export const selectActionIsBuyToCoverOrSellShort = createSelector(
  selectAction,
  (action: OrderEnums.TransactionTypes): boolean =>
    action === OrderEnums.TransactionTypes.BUY_TO_COVER || action === OrderEnums.TransactionTypes.SELL_SHORT
);

export const selectActionIsBuyOrSellShort = createSelector(
  selectAction,
  (action: OrderEnums.TransactionTypes): boolean =>
    action === OrderEnums.TransactionTypes.BUY || action === OrderEnums.TransactionTypes.SELL_SHORT
);

export const selectActionIsSellOrBuyToCover = createSelector(
  selectAction,
  (action: OrderEnums.TransactionTypes): boolean =>
    action === OrderEnums.TransactionTypes.SELL || action === OrderEnums.TransactionTypes.BUY_TO_COVER
);

export const securityAccountType = createSelector(
  selectTradeTicketState,
  (state: TradeTicketState): SecurityAccountTypes | null => state.tradeTicket?.securityAccountType || null
);

export const selectSharesHeld = createSelector(
  selectCashAccountHoldingsForCurrentQuote,
  selectShortAccountHoldingsForCurrentQuote,
  selectMarginAccountHoldingsForCurrentQuote,
  securityAccountType,
  selectAccountIsMargin,
  (
    cashHoldings: Account.Holding[],
    shortHoldings: Account.Holding[],
    marginHoldings: Account.Holding[],
    securityAccountTypeVal: SecurityAccountTypes,
    accountIsMargin: boolean
  ): number | undefined => {
    switch (securityAccountTypeVal) {
      case SecurityAccountTypes.SHORT:
        return getSharesQuantity(shortHoldings);
      case SecurityAccountTypes.MARGIN:
        return getSharesQuantity(marginHoldings);
      default:
        if (securityAccountTypeVal === null && accountIsMargin) {
          return undefined;
        }
        return getSharesQuantity(cashHoldings);
    }
  }
);

export const selectDollarValueOfSharesHeld = createSelector(
  selectSharesHeld,
  selectQuote,
  (sharesHeld: number, quote: Quote): number => {
    return calculateDollarValueOfSharesHeld(sharesHeld, quote);
  }
);

export const selectIsSellingAllHeldShares = createSelector(
  selectAmount,
  selectSharesHeld,
  selectAmountType,
  selectAction,
  (
    amount: number,
    sharesHeld: number,
    amountType: OrderEnums.AmountTypes,
    action: OrderEnums.TransactionTypes
  ): boolean =>
    amountType !== OrderEnums.AmountTypes.DOLLARS &&
    action === OrderEnums.TransactionTypes.SELL &&
    sharesHeld !== undefined &&
    sharesHeld > 0 &&
    amount <= sharesHeld &&
    floor(amount) === floor(sharesHeld)
);

export const selectPositionForCurrentQuoteBySecurityType = createSelector(
  securityAccountType,
  selectCashAccountHoldingsForCurrentQuote,
  selectMarginAccountHoldingsForCurrentQuote,
  selectShortAccountHoldingsForCurrentQuote,
  (securityAccount, cashHoldings, marginHoldings, shortHoldings): Holding | null => {
    let position;

    switch (securityAccount) {
      case SecurityAccountTypes.MARGIN: {
        position = marginHoldings[0];
        break;
      }
      case SecurityAccountTypes.SHORT: {
        position = shortHoldings[0];
        break;
      }
      default: {
        position = cashHoldings[0];
      }
    }

    return position || null;
  }
);

export const selectUpdateDefaultCostBasis = createSelector(
  selectTradeTicketState,
  (state: TradeTicketState): boolean => state.tradeTicket?.updateDefaultCostBasis || false
);

export const selectTradeTicketCostBasisMethod = createSelector(
  selectTradeTicketState,
  (state: TradeTicketState): CostBasisMethod | null => state.tradeTicket?.costBasisMethod || null
);

export const selectTradeTicketSellAll = createSelector(
  selectTradeTicketState,
  (state: TradeTicketState): boolean => state.tradeTicket?.sellAll || false
);

export const selectBuyOrderRestriction = createSelector(
  selectQuote,
  selectActionIsSellOrBuyToCover,
  (quote: Quote, sellOrBuyToCover: boolean) =>
    !!quote &&
    !sellOrBuyToCover &&
    (quote.tradingRestriction === TradingRestrictionEnum.NO_BUY_ORDERS ||
      !!quote.instrumentClasses.find((instrumentClass) =>
        [
          InstrumentClassExtendedEnum.LEVINV_RESET_ETF_OVERRIDE,
          InstrumentClassSetInstrumentClassesEnum.ETNS,
          InstrumentClassSetInstrumentClassesEnum.EXTRA_TYPE7,
          InstrumentClassSetInstrumentClassesEnum.EXTRA_TYPE9,
        ].includes(instrumentClass)
      ))
);

export const selectDisplayQuoteBanner = createSelector(
  selectQuoteIsIpo,
  selectBuyOrderRestriction,
  selectQuoteIsHalted,
  (isQuoteIpo, buyOrderRestriction, quoteIsHalted) => {
    return isQuoteIpo || buyOrderRestriction || quoteIsHalted;
  }
);

export const selectOrderDuration = createSelector(
  selectTradeTicketState,
  (state: TradeTicketState): OrderEnums.Durations | null => state.tradeTicket?.duration || null
);

export const selectOrderEveningDuration = createSelector(
  selectOrderDuration,
  (duration: OrderEnums.Durations): boolean => duration === OrderEnums.Durations.EVENING
);
