import { Injectable } from '@angular/core';
import { KeywordSearchService } from '@app/etfs-equities/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { KeywordSearchActionTypes } from '../../actions';

@Injectable()
export class KeywordSearchEffects {
  clearKeywordSearch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KeywordSearchActionTypes.CLEAR_KEYWORD_SEARCH),
        tap(() => this.keywordSearchService.clearKeywordSearchResults())
      ),
    { dispatch: false }
  );

  constructor(private readonly keywordSearchService: KeywordSearchService, private readonly actions$: Actions) {}
}
