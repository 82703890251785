<ng-container
  *ngIf="{
    isIncapacitated: (isIncapacitated$ | async)
  } as view"
>
  <label c11n-label size="medium">{{ content?.labels?.amountType }}</label>
  <c11n-segmented-control appSegmentControlReset color="yellow" size="small" [legendText]="content?.labels?.amountType">
    <c11n-segment [labelText]="content?.amount?.shares">
      <input
        c11n-segment-input
        id="amountTypeShares"
        #amountTypeShares
        type="radio"
        [formControl]="tradeTicketService?.tradeTicket?.controls?.amountType"
        name="amountTypeAction"
        [value]="amountTypes.SHARES"
        [attr.disabled]="view.isIncapacitated ? true : null"
        checked
      />
    </c11n-segment>

    <c11n-segment [labelText]="content?.amount?.dollars">
      <input
        c11n-segment-input
        id="amountTypeDollars"
        #amountTypeDollars
        type="radio"
        [formControl]="tradeTicketService?.tradeTicket?.controls?.amountType"
        name="amountTypeAction"
        [value]="amountTypes.DOLLARS"
        [attr.disabled]="view.isIncapacitated ? true : null"
      />
    </c11n-segment>
  </c11n-segmented-control>
</ng-container>
