import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaData, UIMetaData } from '@app/core/models/meta-data/meta-data';
import { LoggerService, supportedFeatureIds } from '@app/core/services';
import { EnvironmentService } from '@app/shared/services/environment/environment.service';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { SessionCheckService } from '../session-check/session-check.service';

@Injectable({
  providedIn: 'root',
})
export class MetaDataService {
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
    private readonly loggerService: LoggerService,
    private readonly sessionCheckService: SessionCheckService
  ) {}

  getMetaData(token?: string): Observable<UIMetaData> {
    const metadataPath = `api/meta-data` + (token ? `?token=${token}` : '');
    return this.http
      .get<MetaData>(this.envService.getApiUrlBaseOnRoute() + metadataPath, {
        params: {
          featureIds: supportedFeatureIds,
        },
        withCredentials: true,
      })
      .pipe(
        map(this.mapApiResponse),
        tap(() => (this.sessionCheckService.sessionFailed = false)),
        catchError((error: HttpErrorResponse) => {
          this.sessionCheckService.sessionFailed = Boolean(error?.status === HttpStatusCode.Unauthorized);

          this.loggerService.error('ui-meta-data-service', `Error fetching meta data from API: ${error.message}`);
          return of(null);
        })
      );
  }

  private mapApiResponse(res: MetaData): UIMetaData {
    return {
      metaTags: {
        isBeacon: res.isBeacon,
        isIncapacitated: res.isIncapacitated,
        revision: res.commitRevision,
      },
      multiToggleResponse: res.multiToggleResponse,
    };
  }
}
