<ng-container
  *ngIf="{
    extendedHoursAgreement: (extendedHoursAgreement$ | async),
    selectShowNoBuyOrdersBannerForLowTierOtc: (selectShowNoBuyOrdersBannerForLowTierOtc$ | async),
    accountIsMargin: (accountIsMargin$ | async),
    quote: (quote$ | async),
    selectedAction: (selectedAction$ | async),
    isScreenXSmall: (isScreenXSmall$ | async)
  } as view"
>
  <ng-container *ngIf="!extendedHoursService.isLoading">
    <div class="disclosure-component-wrapper" *ngIf="!view.extendedHoursAgreement; else extTradingTemplate">
      <twe-extended-trading-dislocure></twe-extended-trading-dislocure>
    </div>

    <ng-template #extTradingTemplate>
      <div class="container-fluid">
        <div class="row">
          <twe-accounts-control class="col-12" [showAccountDetails]="true"></twe-accounts-control>

          <twe-extended-hours-banner class="c11n-space-stack-3x"></twe-extended-hours-banner>

          <div *ngIf="minutesRemaining">
            <c11n-banner size="small" variant="warning" class="c11n-space-stack-3x c11n-space--force">
              <div class="c11n-text-md--crop">
                {{ content?.extendedHoursWarning?.body1 }} {{ minutesRemaining }}
                {{ content?.extendedHoursWarning?.body2 }}
              </div>
            </c11n-banner>
          </div>

          <twe-accounts-error #accountErrorComponent></twe-accounts-error>
        </div>
        <ng-container *ngIf="!accountErrorComponent?.hasAccountRetrievalError">
          <div class="row">
            <div class="col-lg-6 margin-sm c11n-space-stack-3x">
              <div class="c11n-space-stack-3x">
                <twe-trade-symbol-keyword [parentGroup]="formGroupName"></twe-trade-symbol-keyword>
              </div>
              <div class="d-lg-none c11n-space-stack-3x">
                <twe-quote [isAfterMarket]="true"></twe-quote>
              </div>
              <twe-transaction-type-control></twe-transaction-type-control>
              <div class="c11n-space-stack-3x" *ngIf="!view.selectShowNoBuyOrdersBannerForLowTierOtc">
                <twe-security-account-type-control
                  [hidden]="!(view.accountIsMargin && view.quote && view.selectedAction)"
                ></twe-security-account-type-control>
              </div>
              <div class="c11n-space-stack-3x">
                <twe-shares-control
                  [parentGroup]="formGroupName"
                  [isChangeOrder]="isChangeOrder"
                  [isExtendedTrading]="true"
                ></twe-shares-control>
              </div>
              <div class="c11n-space-stack-3x" *ngIf="tradeTicketService?.orderTypeIsVisible()">
                <twe-order-type-limit-only></twe-order-type-limit-only>
              </div>
              <div class="c11n-space-stack-3x twe-mt-n075" *ngIf="tradeTicketService?.orderTypeIsVisible()">
                <twe-duration-control [inEveningOrderFlow]="eveningDurationControlFlow"> </twe-duration-control>
              </div>
              <div class="c11n-space-stack-1x" *ngIf="tradeTicketService?.costBasisIsVisible">
                <twe-cost-basis-control [isChangeOrder]="isChangeOrder"></twe-cost-basis-control>
              </div>
              <div class="c11n-space-stack-3x">
                <twe-order-summary></twe-order-summary>
              </div>

              <twe-order-validation-banner></twe-order-validation-banner>
              <twe-margin-requirements-vg-etfs-banner></twe-margin-requirements-vg-etfs-banner>

              <div class="twe-flex-button-wrap justify-content-start c11n-space-stack-3x">
                <c11n-button
                  class="twe-flex-button-wrap__button"
                  [fullWidth]="view.isScreenXSmall"
                  [labelText]="
                    tradeTicketService?.specIdIsSelected() ? content.labels.selectShares : content.labels.previewOrder
                  "
                  [isWaiting]="orderService?.isValidating || costBasisService.isLoadingLots"
                  [isInvalid]="view.isIncapacitated"
                  (clickEvent)="handlePreviewBtnClick()"
                ></c11n-button>
                <c11n-button
                  [attr.data-testid]="'btn-extended-hours-clear'"
                  class="twe-flex-button-wrap__button"
                  buttonType="button"
                  size="medium"
                  variant="secondary"
                  [fullWidth]="view.isScreenXSmall"
                  [labelText]="content.labels.clearTicket"
                  (clickEvent)="resetTradeTicket()"
                ></c11n-button>
              </div>
            </div>
            <div class="col-lg-6 margin-sm c11n-space-stack-3x c11n-space--force">
              <div class="d-none d-lg-block c11n-space-stack-3x">
                <twe-quote [isAfterMarket]="true"></twe-quote>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>

  <twe-extended-hours-expired-modal></twe-extended-hours-expired-modal>
</ng-container>

<twe-triggered-rules-modal
  [acceptedRulesNextStep]="acceptedRulesNextStep.PREVIEW"
  (openOrderTypesModalEmitter)="orderTypesModal.open()"
></twe-triggered-rules-modal>

<twe-generic-error-modal></twe-generic-error-modal>

<twe-order-types-modal #orderTypesModal></twe-order-types-modal>
