import { Component, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';

@Component({
  selector: 'twe-timeout-error-modal',
  templateUrl: './timeout-error-modal.component.html',
  styleUrls: ['./timeout-error-modal.component.scss'],
})
export class TimeoutErrorModalComponent {
  //  Public variables...

  content: JsonContent = content;
  secureSiteUrls = environment.secureSiteUrls;

  // Decorators...
  @ViewChild('modal')
  modal: ModalDialogComponent;
}
