import { QuoteActionTypes, QuoteActionTypesUnion } from '../../actions';
import { initialQuoteState, QuoteState } from '../../states';

export const quoteReducer = (state: QuoteState = initialQuoteState, action: QuoteActionTypesUnion): QuoteState => {
  switch (action.type) {
    case QuoteActionTypes.LOAD_QUOTE_SUCCESS:
    case QuoteActionTypes.SET_QUOTE:
      return { ...state, quote: action.payload };

    case QuoteActionTypes.CLEAR_QUOTE:
      return { ...state, quote: null };

    default:
      return state;
  }
};
