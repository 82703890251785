<h2 class="selected-account">
  <span class="selected-account__account-title c11n-text-xs">{{ content.labels.account }}</span>
  <span
    *ngIf="account?.accountName"
    class="selected-account__account-info"
    [innerHTML]="
      (account?.accountName | tweLongNameSplit) +
      (account?.marginCode === marginIndicator ? (' (' + account.marginCode + ')' | titlecase) : '')
    "
  >
  </span>
</h2>
