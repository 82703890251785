import { ActivatedRouteSnapshot, CanActivateFn, UrlTree } from '@angular/router';
import { environment } from '@env/environment';

export const queryParamsGuard = (params: string[] = []): CanActivateFn => {
  return (route: ActivatedRouteSnapshot): boolean | UrlTree => {
    const counter = params.reduce((acc, val) => (route.queryParams[val] ? ++acc : acc), 0);

    if (counter === params.length) {
      return true;
    } else {
      window.open(environment.secureSiteUrls.orderStatus, '_self');
      return false;
    }
  };
};
