<ng-container
  *ngIf="{
    isFactset: (isFactset$ | async)
  } as view"
>
  <c11n-modal-dialog
    #modal
    [headingText]="view.isFactset ? content.factsetDisclaimerModal.title : content.refinitivDisclaimerModal.title"
  >
    <div c11n-modal-dialog-body>
      <p [innerHtml]="view.isFactset ? content.factsetDisclaimerModal.body : content.refinitivDisclaimerModal.body"></p>
    </div>
  </c11n-modal-dialog>
</ng-container>
