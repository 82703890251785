import { Component, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import { createClearLoadLotsErrorAction, TayneState } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';

@Component({
  selector: 'twe-cost-basis-lot-error-modal',
  templateUrl: './cost-basis-lot-error-modal.component.html',
  styleUrls: ['./cost-basis-lot-error-modal.component.scss'],
})
export class CostBasisLotErrorModalComponent {
  @ViewChild('costBasisLotLoadErrorModal')
  modal: ModalDialogComponent | undefined;

  content: JsonContent = content;

  constructor(private readonly store: Store<TayneState>) {}

  open() {
    this.modal.openModalDialog();
  }

  clearLotLoadError() {
    this.store.dispatch(createClearLoadLotsErrorAction(null));
  }
}
