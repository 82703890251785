import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DecimalsValidatorTypes } from '@app/etfs-equities/enums/decimals-validator-types.enum';
import { DecimalsValidatorConfig } from '@app/etfs-equities/models';

const exactGuard = (expected: number, entered: number, errorName: string): ValidationErrors | null => {
  if (entered !== expected) {
    return { [errorName]: { expected, entered } };
  }

  return null;
};

const maxGuard = (max: number, entered: number, errorName: string): ValidationErrors | null => {
  if (entered > max) {
    return { [errorName]: { max, entered } };
  }

  return null;
};

export const decimalsValidator = ({
  expectedNumberOfDecimals = 2,
  matchType = DecimalsValidatorTypes.EXACT,
  errorName = 'decimals',
}: DecimalsValidatorConfig = {}): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      !control ||
      !control.value ||
      (isNaN(control.value) && control.value !== '.') ||
      control.value.toString().indexOf('.') === -1
    ) {
      return null;
    }

    const value = control.value.toString();
    const parts = value.split('.');
    const decimals = parts[1].length;

    if (matchType === DecimalsValidatorTypes.MAX) {
      return maxGuard(expectedNumberOfDecimals, decimals, errorName);
    } else {
      return exactGuard(expectedNumberOfDecimals, decimals, errorName);
    }
  };
};
