import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { createLoadChangeOrderAction, selectOrder, selectOrderLoadError, TayneState } from '@app/etfs-equities/store';
import { OrderUtil } from '@app/etfs-equities/utils';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

export const ttsOrderChangeGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> | boolean => {
  const store = inject(Store<TayneState>);
  const router = inject(Router);

  const accountId = route.queryParams?.accountId;
  const orderId = route.queryParams?.orderId;

  if (accountId && orderId) {
    store.dispatch(createLoadChangeOrderAction(accountId, orderId));
    return combineLatest([store.select(selectOrder), store.select(selectOrderLoadError)]).pipe(
      first(([order, orderError]) => order?.orderId === orderId || orderError),
      map(([order]) => {
        if (OrderUtil.isPaperTradeOrTTSOrder(order)) {
          router.navigateByUrl(CONSTANTS.TRADE_PATH);
          return false;
        }
        return true;
      })
    );
  }
  return true;
};
