import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Window } from '@app/etfs-equities/models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class MedalliaService {
  // Public variables...
  medalliaUrl = environment.medalliaUrl;

  // Private variables...
  private readonly _$window: Window;

  constructor(@Inject(DOCUMENT) private readonly $document: Document) {
    this._$window = this.$document.defaultView;
  }

  /**
   * Update Medallia page view
   *
   * @remarks
   * call this method when route with sigle page app and need to trigger update page view for Medallia
   *
   */
  updateRoute(): void {
    if (this._$window.KAMPYLE_ONSITE_SDK && typeof this._$window.KAMPYLE_ONSITE_SDK.updatePageView === 'function') {
      this._$window.KAMPYLE_ONSITE_SDK.updatePageView();
    }
  }
}
