import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FeatureStatus } from '@app/core/models/feature-status.model';
import { GatekeeperService, supportedFeatureIds } from '@app/core/services';
import { ApiResponse } from '@app/etfs-equities/models';
import { map } from 'rxjs/operators';

export const gatekeeperResolver: ResolveFn<FeatureStatus[]> = () => {
  const gatekeeperService = inject(GatekeeperService);

  return gatekeeperService
    .checkMultiFeatureStatus(supportedFeatureIds)
    .pipe(map((response: ApiResponse<FeatureStatus[]>) => response.data));
};
