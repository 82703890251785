import { CONSTANTS } from '@app/etfs-equities/constants';
import { LoadingContextModel, LoadingStatusNames } from '@app/etfs-equities/models/loading.model';

export class HeadersUtil {
  static parseLoadingContext(loadingContextHeader?: string | number | boolean): LoadingContextModel {
    if (!loadingContextHeader) {
      return { showLoading: false };
    }
    const loadingContext = JSON.parse(String(loadingContextHeader));
    return loadingContext as LoadingContextModel;
  }

  static showLoadingHeaders(status?: LoadingStatusNames) {
    const headersObject = { showLoading: true, status };
    const headersString = JSON.stringify(headersObject);
    return { headers: { [CONSTANTS.LOADING_CONTEXT_HEADER_NAME]: headersString } };
  }
}
