import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { GatekeeperService } from '@app/core/services';
import { environment } from '@env/environment';
import { ExchangeService } from '@etfs-equities/services/exchange/exchange.service';
import { lastValueFrom, map } from 'rxjs';

import { GatekeeperFeatureIds } from '../../../core/enums/gatekeeper-features.enum';
import { CONSTANTS } from '../../constants';
import { DateAndTimeUtil } from '../../utils';

export const extendedHoursGuard: CanActivateFn = async (): Promise<boolean> => {
  const gatekeeperService = inject(GatekeeperService);
  const exchangeService = inject(ExchangeService);
  const router = inject(Router);

  const { TOGGLE_EXTENDED_TRADING, TWE_EXTENDED_TRADE_TAB_ALWAYS_ON } = GatekeeperFeatureIds;

  const isExtendedHours = await lastValueFrom(gatekeeperService.checkSingleFeatureStatus(TOGGLE_EXTENDED_TRADING));
  if (isExtendedHours) {
    let isAlwaysOn = false;

    const ecnHours = await lastValueFrom(
      exchangeService.getEcnHours().pipe(map((hours) => DateAndTimeUtil.isWithinSpecifiedHours(hours)))
    );

    if (!environment.production) {
      isAlwaysOn = await lastValueFrom(gatekeeperService.checkSingleFeatureStatus(TWE_EXTENDED_TRADE_TAB_ALWAYS_ON));
    }

    if (ecnHours || isAlwaysOn) {
      return true;
    } else {
      router.navigateByUrl(CONSTANTS.TRADE_PATH);
      return false;
    }
  }

  router.navigateByUrl(CONSTANTS.TRADE_PATH);
  return false;
};
