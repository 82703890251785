import { OrderCancelActionTypes, OrderCancelActionTypesUnion } from '../../actions';
import { initialOrderCancelResponseState, OrderCancelState } from '../../states';

export const orderCancelReducer = (
  state: OrderCancelState = initialOrderCancelResponseState,
  action: OrderCancelActionTypesUnion
): OrderCancelState => {
  const orderLoadError = false;
  switch (action.type) {
    case OrderCancelActionTypes.LOAD_CANCEL_ORDER_SUCCESS:
      return { ...state, orderCancel: action.payload, orderLoadError };
    case OrderCancelActionTypes.LOAD_CANCEL_ORDER_ERROR:
      return { ...state, orderCancel: null, orderLoadError: true };
    case OrderCancelActionTypes.VALIDATE_ORDER_CANCEL_SUCCESS:
      return { ...state, orderCancelResponse: action.payload, orderLoadError };
    case OrderCancelActionTypes.SUBMIT_ORDER_CANCEL_SUCCESS:
      return { ...state, orderCancelResponse: action.payload, orderLoadError };
    case OrderCancelActionTypes.CLEAR_CANCEL_ORDER:
      return { ...state, orderCancel: null, orderCancelResponse: null, orderLoadError };
    default:
      return state;
  }
};
