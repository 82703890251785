import { Injectable, OnDestroy } from '@angular/core';
import {
  createSubmitEditCostBasisAction,
  createSubmitOrderAction,
  selectIsEditCostBasisState,
  TayneState,
} from '@etfs-equities/store';
import { Store } from '@ngrx/store';
import { Subject, withLatestFrom } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubmitService implements OnDestroy {
  // private observables/subjects
  private readonly unsubscribe$ = new Subject<void>();

  // public observables/subjects
  public readonly submitOrder$ = new Subject<void>();

  constructor(private readonly store: Store<TayneState>) {
    this.watchForSubmitOrder();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private watchForSubmitOrder() {
    this.submitOrder$
      .pipe(
        withLatestFrom(this.store.select(selectIsEditCostBasisState)),
        tap(([, isEditCostBasis]) => {
          if (isEditCostBasis) {
            this.store.dispatch(createSubmitEditCostBasisAction());
          } else {
            this.store.dispatch(createSubmitOrderAction());
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
