export enum SymbolRetrievalType {
  MUTUAL_FUND = 'MUTUAL_FUND',
  ETF_EQUITY = 'ETF_EQUITY',
  NON_VG_MUTUAL_FUND = 'NON_VG_MUTUAL_FUND',
}

export enum TweLookupTypes {
  CUSIP = 'cusip',
  SYMBOL = 'symbol',
}
