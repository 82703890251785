<div class="label--tooltip">
  <label c11n-label [for]="for" [size]="size">
    <twe-tooltip
      *ngIf="tooltipHeader"
      [labelText]="labelText"
      [tooltipHeader]="tooltipHeader"
      [direction]="direction"
      [autoOrientation]="autoOrientation"
    >
      <ng-content></ng-content>
    </twe-tooltip>
  </label>
</div>
