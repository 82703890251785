import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { WindowService } from '@app/etfs-equities/services';
import { SessionCheckService } from '@core/services/session-check/session-check.service';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { EMPTY } from 'rxjs';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private readonly sessionCheckService: SessionCheckService,
    private readonly windowService: WindowService,
    private readonly cookieService: CookieService
  ) {}

  private readonly allowedPaths = ['/sessioncheck', '/meta-data'];

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const url = new URL(req.urlWithParams);
    const { pathname } = url;
    const testServerlessCookie = this.cookieService.get(CONSTANTS.SERVERLESS_TEST_COOKIE);

    if (
      this.sessionCheckService.isLocalhost ||
      !this.sessionCheckService.sessionFailed ||
      this.allowedPaths.filter((p) => pathname.endsWith(p)).length > 0 ||
      (testServerlessCookie && pathname.endsWith('/gatekeeper'))
    ) {
      return next.handle(req);
    } else {
      const logonUrlRedirect = environment.logonURL + '&TARGET_OVERRIDE=' + encodeURI(window.location.href);
      this.windowService.navigateToExternalLink(logonUrlRedirect);
      return EMPTY;
    }
  }
}
