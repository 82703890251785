<!-- twe-fractional-shares-modal -->
<twe-fractional-shares-info-modal #fractionalSharesInfoModal></twe-fractional-shares-info-modal>
<twe-dollars-to-shares-calculator #dollarsToSharesModal></twe-dollars-to-shares-calculator>

<ng-container
  *ngIf="{
    actionIsBuyToCoverOrSellShort: (actionIsBuyToCoverOrSellShort$ | async),
    dollarBasedTradesEnabled: (dollarBasedTradesEnabled$ | async),
    isIncapacitated: (isIncapacitated$ | async),
    quote: (quote$ | async),
    quoteIsVgEtf: (quoteIsVgEtf$ | async),
    showAmountTypeToggle: (showAmountTypeToggle$ | async)
  } as view"
  [formGroup]="parentGroup"
>
  <twe-amount-type-control
    class="d-block c11n-space-stack-3x"
    *ngIf="view.showAmountTypeToggle && !isExtendedTrading"
  ></twe-amount-type-control>

  <div class="amount-inputs">
    <twe-input-label for="amount" [labelText]="amountLabel" [tooltipHeader]="amountLabel">
      <div *ngIf="!(view.quoteIsVgEtf && view.dollarBasedTradesEnabled)" c11nTooltipContent>
        <div *ngIf="view.dollarBasedTradesEnabled">
          <p [innerHTML]="content?.guidelet.numberOfShares.sharesContentDBT"></p>
          <button
            c11n-link
            colorMode="on-dark"
            type="button"
            (click)="
              adobeService.sendAdobeTrackingOnClick('How do fractional shares work', 'link', 'AmountToolTip');
              fractionalSharesInfoModal.open($event)
            "
          >
            {{ content?.guidelet.numberOfShares.sharesLinkDBT }}
          </button>
        </div>
        <div *ngIf="!view.dollarBasedTradesEnabled">
          <p [innerHTML]="content?.guidelet.numberOfShares.sharesContent"></p>
          <button c11n-link colorMode="on-dark" type="button" (click)="fractionalSharesInfoModal.open($event)">
            {{ content?.guidelet.numberOfShares.sharesLink }}
          </button>
        </div>
      </div>
      <div
        *ngIf="view.quoteIsVgEtf && view.dollarBasedTradesEnabled && !view.actionIsBuyToCoverOrSellShort"
        c11nTooltipContent
      >
        <p [innerHTML]="content?.guidelet.amount.body"></p>
        <button
          c11n-link
          colorMode="on-dark"
          type="button"
          (click)="
            adobeService.sendAdobeTrackingOnClick('How do fractional shares work', 'link', 'AmountToolTip');
            fractionalSharesInfoModal.open($event)
          "
        >
          {{ content?.guidelet.amount.dbtLink }}
        </button>
      </div>
    </twe-input-label>

    <c11n-input [hasError]="tradeTicketService?.controlHasError('amount')">
      <input
        c11nInput
        id="amountTypeIsShares"
        *ngIf="tradeTicketService?.amountTypeIsShares()"
        inputmode="decimal"
        [formControl]="tradeTicketService?.tradeTicket.controls.amount"
        (keyup)="updateShareAmountValue($event)"
        name="amount"
        type="text"
        [placeholder]="content?.amount.enterShares"
        [attr.aria-label]="!tradeTicketService?.isSellAllChecked() ? content?.amount.enterSharesDescription : ''"
        [attr.aria-invalid]="tradeTicketService?.controlHasError('amount')"
        [attr.readonly]="tradeTicketService?.isSellAllChecked() ? true : null"
        [attr.aria-readonly]="tradeTicketService?.isSellAllChecked() ? true : false"
        [attr.disabled]="view.isIncapacitated ? true : null"
        aria-describedby="amount-error"
        (blur)="emitAmountEvent(); updateShareAmountValue($event)"
        (keyup.enter)="emitAmountEvent()"
        [imask]="inputMaskShares"
      />
      <input
        c11nInput
        id="amountTypeIsDollars"
        *ngIf="tradeTicketService?.amountTypeIsDollars()"
        inputmode="decimal"
        [formControl]="tradeTicketService?.tradeTicket.controls.amount"
        name="amount"
        type="text"
        [attr.aria-label]="
          view.quoteIsVgEtf && view.dollarBasedTradesEnabled && !tradeTicketService?.isSellAllChecked()
            ? content?.amount.enterDollarsDescription
            : ''
        "
        [attr.aria-invalid]="tradeTicketService?.controlHasError('amount')"
        [attr.disabled]="view.isIncapacitated ? true : null"
        aria-describedby="amount-error"
        [imask]="inputMaskDollars"
        [unmask]="true"
        (keyup.enter)="emitBlur($event)"
      />
    </c11n-input>

    <c11n-hint-error
      *ngIf="tradeTicketService?.controlHasError('amount')"
      [hasError]="tradeTicketService?.controlHasError('amount')"
      hintErrorId="amount-error"
      [errorText]="getAmountErrorText()"
      data-testid="txt-input-validation"
    ></c11n-hint-error>
  </div>
  <div class="c11n-text-sm dollars-to-shares-link">
    <button
      c11n-link
      type="button"
      *ngIf="view.quote && tradeTicketService?.amountTypeIsShares()"
      fontWeight="normal"
      (click)="adobeService.sendAdobeTrackingOnClick('Calculate dollars to share', 'link'); dollarsToSharesModal.open()"
      data-testid="calculateDollarsToSharesLink"
    >
      {{ content?.dollarsToSharesModalTrigger }}
    </button>
  </div>
</ng-container>
