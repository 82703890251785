import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OrderCancel } from '@app/etfs-equities/models/order-cancel';
import { OrderCancelService } from '@etfs-equities/services/order-cancel/order-cancel.service';
import {
  createLoadCancelOrderErrorAction,
  createLoadCancelOrderSuccessAction,
  createValidateOrderCancelErrorAction,
  createValidateOrderCancelSuccessAction,
  OrderCancelActionTypes,
} from '@etfs-equities/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';

import {
  createSubmitOrderCancelAction,
  createSubmitOrderCancelErrorAction,
  createSubmitOrderCancelSuccessAction,
  createUpdateTriggeredRulesAction,
  LoadCancelOrderAction,
  SubmitOrderCancelAction,
  SubmitOrderCancelSuccessAction,
  UpdateTriggeredRulesAction,
  ValidateOrderCancelSuccessAction,
} from '../../actions';
import { selectOrderCancel } from '../../selectors/order-cancel/order-cancel.selector';
import { TayneState } from '../../states';

@Injectable()
export class OrderCancelEffects {
  loadCancelOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderCancelActionTypes.LOAD_CANCEL_ORDER),
      exhaustMap((action: LoadCancelOrderAction) => {
        return this.orderCancelService.loadCancelOrder(action.accountId, action.orderId).pipe(
          map((order) => createLoadCancelOrderSuccessAction(order[0])),
          catchError((error: HttpErrorResponse) => of(createLoadCancelOrderErrorAction(error)))
        );
      })
    )
  );

  validateOrderCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderCancelActionTypes.VALIDATE_ORDER_CANCEL),
      withLatestFrom(this.store.pipe(select(selectOrderCancel))),
      exhaustMap(([_action, orderCancel]) => {
        return this.orderCancelService.validate(orderCancel.orderId).pipe(
          map((response) => createValidateOrderCancelSuccessAction(response)),
          catchError((error: HttpErrorResponse) => of(createValidateOrderCancelErrorAction(error)))
        );
      })
    )
  );

  submitOrderCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderCancelActionTypes.SUBMIT_ORDER_CANCEL),
      withLatestFrom(this.store.pipe(select(selectOrderCancel))),
      exhaustMap(([action, orderCancel]: [SubmitOrderCancelAction, OrderCancel]) => {
        return this.orderCancelService.submit(orderCancel.orderId, action.payload).pipe(
          map((response) => createSubmitOrderCancelSuccessAction(response)),
          catchError((error: HttpErrorResponse) => of(createSubmitOrderCancelErrorAction(error)))
        );
      })
    )
  );

  validateOrderCancelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderCancelActionTypes.VALIDATE_ORDER_CANCEL_SUCCESS),
      map((action: ValidateOrderCancelSuccessAction) => {
        // If there are triggered rules, inform subscribers.
        if (action.payload.triggeredTradingRules?.length > 0) {
          return createUpdateTriggeredRulesAction(action.payload);
        }
        // If there are no triggered rules, go ahead and submit the cancel order
        return createSubmitOrderCancelAction({ allAcceptedWarningRules: [] });
      })
    )
  );

  submitOrderCancelSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderCancelActionTypes.SUBMIT_ORDER_CANCEL_SUCCESS),
        tap((action: SubmitOrderCancelSuccessAction) => {
          if (action.payload.confirmationNumber) {
            this.router.navigateByUrl('trade/cancel-success');
          } else if (action.payload.triggeredTradingRules?.length > 0) {
            this.orderCancelService.hasSubmitTriggeredRules = true;
            this.store.dispatch(createUpdateTriggeredRulesAction(action.payload));
          }
        })
      ),
    { dispatch: false }
  );

  updateTriggeredRules$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderCancelActionTypes.UPDATE_TRIGGERED_RULES),
        tap((action: UpdateTriggeredRulesAction) => {
          this.orderCancelService.triggeredRules$.next(action.payload);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly orderCancelService: OrderCancelService,
    private readonly store: Store<TayneState>,
    private readonly router: Router,
    private readonly actions$: Actions
  ) {}
}
