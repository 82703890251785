import { Pipe, PipeTransform } from '@angular/core';
import { Quote } from '@app/etfs-equities/models';

@Pipe({
  name: 'tweQuoteTickerLongName',
})
export class QuoteTickerLongNamePipe implements PipeTransform {
  transform(quote: Quote): string | null {
    if (quote === null) {
      return null;
    } else if (quote.tickerLongName) {
      return `${quote.tickerLongName} (${quote.tickerSymbol})`;
    } else if (quote.fullSecurityName) {
      return `${quote.fullSecurityName} (${quote.tickerSymbol})`;
    }
  }
}
