import { HttpErrorResponse } from '@angular/common/http';
import { FundsAvailable } from '@app/etfs-equities/models';
import { Action } from '@ngrx/store';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum FundsAvailableActionTypes {
  LOAD_FUNDS_AVAILABLE = '[Funds Available] Load',
  LOAD_FUNDS_AVAILABLE_SUCCESS = '[Funds Available] Load Success',
  LOAD_FUNDS_AVAILABLE_ERROR = '[Funds Available] Load Error',
  REFRESH_FUNDS_AVAILABLE = '[Funds Available] Refresh',
  LOADING_FUNDS_AVAILABLE = '[Funds Available] Loading',
  LOADING_FUNDS_AVAILABLE_FAILED = '[Funds Available] Loading Failed',
  FUNDS_AVAILABLE_PANEL_TOGGLE = '[Funds Available] Panel Toggle',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class LoadFundsAvailableAction implements Action {
  readonly type = FundsAvailableActionTypes.LOAD_FUNDS_AVAILABLE;
  constructor(public payload: string | number) {}
}

export class LoadFundsAvailableSuccessAction implements Action {
  readonly type = FundsAvailableActionTypes.LOAD_FUNDS_AVAILABLE_SUCCESS;
  constructor(public payload: FundsAvailable) {}
}

export class LoadFundsAvailableErrorAction implements Action {
  readonly type = FundsAvailableActionTypes.LOAD_FUNDS_AVAILABLE_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export class RefreshFundsAvailableAction implements Action {
  readonly type = FundsAvailableActionTypes.REFRESH_FUNDS_AVAILABLE;
}

export class LoaderAction implements Action {
  readonly type = FundsAvailableActionTypes.LOADING_FUNDS_AVAILABLE;
  constructor(public payload: boolean) {}
}

export class LoaderFailureAction implements Action {
  readonly type = FundsAvailableActionTypes.LOADING_FUNDS_AVAILABLE_FAILED;
  constructor(public payload: boolean) {}
}

export class PanelToggleAction implements Action {
  readonly type = FundsAvailableActionTypes.FUNDS_AVAILABLE_PANEL_TOGGLE;
  constructor(public payload: boolean) {}
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createLoadFundsAvailableAction = (accountId: string | number) => {
  return new LoadFundsAvailableAction(accountId);
};

export const createLoadFundsAvailableSuccessAction = (fundsAvailable: FundsAvailable) => {
  return new LoadFundsAvailableSuccessAction(fundsAvailable);
};

export const createLoadFundsAvailableErrorAction = (error: HttpErrorResponse) => {
  return new LoadFundsAvailableErrorAction(error);
};

export const createRefreshFundsAvailableAction = () => {
  return new RefreshFundsAvailableAction();
};

export const createFundsAvailableIsLoadingAction = (isLoading: boolean) => {
  return new LoaderAction(isLoading);
};

export const createFundsAvailableLoadingFailedAction = (loadingFailed: boolean) => {
  return new LoaderFailureAction(loadingFailed);
};

export const createFundsAvailablePanelToggleAction = (panelIsOpen: boolean) => {
  return new PanelToggleAction(panelIsOpen);
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type FundsAvailableActionTypesUnion =
  | LoadFundsAvailableAction
  | LoadFundsAvailableSuccessAction
  | LoadFundsAvailableErrorAction
  | RefreshFundsAvailableAction
  | LoaderAction
  | LoaderFailureAction
  | PanelToggleAction;
