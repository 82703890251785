import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@app/core/services';
import { BrokerageExchangeAgreementService } from '@app/core/services/brokerage-exchange-agreement/brokerage-exchange-agreement.service';
import { EnvironmentService } from '@app/shared/services/environment/environment.service';
import { BrokerageExchangeAgreementResponseExchangeAgreementStatusEnum } from '@vanguard/invest-api-client-typescript-axios';
import { lastValueFrom } from 'rxjs';

export const brokerageExchangeAgreementGuard: CanActivateFn = async (): Promise<boolean> => {
  const gatekeeperService = inject(GatekeeperService);
  const bxaService = inject(BrokerageExchangeAgreementService);
  const environmentService = inject(EnvironmentService);

  if (environmentService.isLocalEnvironment()) {
    return true;
  }

  const isServerless = await lastValueFrom(
    gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_SERVERLESS)
  );

  if (isServerless) {
    const bxaResponse = await lastValueFrom(bxaService.fetchBrokerageExchangeAgreement());
    if (
      bxaResponse.data &&
      bxaResponse.data.exchangeAgreementStatus !== BrokerageExchangeAgreementResponseExchangeAgreementStatusEnum.ACPT
    ) {
      window.open(bxaResponse.data.redirectUrl, '_self');
      return false;
    }
  }

  return true;
};
