import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Quote } from '@app/etfs-equities/models';
import { selectQuote, TayneState } from '@app/etfs-equities/store';
import { Store } from '@ngrx/store';
import { map, Observable, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExtendedHoursValidators implements AsyncValidator {
  constructor(private store: Store<TayneState>) {}

  validate(_control: AbstractControl): Observable<ValidationErrors> {
    return this.store.select(selectQuote).pipe(
      take(1),
      map((quote: Quote) => {
        return !quote || quote?.eveningTradingEnabled === 'YES' ? null : { eveningTradingDisabled: true };
      })
    );
  }
}
