import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';

@Component({
  selector: 'twe-mixed-method-warning-modal',
  templateUrl: './mixed-method-warning-modal.component.html',
  styleUrls: ['./mixed-method-warning-modal.component.scss'],
})
export class MixedMethodWarningModalComponent {
  // Decorators...
  @ViewChild('modal')
  modal: ModalDialogComponent;

  @Output()
  invokePrimaryClick = new EventEmitter<void>();

  // Public properties...
  content: JsonContent = content;
  secureMessagesUrl = environment.secureMessagesUrl;

  // Public methods...
  emitPrimary(): void {
    this.invokePrimaryClick.emit();
  }
}
