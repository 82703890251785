import { Account, Quote, TweQuote } from '@app/etfs-equities/models';

export const isQuoteVgETF = (quote?: Quote) => {
  return quote?.isVgETF === true;
};

export const calculateDollarValueOfSharesHeld = (sharesHeld: number, quote: Quote): number => {
  if (!sharesHeld || !quote || (!quote.lastTradePrice && !quote.previousClosePrice)) {
    return null;
  }

  if (!Number(quote.lastTradePrice) || parseFloat(quote.lastTradePrice) === 0) {
    return sharesHeld * parseFloat(quote.previousClosePrice);
  }

  return sharesHeld * parseFloat(quote.lastTradePrice);
};

export const getSharesQuantity = (holdings: Account.Holding[]): number => {
  return holdings?.length > 0 ? Math.abs(holdings[0].quantity) : 0;
};

export const mapToTweQuoteModel = (quote: Quote): TweQuote => {
  return {
    askPrice: quote.askPrice,
    askPriceExChg: quote.askPriceExChg,
    askPriceExChgName: quote.askPriceExChgName,
    askSize: quote.askSize,
    bidPrice: quote.bidPrice,
    bidPriceExChg: quote.bidPriceExChg,
    bidPriceExChgName: quote.bidPriceExChgName,
    bidSize: quote.bidSize,
    exDividendDate: quote.exDividendDate,
    dayHighPrice: quote.dayHighPrice,
    dayLowPrice: quote.dayLowPrice,
    dividendPayDate: quote.dividendPayDate,
    dividendPerShare: quote.dividendPerShare,
    fiftyTwoWeekHighPrice: quote.fiftyTwoWeekHighPrice,
    fiftyTwoWeekLowPrice: quote.fiftyTwoWeekLowPrice,
    fullSecurityName: quote.fullSecurityName,
    isAskOutOfRange: quote.isAskOutOfRange,
    isBidOutOfRange: quote.isBidOutOfRange,
    instrumentClasses: quote.instrumentClasses,
    isETF: quote.isETF,
    isOtc: quote.isOtc,
    isTradingHaltLULD: quote.isTradingHaltLULD,
    lastTradeDate: quote.lastTradeDate,
    lastTradeExChg: quote.lastTradeExChg,
    lastTradeExChgName: quote.lastTradeExChgName,
    lastTradePrice: quote.lastTradePrice,
    lastTradeVolume: quote.lastTradeVolume,
    openPrice: quote.openPrice,
    outStandingShares: quote.outStandingShares,
    previousClosePrice: quote.previousClosePrice,
    primaryExChgName: quote.primaryExChgName,
    romCusip: quote.romCusip,
    romTickerSymbol: quote.romTickerSymbol,
    securityType: quote.securityType,
    tickerLongName: quote.tickerLongName,
    tickerSymbol: quote.tickerSymbol,
    todaysPriceChange: quote.todaysPriceChange,
    todaysPriceChangePercent: quote.todaysPriceChangePercent,
    todaysVolume: quote.todaysVolume,
    vendor: quote.vendor,
  };
};

export const mapToTweEveningQuoteModel = (quote: Quote): TweQuote => {
  return {
    ...mapToTweQuoteModel(quote),
    lastTradeDate: quote.afterMarketTradeDateTime,
    lastTradePrice: quote.afterMarketTradePrice,
    todaysPriceChange: quote.afterMarketTradeNetChange,
    todaysPriceChangePercent: quote.afterMarketTradePriceChangePercentToday,
    todaysVolume: quote.afterMarketTradeVolume?.toString(),
  };
};
