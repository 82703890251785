import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/shared/services/environment/environment.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, take } from 'rxjs/operators';

import { createLoadEnvironmentSuccessAction, EnvironmentActionTypes } from '../../actions';

@Injectable()
export class EnvironmentEffects {
  loadEnvironment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActionTypes.LOAD_ENVIRONMENT),
      exhaustMap(() => {
        return this.environmentService.getEnvironment().pipe(
          take(1),
          map((response) =>
            createLoadEnvironmentSuccessAction({
              ...response,
            })
          )
        );
      })
    )
  );

  constructor(private readonly environmentService: EnvironmentService, private readonly actions$: Actions) {}
}
