import { Component, OnInit } from '@angular/core';
import { OrderEnums } from '@app/etfs-equities/enums';
import content from '@content/content.json';
import { TradeTicketService } from '@etfs-equities/services';
import { TayneState } from '@etfs-equities/store';
import { selectIsIncapacitated } from '@etfs-equities/store/selectors/client-data/client-data.selectors';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-amount-type-control',
  templateUrl: './amount-type-control.component.html',
  styleUrls: ['./amount-type-control.component.scss'],
})
export class AmountTypeControlComponent implements OnInit {
  protected readonly content = content;

  isIncapacitated$: Observable<boolean>;
  amountTypes = OrderEnums.AmountTypes;

  constructor(readonly tradeTicketService: TradeTicketService, private readonly store: Store<TayneState>) {}

  ngOnInit() {
    this.isIncapacitated$ = this.store.pipe(select(selectIsIncapacitated));
  }
}
