export enum RuleActions {
  ERROR = 'ERROR',
  REVIEW_RELEASE = 'REVIEW_RELEASE',
  WARNING = 'WARNING',
}

export enum TransactionLevels {
  ACCOUNT_LEVEL = 'ACCOUNT_LEVEL',
  TRANSACTION_DETAIL_LEVEL = 'TRANSACTION_DETAIL_LEVEL',
  HOLDING_LEVEL = 'HOLDING_LEVEL',
  CLIENT_LEVEL = 'CLIENT_LEVEL',
}

export enum AcceptedRulesNextStep {
  PREVIEW = 'PREVIEW',
  SUBMIT = 'SUBMIT',
}
