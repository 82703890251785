import { Order } from '@app/etfs-equities/models/order.model';

export interface OrderState {
  order: Order | null;
  origOrder: Order | null;
  orderLoadError: boolean;
}

export const initialOrderState: OrderState = {
  order: null,
  origOrder: null,
  orderLoadError: false,
};
