import { HttpErrorResponse } from '@angular/common/http';
import { CostBasis } from '@app/etfs-equities/models';
export interface CostBasisState {
  accounts: CostBasis.CostBasisForSelectedAccountResponse[];
  lots: CostBasis.LotResponse | null;
  lotsLoadError: HttpErrorResponse | null;
  lotForm: { [key: string]: any } | null;
  tradeMethods: CostBasis.CostBasisTradeMethods | null;
  tradeMethodsError: HttpErrorResponse | null;
  methodAutoSelection: CostBasis.MethodAutoSelectionModel;
  isEditCostBasis: boolean;
}

export const initialCostBasisState: CostBasisState = {
  accounts: [],
  lots: null,
  lotsLoadError: null,
  lotForm: null,
  tradeMethods: null,
  tradeMethodsError: null,
  methodAutoSelection: {
    value: null,
    mustBeFifo: false,
    shouldBeCleared: false,
  },
  isEditCostBasis: false,
};
