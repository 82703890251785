<ng-container *ngIf="{ dollarBasedTradesEnabled: (dollarBasedTradesEnabled$ | async) } as view">
  <c11n-modal-dialog #modal [headingText]="content.tradeHelpModal.title" maxWidth="1100">
    <div c11n-modal-dialog-body>
      <c11n-tabs [activeItem]="activeItem" [hasAnimation]="true">
        <!-- Trading Policies Tab -->
        <c11n-tab-panel id="trading-policies-tab" [labelText]="content.tradeHelpModal.tradingPoliciesTab.title">
          <div class="scrollable-selection list-style">
            <p class="twe-c11n-color-ink-600">{{ content.tradeHelpModal.tradingPoliciesTab.tradingPoliciesBodyTop }}</p>
            <div>
              <h3>{{ content.tradeHelpModal.tradingPoliciesTab.marketOpening }}</h3>

              <p
                class="mt-1 twe-c11n-color-ink-600"
                [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.marketOpeningDescription"
              ></p>

              <p class="twe-c11n-color-ink-600">{{ content.tradeHelpModal.tradingPoliciesTab.marketOpeningBody }}</p>

              <p class="twe-c11n-color-ink-600">{{ content.tradeHelpModal.tradingPoliciesTab.marketOpeningEnd }}</p>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.tradingPoliciesTab.placingOrder }}</h3>

              <ul>
                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.placingOrderFirstListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.placingOrderSecondListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.placingOrderThirddListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.placingOrderFourthListItem"
                ></li>

                <li class="mt-1 twe-c11n-color-ink-600"
                  >{{ content.tradeHelpModal.tradingPoliciesTab.placingOrderFifthListItem }}
                  <a
                    c11n-link
                    variant="primary-reinforced"
                    [attr.href]="content.tradeHelpModal.tradingPoliciesTab.commissionLink"
                    target="_blank"
                    >{{ content.tradeHelpModal.tradingPoliciesTab.commissionLinkText }}</a
                  >

                  <span>{{ content.tradeHelpModal.tradingPoliciesTab.placingOrderEnd }}</span>
                </li>
              </ul>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.tradingPoliciesTab.cancellingOrder }}</h3>

              <ul>
                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.cancellingOrderFirstListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.cancellingOrderSecondListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.cancellingOrderThirdListItem"
                ></li>
              </ul>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.tradingPoliciesTab.specificSecurity }}</h3>

              <ul>
                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.specificSecurityFirstListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.specificSecuritySecondListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.specificSecurityThirdListItem"
                ></li>
              </ul>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.tradingPoliciesTab.disclaimer }}</h3>

              <ul>
                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.disclaimerFirstListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.disclaimerSecondListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingPoliciesTab.disclaimerThirdListItem"
                ></li>

                <li class="mt-1 twe-c11n-color-ink-600"
                  >{{ content.tradeHelpModal.tradingPoliciesTab.disclaimerFourthListItem }}
                  <a
                    c11n-link
                    variant="primary-reinforced"
                    [attr.href]="content.tradeHelpModal.tradingPoliciesTab.vbaLink"
                    target="_blank"
                    >{{ content.tradeHelpModal.tradingPoliciesTab.vbaLinkText }}</a
                  >.
                </li>
              </ul>
            </div>
          </div>
        </c11n-tab-panel>

        <!-- Dollar-Based Trades Tab-->
        <c11n-tab-panel id="dollar-based-trades-tab" [labelText]="content.tradeHelpModal.dollarBasedTradesTab.title">
          <div class="scrollable-selection">
            <h2>{{ content.tradeHelpModal.dollarBasedTradesTab.dollarBasedTrading.title }}</h2>
            <p class="mt-1 twe-c11n-color-ink-600"
              ><em>{{ content.tradeHelpModal.dollarBasedTradesTab.dollarBasedTrading.body1 }}</em></p
            >
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.dollarBasedTrading.body2
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.dollarBasedTrading.body3
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.OverviewDollarTrades.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.OverviewDollarTrades.body1
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.OverviewDollarTrades.body2
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.eligibleAccounts.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.eligibleAccounts.body1
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.eligibleAccounts.body2
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.eligibleAccounts.body3
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.eligibleSecurities.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.eligibleSecurities.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.eligibleOrders.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.eligibleOrders.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.dollarValueOrderConversion.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.dollarValueOrderConversion.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.estimatedShare.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.estimatedShare.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.finalFractional.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.finalFractional.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.sellingEligibleSecurities.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.sellingEligibleSecurities.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.avoidingOverselling.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.avoidingOverselling.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.tradesNotHeld.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.tradesNotHeld.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.orderDuration.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.orderDuration.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.orderAggregation.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.orderAggregation.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.extendedHoursTrading.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.extendedHoursTrading.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.tradingHalts.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.tradingHalts.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.orderFulfillment.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.orderFulfillment.body
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.orderFulfillment.body2
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.orderFulfillment.body3
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.orderFulfillment.body4
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.limitedTradeChannels.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.limitedTradeChannels.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.costBasisMethodRestrictions.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.costBasisMethodRestrictions.body1
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.costBasisMethodRestrictions.body2
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.serviceLimitation.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.serviceLimitation.body
            }}</p>
            <h2>{{ content.tradeHelpModal.dollarBasedTradesTab.fractionalShareOwnership }}</h2>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.dividends.title }}</h3>
            <p class="twe-c11n-color-ink-600">
              {{ content.tradeHelpModal.dollarBasedTradesTab.dividends.bodyPart1 }}
              <a
                c11n-link
                variant="primary-reinforced"
                [attr.href]="content.tradeHelpModal.dollarBasedTradesTab.dividends.link"
                target="_blank"
              >
                {{ content.tradeHelpModal.dollarBasedTradesTab.dividends.linkText }}</a
              >.
              {{ content.tradeHelpModal.dollarBasedTradesTab.dividends.bodyPart2 }}
            </p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.corporateActions.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.corporateActions.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.votingRights.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.votingRights.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.stockCertificates.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.stockCertificates.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.accountTransfer.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.accountTransfer.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.suspendAutoLiquidation.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.suspendAutoLiquidation.body
            }}</p>
            <h2>{{ content.tradeHelpModal.dollarBasedTradesTab.automaticETFInvestmentTOU.title }}</h2>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.automaticETFInvestmentTOU.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.automaticETFOverview.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.automaticETFOverview.body1
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.automaticETFOverview.body2
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.eligibleAccountsAIP.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.eligibleAccountsAIP.body1
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.eligibleAccountsAIP.body2
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.eligibleAccountsAIP.body3
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.eligibleSecuritiesAIP.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.eligibleSecuritiesAIP.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.eligibleOrdersAIP.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.eligibleOrdersAIP.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.planSchedules.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.planSchedules.body1
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.planSchedules.body2
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.planSchedules.body3
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.tradesNotHeldAIP.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.tradesNotHeldAIP.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.orderAggregationAIP.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.orderAggregationAIP.body1
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.orderAggregationAIP.body2
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.planAmendments.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.planAmendments.body1
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.planAmendments.body2
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.accountFunding.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.accountFunding.body1
            }}</p>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.accountFunding.body2
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.limitedTradeChannelsAIP.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.limitedTradeChannelsAIP.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.serviceLimitationAIP.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.serviceLimitationAIP.body
            }}</p>
            <h3>{{ content.tradeHelpModal.dollarBasedTradesTab.electronicAcceptance.title }}</h3>
            <p class="mt-1 twe-c11n-color-ink-600">{{
              content.tradeHelpModal.dollarBasedTradesTab.electronicAcceptance.body
            }}</p>
          </div>
        </c11n-tab-panel>

        <!-- Trading Risks Tab-->
        <c11n-tab-panel id="trading-risks-tab" [labelText]="content.tradeHelpModal.tradingRisksTab.title">
          <div class="scrollable-selection list-style">
            <p class="twe-c11n-color-ink-600">{{ content.tradeHelpModal.tradingRisksTab.tradingRisksBodyTop }}</p>

            <div>
              <h3>{{ content.tradeHelpModal.tradingRisksTab.liquidity }}</h3>

              <ul>
                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingRisksTab.liquidityFirstListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingRisksTab.liquiditySecondListItem"
                ></li>
              </ul>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.tradingRisksTab.volatileMarket }}</h3>

              <ul>
                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingRisksTab.volatileMarketFirstListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingRisksTab.volatileMarketSecondListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingRisksTab.volatileMarketThirdListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingRisksTab.volatileMarketFourthListItem"
                ></li>
              </ul>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.tradingRisksTab.risks }}</h3>

              <ul>
                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingRisksTab.riskFirstListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingRisksTab.riskSecondListItem"
                ></li>
              </ul>

              <p class="mt-3 twe-c11n-color-ink-600">
                {{ content.tradeHelpModal.tradingRisksTab.riskBody1 }}
                <a
                  c11n-link
                  variant="primary-reinforced"
                  [attr.href]="content.tradeHelpModal.tradingRisksTab.links.secGov"
                  target="_blank"
                  aria-describedby="opens-new-window"
                >
                  {{ content.tradeHelpModal.tradingRisksTab.links.secGovText }}
                </a>
                and
                <a
                  c11n-link
                  variant="primary-reinforced"
                  [attr.href]="content.tradeHelpModal.tradingRisksTab.links.finra"
                  target="_blank"
                  aria-describedby="opens-new-window"
                >
                  {{ content.tradeHelpModal.tradingRisksTab.links.finraText }}</a
                >.
                {{ content.tradeHelpModal.tradingRisksTab.riskBody2 }}
                <a
                  c11n-link
                  variant="primary-reinforced"
                  [attr.href]="content.tradeHelpModal.tradingRisksTab.links.termsAndConditions"
                  target="_blank"
                  aria-describedby="opens-new-window"
                >
                  {{ content.tradeHelpModal.tradingRisksTab.links.termsAndConditionsText }}
                </a>
                {{ content.tradeHelpModal.tradingRisksTab.riskBody3 }}
              </p>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.tradingRisksTab.dayTrading }}</h3>

              <p
                class="mt-1 twe-c11n-color-ink-600"
                [innerHtml]="content.tradeHelpModal.tradingRisksTab.dayTradingBody"
              ></p>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.tradingRisksTab.mitigate }}</h3>

              <ul>
                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingRisksTab.mitigateFirstListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.tradingRisksTab.mitigateSecondListItem"
                ></li>
              </ul>

              <p
                class="mt-3 twe-c11n-color-ink-600"
                [innerHtml]="content.tradeHelpModal.tradingRisksTab.mitigateBody"
              ></p>

              <p class="mt-3 twe-c11n-color-ink-600"
                >{{ content.tradeHelpModal.tradingRisksTab.mitigateEnd }}
                <a
                  c11n-link
                  variant="primary-reinforced"
                  [attr.href]="content.tradeHelpModal.tradingPoliciesTab.vbaLink"
                  target="_blank"
                  >{{ content.tradeHelpModal.tradingPoliciesTab.vbaLinkText }}</a
                >.
              </p>
            </div>
          </div>
        </c11n-tab-panel>

        <!-- Preferred Securities Tab-->
        <c11n-tab-panel id="preferred-securities-tab" [labelText]="content.tradeHelpModal.preferredSecuritiesTab.title">
          <div class="scrollable-selection list-style">
            <p class="twe-c11n-color-ink-600">{{
              content.tradeHelpModal.preferredSecuritiesTab.preferredSecuritiesBodyTop
            }}</p>

            <div>
              <h3>{{ content.tradeHelpModal.preferredSecuritiesTab.income }}</h3>

              <p class="mt-1 twe-c11n-color-ink-600">{{ content.tradeHelpModal.preferredSecuritiesTab.incomeBody }}</p>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.preferredSecuritiesTab.creditRating }}</h3>

              <p class="mt-1 twe-c11n-color-ink-600">{{
                content.tradeHelpModal.preferredSecuritiesTab.creditRatingBody
              }}</p>

              <ul>
                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.creditFirstListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.creditSecondListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.creditThirdListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.creditFourthListItem"
                ></li>
              </ul>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.preferredSecuritiesTab.disclosure }}</h3>

              <p class="mt-1 twe-c11n-color-ink-600">
                {{ content.tradeHelpModal.preferredSecuritiesTab.disclosureBody }}
                <a
                  c11n-link
                  variant="primary-reinforced"
                  [attr.href]="content.tradeHelpModal.preferredSecuritiesTab.disclosureLink"
                  target="_blank"
                  aria-describedby="opens-new-window"
                >
                  {{ content.tradeHelpModal.preferredSecuritiesTab.disclosureLinkText }}</a
                >.
              </p>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.preferredSecuritiesTab.types }}</h3>

              <ul>
                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.typeFirstListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.typeSecondListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.typeThirdListItem"
                ></li>
              </ul>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.preferredSecuritiesTab.taxability }}</h3>

              <ul>
                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.taxabilityFirstListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.taxabilitySecondListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.taxabilityThirdListItem"
                ></li>
              </ul>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.preferredSecuritiesTab.liquidity }}</h3>

              <p class="mt-1 twe-c11n-color-ink-600">{{
                content.tradeHelpModal.preferredSecuritiesTab.liquidityBody
              }}</p>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.preferredSecuritiesTab.fees }}</h3>

              <p class="mt-1 twe-c11n-color-ink-600"
                >{{ content.tradeHelpModal.preferredSecuritiesTab.feesBody }}
                <a
                  c11n-link
                  variant="primary-reinforced"
                  [attr.href]="content.tradeHelpModal.tradingPoliciesTab.commissionLink"
                  target="_blank"
                  >{{ content.tradeHelpModal.tradingPoliciesTab.commissionLinkText }}</a
                >
                <span>{{ content.tradeHelpModal.tradingPoliciesTab.placingOrderEnd }}</span>
              </p>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.preferredSecuritiesTab.risk }}</h3>

              <ul>
                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.riskFirstListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.riskSecondListItem"
                ></li>

                <li
                  class="mt-1 twe-c11n-color-ink-600"
                  [innerHtml]="content.tradeHelpModal.preferredSecuritiesTab.riskThirdListItem"
                ></li>
              </ul>
            </div>
          </div>
        </c11n-tab-panel>

        <!-- Specialized ETFs and ETNs Tab -->
        <c11n-tab-panel id="specialized-tab" [labelText]="content.tradeHelpModal.specializedTab.title">
          <div class="list-style">
            <p class="twe-c11n-color-ink-600">{{ content.tradeHelpModal.specializedTab.specializedBodyTop }}</p>

            <div>
              <h3>{{ content.tradeHelpModal.specializedTab.etf }}</h3>

              <p class="mt-1 twe-c11n-color-ink-600">{{ content.tradeHelpModal.specializedTab.etfBody }}</p>
            </div>

            <div>
              <h3>{{ content.tradeHelpModal.specializedTab.etn }}</h3>

              <p class="mt-1 twe-c11n-color-ink-600">{{ content.tradeHelpModal.specializedTab.etnDescription }}</p>

              <p class="mt-1 twe-c11n-color-ink-600">{{ content.tradeHelpModal.specializedTab.etnBody }}</p>

              <p class="mt-1 twe-c11n-color-ink-600">{{ content.tradeHelpModal.specializedTab.etnEnd }}</p>
            </div>
          </div>
        </c11n-tab-panel>
      </c11n-tabs>
    </div>
  </c11n-modal-dialog>
</ng-container>
