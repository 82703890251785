import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdobeAnalyticsService } from '@app/core/services';
import { SymbolRetrievalType } from '@app/etfs-equities/enums';
import { JsonContent, Quote } from '@app/etfs-equities/models';
import { SymbolRetrievalError } from '@app/etfs-equities/models/symbol-retrieval-error.model';
import { MarketDataService, TradeTicketService, WindowService } from '@app/etfs-equities/services';
import {
  createClearQuoteAction,
  createLoadQuoteAction,
  createLoadQuoteByCusipAction,
  createResetTradeTicketAction,
  selectQuote,
  TayneState,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { InputInputComponent } from '@vg-constellation/angular-17/input';
import { filter, map, Observable, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';

@Component({
  selector: 'twe-symbol-search-control',
  templateUrl: './symbol-search-control.component.html',
  styleUrls: ['./symbol-search-control.component.scss'],
})
export class SymbolSearchControlComponent implements OnInit, OnDestroy {
  //  Decorators...

  @Input() isChangeOrder = false;

  @Input() isDisabled = false;

  @Output() hasMutualFundErrorEmitter = new EventEmitter<boolean>();

  @ViewChild('symbolInput')
  symbolInput: InputInputComponent;

  //  Public observables/subjects...

  quote$: Observable<Quote>;

  //  Public variables...

  content: JsonContent = content;

  symbol: string;

  isBeacon = false;

  // Private observables/subjects...

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public marketDataService: MarketDataService,
    public tradeTicketService: TradeTicketService,
    private readonly windowService: WindowService,
    private readonly store: Store<TayneState>,
    private readonly route: ActivatedRoute,
    public readonly adobeService: AdobeAnalyticsService
  ) {}

  get symbolHasError() {
    return (
      this.tradeTicketService.controlHasError('symbol') ||
      this.tradeTicketService.controlHasError('hasQuote') ||
      this.marketDataService.symbolNotFound
    );
  }

  getErrorText() {
    if (
      this.tradeTicketService.controlHasError('symbol', 'maxlength') ||
      this.tradeTicketService.controlHasError('symbol', 'pattern')
    ) {
      return this.content.validation.symbol.tryKeywordSearch;
    }

    if (this.tradeTicketService.controlHasError('symbol', 'required')) {
      return this.content.validation.symbol.empty;
    }

    if (this.marketDataService.symbolNotFound) {
      return this.content.validation.symbol.notFound;
    }

    if (this.tradeTicketService.controlHasError('symbol', 'eveningTradingDisabled')) {
      return this.content.validation.eveningTrading.tickerHasNoEveningTrading;
    }
  }

  ngOnInit() {
    this.quote$ = this.store.pipe(select(selectQuote));
    this.isBeacon = this.windowService.getIsBeacon();

    this.prefillSymbolFromRoute();
    this.watchForLoadQuoteSuccess();
    this.watchForMutualFundSymbolError();
    this.watchForSymbolChanges();
    this.watchForSelectedKeywordResult();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  fetchQuote(event?: Event) {
    event?.preventDefault();

    // We allow blanks in the symbol, so need to trim leading and trailing blanks.
    // Store the result back in the form control because it gets referenced later.
    const symbolControl = this.tradeTicketService.tradeTicket.controls.symbol;
    if (!!symbolControl.value) {
      const cleanSymbol = this.tradeTicketService.replaceDotsAndUnderscores(symbolControl.value);
      symbolControl.setValue(cleanSymbol, { emitEvent: false, onlySelf: true });
    }
    this.symbol = symbolControl.value;
    if (
      !this.symbol ||
      !symbolControl.valid ||
      (!this.marketDataService.allowRetry && this.symbol === this.marketDataService.lastSymbol) ||
      this.marketDataService.isLoading
    ) {
      return;
    }
    this.store.dispatch(createLoadQuoteAction(this.symbol));

    this.resetAfterNewSymbolSearch(this.symbol);
  }

  fetchQuoteByCusip(): void {
    const cusip = this.tradeTicketService.getKeywordCusip();

    if (cusip) {
      this.store.dispatch(
        createLoadQuoteByCusipAction(cusip, this.tradeTicketService.tradeTicket.controls.symbol.value)
      );
    } else {
      this.fetchQuote();
    }
  }

  focusInput() {
    setTimeout(() => {
      this.symbolInput?.elRef.nativeElement.focus();
      this.symbolInput?.elRef.nativeElement.select();
    });
  }

  private resetAfterNewSymbolSearch(tradeTicketSymbol: string) {
    if (tradeTicketSymbol !== null) {
      this.tradeTicketService.resetEverythingBut([
        'accountId',
        'brokerageAccountNumber',
        'symbol',
        'action',
        'isCostBasisEligible',
      ]);
      this.tradeTicketService.resetAmountType();
      this.tradeTicketService.setSharesValidators();
    }
  }

  resetTradeTicket = () => {
    this.store.dispatch(createResetTradeTicketAction());
  };

  private prefillSymbolFromRoute() {
    this.route.queryParamMap
      .pipe(
        map((params) => params.get('ticker')),
        filter((ticker) => !!ticker && !this.isChangeOrder),
        tap((ticker) => {
          // don't set the symbol if there's already a value for it. We don't want to override it if it has changed
          // since we first entered the trade ticket (e.g. we are returning to the trade page via an Edit button)
          if (!this.tradeTicketService.tradeTicket.controls.symbol.value) {
            this.tradeTicketService.setSymbol(ticker);
          }
          this.fetchQuote();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForSymbolChanges() {
    this.tradeTicketService.tradeTicket.controls.symbol.valueChanges
      .pipe(
        // Clear the "symbol not found" error when the client starts typing.
        tap(() => {
          this.marketDataService.clearSymbolNotFoundError();
        }),

        // If there is a quote in the store, clear it out.
        withLatestFrom(this.store.pipe(select(selectQuote))),
        filter(([_symbol, quote]) => !!quote),
        tap(() => this.store.dispatch(createClearQuoteAction())),

        // Clean up subscription after component is destroyed.
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForLoadQuoteSuccess() {
    this.marketDataService.emitLoadQuoteSuccess$
      .pipe(
        withLatestFrom(this.quote$),
        tap(([_action, quote]) => this.adobeService.sendAdobeLaunchProcess(`TickerSymbol_${quote.tickerSymbol}`)),

        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForMutualFundSymbolError() {
    this.marketDataService.symbolRetrievalError$
      .pipe(
        filter((error: SymbolRetrievalError) => !this.isChangeOrder && error.type === SymbolRetrievalType.MUTUAL_FUND),
        tap(() => {
          this.hasMutualFundErrorEmitter.emit(true);
          this.resetTradeTicket();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForSelectedKeywordResult() {
    this.tradeTicketService.selectedKeywordSearchResult$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.fetchQuoteByCusip());
  }
}
