export enum LotTerms {
  SHORT = 'Short',
  LONG = 'Long',
}

export enum MethodCodes {
  AVG_COST = 'AvgCost',
  FIFO = 'FIFO',
  HIFO = 'HIFO',
  SPEC_ID = 'SpecID',
  MIN_TAX = 'MinTax',
}
