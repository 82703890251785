import { Component, OnInit } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import { selectAccountIsMargin, selectActionIsBuy, selectQuoteIsVgETF } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-margin-requirements-vg-etfs-banner',
  templateUrl: './margin-requirements-vg-etfs-banner.component.html',
  styleUrl: './margin-requirements-vg-etfs-banner.component.scss',
})
export class MarginRequirementsVgETFsBannerComponent implements OnInit {
  //  Public variables...
  content: JsonContent = content;

  //  Public observables/subjects...
  accountIsMargin$: Observable<boolean>;
  quoteIsVgEtf$: Observable<boolean>;
  actionIsBuy$: Observable<boolean>;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.accountIsMargin$ = this.store.select(selectAccountIsMargin);
    this.quoteIsVgEtf$ = this.store.pipe(select(selectQuoteIsVgETF));
    this.actionIsBuy$ = this.store.pipe(select(selectActionIsBuy));
  }
}
