import { Component, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';

@Component({
  selector: 'twe-balances-guidelet-modal',
  templateUrl: './balances-guidelet-modal.component.html',
  styleUrls: ['./balances-guidelet-modal.component.scss'],
})
export class BalancesGuideletComponent {
  @ViewChild('modal')
  modal: ModalDialogComponent;

  //  Public variables...

  content: JsonContent = content;

  open() {
    this.modal.openModalDialog();
  }

  close() {
    this.modal.closeDialogModal();
  }
}
