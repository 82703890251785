import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentService, LOCATION_TOKEN } from '@app/shared/services/environment/environment.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SessionCheckService {
  sessionFailed = true;
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
    @Inject(LOCATION_TOKEN) private readonly location: Location
  ) {}

  // Getters/Setters...

  get isLocalhost() {
    return this.location.hostname.indexOf('localhost') > -1;
  }

  private checkSessionRequest(token?: string) {
    const sessionPath = `sessioncheck` + (token ? `?token=${token}` : '');
    return this.http.get(this.envService.getApiUrlBaseOnRoute() + sessionPath, {
      withCredentials: true,
    });
  }

  checkSession(token?: string) {
    this.sessionFailed = true;
    return this.checkSessionRequest(token).pipe(tap(() => (this.sessionFailed = false)));
  }
}
