import { ClientDataState, selectTayneState, TayneState } from '@etfs-equities/store';
import { createSelector } from '@ngrx/store';

export const selectClientDataState = createSelector(selectTayneState, (state: TayneState) => state.clientData);

export const selectIsIncapacitated = createSelector(
  selectClientDataState,
  (state: ClientDataState) => state.isIncapacitated
);

export const selectIsExitBtnClicked = createSelector(
  selectClientDataState,
  (state: ClientDataState) => state.isExitBtnClicked
);

export const selectClientId = createSelector(selectClientDataState, (state: ClientDataState) => state.clientId);
