import { Action } from '@ngrx/store';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum ChangeOrderActionTypes {
  SET_ISCHANGEORDER = '[ChangeOrder] Set isChangeOrder',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class SetIsChangeOrderAction implements Action {
  readonly type = ChangeOrderActionTypes.SET_ISCHANGEORDER;
  constructor(public payload: boolean) {}
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createSetIsChangeOrderAction = (isChangeOrder: boolean) => {
  return new SetIsChangeOrderAction(isChangeOrder);
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type ChangeOrderTypesUnion = SetIsChangeOrderAction;
