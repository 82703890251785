import { Order } from '@app/etfs-equities/models';
import { OrderUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { OrderEnums } from '@etfs-equities/enums';
import { createSelector } from '@ngrx/store';

import { OrderState, TayneState } from '../../states';
import { selectQuoteIsEtf } from '../quote/quote.selectors';
import { selectTayneState } from '../tayne/tayne.selectors';

export const selectOrderState = createSelector(selectTayneState, (state: TayneState) => state.order);

export const selectOrder = createSelector(selectOrderState, (state: OrderState) => state.order);

export const selectOrderLoadError = createSelector(selectOrderState, (state: OrderState) => state.orderLoadError);

export const selectOrigOrder = createSelector(selectOrderState, (state: OrderState) => state.origOrder);

export const selectOrderContent = createSelector(selectOrderState, (state: OrderState) => {
  if (!state.order) {
    return null;
  }

  switch (state.order.orderType) {
    case OrderEnums.Types.MARKET:
      return state.order.amountType === OrderEnums.AmountTypes.DOLLARS
        ? content.dynamicContentDollarBasedOrders
        : content.dynamicContentMarketOrder;
    case OrderEnums.Types.STOP:
      return content.dynamicContentStopOrders;
    case OrderEnums.Types.LIMIT:
    case OrderEnums.Types.STOP_LIMIT:
      return content.dynamicContentLimitAndStopOrders;
    default:
      return null;
  }
});

export const selectOrderHasExceededFundsAvailable = createSelector(selectOrder, (state: Order.Order) =>
  OrderUtil.orderHasExceededFundsAvailable(state)
);

export const selectShouldDisplayProspectusLink = createSelector(
  selectOrder,
  selectQuoteIsEtf,
  (order: Order.Order, quoteIsEtf: boolean) => {
    return order?.transactionType === OrderEnums.TransactionTypes.BUY && quoteIsEtf;
  }
);

export const selectOrderFormatSharesDisplay = createSelector(selectOrder, (order: Order.Order) => {
  return OrderUtil.formatSharesDisplay(order?.shares);
});
