import { HttpClient, HttpContext, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingContext } from '@app/core/interceptors/loading-interceptor';
import { UILogError } from '@app/core/models/ui-log/ui-log.model';
import { BusinessWorkStreamEnum } from '@app/etfs-equities/enums/order.enums';
import { Account, OpenOrder, OpenOrdersResponse } from '@app/etfs-equities/models';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, tap } from 'rxjs/operators';

enum AccountServiceRequestTypes {
  ACCOUNTS = 'ACCOUNTS',
  OPEN_ORDERS = 'OPEN_ORDERS',
}

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  //  Public observables/subjects...

  openOrdersResponse$: Observable<OpenOrdersResponse>;

  accountRetrievalError$ = new Subject<UILogError>();

  accountRetrievalRetry$ = new Subject<void>();

  transactableAccountsRetrievalError$ = new Subject<void>();

  //  Public variables...

  isLoadingAccounts = false;

  isLoadingOpenOrders = false;

  isRefreshingAccounts = false;

  orderRetrievalFailed = false;

  hasCriticalHoldingError = false;

  constructor(private readonly http: HttpClient, private readonly envService: EnvironmentService) {}

  fetchAccounts(): Observable<Account.MidTierResponse> {
    this.isLoadingAccounts = true;

    return this.getAccountsRequest().pipe(
      tap(({ accounts }) => {
        if (!accounts.length) {
          this.transactableAccountsRetrievalError$.next();
          this.accountRetrievalError$.next({
            error: new HttpErrorResponse({
              error: new Error('Account retrieval failed.'),
              status: HttpStatusCode.NoContent,
              statusText: 'Not Found',
            }),
            serviceName: 'AccountService.fetchAccounts',
          });
        }
        this.isLoadingAccounts = false;
      })
    );
  }

  refreshAccounts(): Observable<Account.MidTierResponse> {
    this.isRefreshingAccounts = true;

    return this.getAccountsRequest(true).pipe(tap(() => (this.isRefreshingAccounts = false)));
  }

  fetchOpenOrders(): Observable<OpenOrdersResponse> {
    this.isLoadingOpenOrders = true;

    if (this.openOrdersResponse$) {
      return this.openOrdersResponse$;
    }

    const options = { withCredentials: true, context: new HttpContext().set(LoadingContext, { showLoading: true }) };

    this.openOrdersResponse$ = this.http
      .get<OpenOrdersResponse>(this.envService.getApiUrlBaseOnRoute() + 'api/open-orders', options)
      .pipe(
        shareReplay(1),
        tap(() => {
          this.isLoadingOpenOrders = false;
          this.orderRetrievalFailed = false;
        }),
        catchError((error: HttpErrorResponse) => this.handleHttpError(error, AccountServiceRequestTypes.OPEN_ORDERS))
      );

    return this.openOrdersResponse$;
  }

  fetchOpenOrdersForAccount(accountId: string | number): Observable<OpenOrder[]> {
    return this.fetchOpenOrders().pipe(
      filter((midtierResponse) => midtierResponse.orders && midtierResponse.orders.length > 0),
      map((midtierResponse) =>
        midtierResponse.orders.filter((openOrder) => {
          return (
            openOrder.accountId === accountId &&
            openOrder.businessOrigin?.businessWorkStream !== BusinessWorkStreamEnum.AUTO_INVEST_INTO_ETFS
          );
        })
      )
    );
  }

  // Break memoization upon client request.
  refreshOpenOrdersForAccount(accountId: string | number): Observable<OpenOrder[]> {
    this.openOrdersResponse$ = null;
    return this.fetchOpenOrdersForAccount(accountId);
  }

  private getAccountsRequest(showLoading: boolean = false) {
    const options = {
      withCredentials: true,
      context: new HttpContext().set(LoadingContext, { showLoading }),
    };
    return this.http
      .get<Account.MidTierResponse>(this.envService.getApiUrlBaseOnRoute() + 'api/accounts', options)
      .pipe(
        tap(
          (response: Account.MidTierResponse) =>
            (this.hasCriticalHoldingError = this.responseHasCriticalHoldingsError(response))
        ),
        catchError((error: HttpErrorResponse) => this.handleHttpError(error, AccountServiceRequestTypes.ACCOUNTS))
      );
  }

  private handleHttpError(error: HttpErrorResponse, type: AccountServiceRequestTypes): Observable<never> {
    switch (type) {
      case AccountServiceRequestTypes.ACCOUNTS:
        this.accountRetrievalError$.next({ error, serviceName: 'AccountService.getAccountsRequest' });
        this.hasCriticalHoldingError = true;
        this.isLoadingAccounts = false;
        this.isRefreshingAccounts = false;
        break;
      case AccountServiceRequestTypes.OPEN_ORDERS:
        this.orderRetrievalFailed = true;
        this.isLoadingOpenOrders = false;
        break;
    }

    return throwError(() => error);
  }

  private responseHasCriticalHoldingsError(response: Account.MidTierResponse) {
    return response.errors?.find((e) => e.code === 'ALS-10015') ? true : false;
  }
}
