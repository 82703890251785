import { Action } from '@ngrx/store';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum ClientDataActionTypes {
  SET_INCAPACITATED = '[ClientData] Set Incapacitated',
  SET_EXIT_BTN_STATUS = '[ClientData] Set Exit Button Status',
  SET_CLIENT_ID = '[ClientData] Set Client Id',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class SetIncapacitatedAction implements Action {
  readonly type = ClientDataActionTypes.SET_INCAPACITATED;
  constructor(public payload: boolean) {}
}

export class SetExitBtnStatusAction implements Action {
  readonly type = ClientDataActionTypes.SET_EXIT_BTN_STATUS;
  constructor(public payload: boolean) {}
}

export class SetClientIdAction implements Action {
  readonly type = ClientDataActionTypes.SET_CLIENT_ID;
  constructor(public payload: number) {}
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createSetIncapacitatedAction = (isIncapacitated: boolean) => {
  return new SetIncapacitatedAction(isIncapacitated);
};

export const createSetExitBtnStatusAction = (isExitBtnClicked: boolean) => {
  return new SetExitBtnStatusAction(isExitBtnClicked);
};

export const createSetClientIdAction = (clientId: number) => {
  return new SetClientIdAction(clientId);
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type ClientDataActionTypesUnion = SetIncapacitatedAction | SetExitBtnStatusAction | SetClientIdAction;
