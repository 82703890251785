<ng-container
  *ngIf="{
    dollarBasedTradesToggleEnabled: dollarBasedTradesToggleEnabled$ | async
  } as view"
>
  <c11n-modal-dialog
    #fractionalSharesInfoModal
    [headingText]="content?.fractionalSharesInfoModal.title"
    (closeEvent)="onClose()"
  >
    <div c11n-modal-dialog-body>
      <p
        *ngIf="view.dollarBasedTradesToggleEnabled"
        class="twe-c11n-color-ink-600"
        [innerHTML]="content?.fractionalSharesInfoModal.wholeSharesDBT"
      ></p>
      <p
        *ngIf="!view.dollarBasedTradesToggleEnabled"
        class="twe-c11n-color-ink-600"
        [innerHTML]="content?.fractionalSharesInfoModal.wholeShares"
      ></p>
      <p
        class="twe-c11n-color-ink-600 mb-0"
        [innerHTML]="content?.fractionalSharesInfoModal.fractionalSharesSelling"
      ></p>
    </div>
  </c11n-modal-dialog>
</ng-container>
