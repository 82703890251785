import { Component, Input } from '@angular/core';
import { TooltipDirection } from '@vg-constellation/angular-17/tooltip';

@Component({
  selector: 'twe-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input()
  labelText = '';

  @Input()
  direction: TooltipDirection = 'right';

  @Input()
  tooltipHeader: string;

  @Input()
  autoOrientation = true;
}
