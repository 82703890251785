export enum OrderCancelErrorType {
  GENERIC_ERROR = 'GENERIC_ERROR',
  REST_SERVICE_CALL_ERROR = 'REST_SERVICE_CALL_ERROR',
  SP_EXECUTION_ERROR = 'SP_EXECUTION_ERROR',
  INVALID_REQUEST_ERROR = 'INVALID_REQUEST_ERROR',
  NO_ORDER_FOUND_ERROR = 'NO_ORDER_FOUND_ERROR',
  INELIGIBLE_ACCEPTED_RULES_ERROR = 'INELIGIBLE_ACCEPTED_RULES_ERROR',
  ACCOUNT_NOT_TRANSACTABLE_ERROR = 'ACCOUNT_NOT_TRANSACTABLE_ERROR',
}

export enum SecurityAccountTypes {
  CASH = 'CASH',
  MARGIN = 'MARGIN',
  SHORT = 'SHORT',
}

export enum CancelOrderDuration {
  DAY = 'Day',
  SIXTY_DAY_GTC = 'Good Till Cancel (60 days)',
  IMMEDIATE_OR_CANCEL = 'Immediate or Cancel',
  GOOD_TILL_DAY = 'Good Till Day',
  EVENING = 'Evening',
  FILL_OR_KILL = 'Fill or Kill',
}
