<twe-cost-basis-unavailable-modal></twe-cost-basis-unavailable-modal>
<ng-container
  *ngIf="{
    orderContent: (orderContent$ | async),
    account: (selectedAccount$ | async),
    principal: (principal$ | async),
    order: (order$ | async),
    quote: (quote$ | async),
    dollarBasedTradesEnabled: (dollarBasedTradesEnabled$ | async),
    estimatedShares: (estimatedShares$ | async),
    formatSharesDisplay: (formatSharesDisplay$ | async),
    securityAccountType: (securityAccountType$ | async),
    isEditCostBasis: (isEditCostBasis$ | async)
  } as view"
>
  <div class="preview-grid row no-gutters c11n-space-stack-3x c11n-space--force">
    <div class="preview-row col-12">
      <p class="preview-row__title" data-testid="preview-row-title">{{ content.orderDetail.account }}</p>
      <p id="account-selection" class="preview-row__data" data-testid="preview-row-data"
        >{{ view.account?.shortName
        }}{{
          view.account?.marginCode === marginIndicator ? (' (' + view.account.marginCode + ')' | titlecase) : ''
        }}</p
      >
    </div>

    <div class="preview-row col-6 col-lg-3">
      <p class="preview-row__title" data-testid="preview-row-title">{{ content.orderDetail.transaction }}</p>
      <p class="preview-row__data" data-testid="preview-row-data">{{ view.order?.transactionType }}</p>
      <p class="preview-row__meta" data-testid="preview-row-title">{{ view.securityAccountType }}</p>
    </div>

    <div class="preview-row col-6 col-lg-3">
      <p class="preview-row__title" data-testid="preview-row-title">{{
        view.order?.amountType === amountTypes.DOLLARS
          ? content.orderDetail.estimatedShares
          : content.orderDetail.shares
      }}</p>
      <p class="preview-row__data" data-testid="preview-row-data">
        {{ view.order?.transactionType === transactionTypes.SELL_SHORT ? '-' : ''
        }}{{
          view.order?.amountType === amountTypes.DOLLARS
            ? (view.estimatedShares | number : '1.4-4')
            : view.formatSharesDisplay
        }}
      </p>
      <p class="preview-row__meta" *ngIf="view.order?.allOrNone" data-testid="txt-all-or-none">{{
        content.orderDetail.allOrNone
      }}</p>
    </div>

    <div class="preview-row col-12 col-lg-6">
      <p class="preview-row__title" data-testid="preview-row-title">{{ content.orderDetail.security }}</p>
      <p class="preview-row__data" data-testid="preview-row-data">{{
        (view.quote | tweQuoteTickerLongName) || '&mdash;'
      }}</p>
    </div>

    <div class="preview-row col-lg-3" [ngClass]="{ 'col-4': isStopLimit, 'col-6': isStopOrLimit, 'col-12': isMarket }">
      <p class="preview-row__title" data-testid="preview-row-title">{{ content.orderDetail.orderType }}</p>
      <p class="preview-row__data" data-testid="preview-row-data">{{ view.order?.orderType }}</p>
    </div>

    <div
      class="preview-row col-lg-3"
      [ngClass]="{ 'col-4': isStopLimit, 'col-6': isStopOrLimit }"
      *ngIf="view.order?.stopPrice"
    >
      <p class="preview-row__title" data-testid="preview-row-title">{{ content.orderDetail.stopPrice }}</p>
      <p class="preview-row__data" data-testid="preview-row-data">{{ view.order?.stopPrice | currency }}</p>
    </div>

    <div
      class="preview-row col-lg-3"
      [ngClass]="{ 'col-4': isStopLimit, 'col-6': isStopOrLimit }"
      *ngIf="view.order?.limitPrice"
    >
      <p class="preview-row__title" data-testid="preview-row-title">{{ content.orderDetail.limitPrice }}</p>
      <p class="preview-row__data" data-testid="preview-row-data">{{ view.order?.limitPrice | currency }}</p>
    </div>

    <div
      class="preview-row col-12"
      [ngClass]="{ 'col-lg-3': isStopLimit, 'col-lg-6': isStopOrLimit, 'col-lg-9': isMarket }"
    >
      <p class="preview-row__title" data-testid="preview-row-title">{{ content.orderDetail.duration }}</p>
      <p class="preview-row__data" data-testid="preview-row-data">{{ view.order?.orderDuration || '&mdash;' }}</p>
    </div>

    <div
      *ngIf="!view.dollarBasedTradesEnabled"
      class="preview-row preview-row--no-border preview-row--dense col-6 col-lg-3"
    >
      <p class="preview-row__title preview-row__title--desktop" data-testid="preview-row-title"
        >{{ content.orderDetail.estimatedPrincipal }}*</p
      >
      <p class="preview-row__title preview-row__title--mobile" data-testid="preview-row-title"
        >{{ content.orderDetail.estimatedPrincipalAbbr }}*</p
      >
      <p class="preview-row__data" data-testid="preview-row-data">{{ view.principal | tweSubDollarCurrency }}</p>
    </div>

    <div class="preview-row preview-row--no-border preview-row--dense col-6 col-lg-3">
      <p class="preview-row__title" data-testid="preview-row-title">{{ content.orderDetail.commission }}</p>
      <c11n-chip [labelText]="content.orderDetail.free"> </c11n-chip>
    </div>

    <div class="preview-row preview-row--no-border preview-row--dense col-6">
      <p
        *ngIf="!view.dollarBasedTradesEnabled"
        class="preview-row__title preview-row__title--desktop"
        data-testid="preview-row-title"
        >{{ content.orderDetail.estimatedNetAmount }}*</p
      >
      <p
        *ngIf="!view.dollarBasedTradesEnabled"
        class="preview-row__title preview-row__title--mobile"
        data-testid="preview-row-title"
        >{{ content.orderDetail.estimatedNetAmountAbbr }}*</p
      >
      <p *ngIf="view.dollarBasedTradesEnabled" class="preview-row__title" data-testid="preview-row-title">{{
        view.order?.amountType === amountTypes.DOLLARS
          ? content.orderDetail.amount
          : content.orderDetail.estimatedAmount
      }}</p>
      <p class="preview-row__data" data-testid="preview-row-data">{{
        (view.order?.amountType === amountTypes.DOLLARS ? view.order?.shares : view.principal) | tweSubDollarCurrency
      }}</p>
    </div>

    <div class="preview-row preview-row--no-border preview-row--dense col-12">
      <p
        *ngIf="!view.isEditCostBasis"
        class="disclaimer-text"
        data-testid="txt-disclaimer"
        [innerHTML]="view.orderContent"
      ></p>
    </div>

    <div
      class="preview-row preview-row--border-top preview-row--dense col-12"
      *ngIf="showCostBasis || view.isEditCostBasis"
    >
      <p class="preview-row__title" data-testid="preview-row-title">{{ content.orderDetail.costBasis }}</p>
      <p class="preview-row__data" data-testid="preview-row-data" *ngIf="view.order?.costBasisMethod">
        {{ view.order?.costBasisMethod | tweCostBasisExpand }}
      </p>
      <p class="preview-row__data" data-testid="preview-row-data" *ngIf="!view.order?.costBasisMethod">
        {{ content.labels.unavailable }}
        <button
          c11n-link
          variant="primary-reinforced"
          class="c11n-space-inline-left-0_5x c11n-space--force"
          (click)="openCostBasisUnavailableModal()"
        >
          {{ content.costBasisMethodUnavailableModal.viewDetails }}
        </button>
      </p>

      <c11n-details
        *ngIf="view.order?.costBasisLots"
        [collapseLabelText]="content.orderDetail.specID.hideSelectedShares"
        [expandLabelText]="content.orderDetail.specID.showSelectedShares"
        [labelWeight]="'normal'"
      >
        <div class="container-fluid text-end">
          <div class="preview-row row preview-row--dense">
            <div class="preview-row__title col-4" data-testid="preview-row-title">{{
              content.orderDetail.specID.dateTitle
            }}</div>
            <div class="preview-row__title col-4" data-testid="preview-row-title">{{
              content.orderDetail.specID.sharesTitle
            }}</div>
            <div class="preview-row__title col-4" data-testid="preview-row-title">{{
              content.orderDetail.specID.costTitle
            }}</div>
          </div>
          <div *ngFor="let lot of view.order?.costBasisLots" class="preview-row row preview-row--dense">
            <div class="col-4 preview-row__specid" data-testid="preview-row-data">{{ lot.aquiredDate }}</div>
            <div class="col-4 preview-row__specid" data-testid="preview-row-data">{{
              lot.selectedLotQuantity | number : '1.4-4'
            }}</div>
            <div class="col-4 preview-row__specid" data-testid="preview-row-data">{{
              lot.selectedLotQuantity * lot.costperShare | currency
            }}</div>
          </div>
          <div class="preview-row--no-border row preview-row--dense">
            <div class="preview-row__data col-4 text-start" data-testid="preview-row-title">{{
              content.orderDetail.specID.total
            }}</div>
            <div class="preview-row__data col-4" data-testid="preview-row-data">{{
              totalShares | number : '1.4-4'
            }}</div>
            <div class="preview-row__data col-4" data-testid="preview-row-data">{{ totalSharesCost | currency }}</div>
          </div>
        </div>
      </c11n-details>
    </div>
  </div>
</ng-container>
