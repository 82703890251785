<ng-container
  *ngIf="{
    selectedAction: (selectedAction$ | async),
    isCashAndMarginOrShortHoldings: (isCashAndMarginOrShortHoldings$ | async)
  } as view"
>
  <div class="c11n-space-stack-3x c11n-space--force">
    <twe-input-label
      for="securityAccountType"
      id="margin-trade-type-guidelet"
      data-testid="margin-trade-type-guidelet"
      [labelText]="content?.guidelet.marginTradeType.title"
      [tooltipHeader]="content?.guidelet.marginTradeType.title"
    >
      <p [innerHtml]="content.guidelet.marginTradeType.body"></p>
    </twe-input-label>

    <div
      data-testid="margin-sub-type-value"
      class="read-only c11n-text-md-bold"
      *ngIf="
        !(view.selectedAction === transactionTypes.SELL && view.isCashAndMarginOrShortHoldings && !isReadOnlyPreselect);
        else securityAccTypeToggle
      "
    >
      {{ tradeTicketService.tradeTicket.controls.securityAccountType.value | titlecase }}
    </div>

    <ng-template #securityAccTypeToggle>
      <fieldset [attr.aria-invalid]="tradeTicketService?.controlHasError('securityAccountType')">
        <c11n-segmented-control
          appSegmentControlReset
          color="yellow"
          size="small"
          [legendText]="content.labels.marginTradeType"
        >
          <c11n-segment [labelText]="content.labels.margin">
            <input
              c11n-segment-input
              id="securityAccountTypeMargin"
              [formControl]="tradeTicketService?.tradeTicket.controls.securityAccountType"
              name="securityAccountType"
              type="radio"
              aria-describedby="security-account-type-required-error"
              [value]="securityAccountTypes.MARGIN"
            />
          </c11n-segment>

          <c11n-segment [labelText]="content.labels.cash">
            <input
              c11n-segment-input
              id="securityAccountTypeCash"
              [formControl]="tradeTicketService?.tradeTicket.controls.securityAccountType"
              name="securityAccountType"
              type="radio"
              aria-describedby="security-account-type-required-error"
              [attr.aria-invalid]="tradeTicketService?.controlHasError('securityAccountType')"
              [value]="securityAccountTypes.CASH"
            />
          </c11n-segment>
        </c11n-segmented-control>

        <c11n-hint-error
          [hasError]="tradeTicketService?.controlHasError('securityAccountType')"
          [errorText]="content.validation.securityAccountType"
          [hintErrorId]="'security-account-type-required-error'"
          data-testid="txt-input-validation"
          [ngClass]="{ 'm-0': !tradeTicketService?.controlHasError('securityAccountType') }"
        ></c11n-hint-error>
      </fieldset>
    </ng-template>
  </div>
</ng-container>
