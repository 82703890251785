export enum FeeType {
  PURCHASE_FEE = 'PURCHASE_FEE',
  REDEMPTION_FEE = 'REDEMPTION_FEE',
}

export enum OpenCloseIndicator {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

export enum Duration {
  DAY = 'DAY',
  IMMEDIATE_OR_CANCEL = 'IMMEDIATE_OR_CANCEL',
  GOOD_TILL_DAY = 'GOOD_TILL_DAY',
  SIXTY_DAY = 'SIXTY_DAY',
  EVENING = 'EVENING',
  FILL_OR_KILL = 'FILL_OR_KILL',
}

export enum Category {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
  STOP = 'STOP',
  OR_BETTER = 'OR_BETTER',
  STOP_LIMIT = 'STOP_LIMIT',
  CLOSED = 'CLOSED',
  WITH_OR_WITHOUT = 'WITH_OR_WITHOUT',
  MARKET_ON_CLOSE = 'MARKET_ON_CLOSE',
}

export enum Status {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  ENTERED = 'ENTERED',
  EXPIRED = 'EXPIRED',
  EXECUTED = 'EXECUTED',
  CANCELLED = 'CANCELLED',
  DROPPED = 'DROPPED',
  REJECTED = 'REJECTED',
  PARTIAL_CANCEL = 'PARTIAL_CANCEL',
  PARTIAL_EXECUTION = 'PARTIAL_EXECUTION',
  PENDING_CANCEL = 'PENDING_CANCEL',
  PENDING_CHANGE = 'PENDING_CHANGE',
}

export enum ParentOrderStatus {
  OPEN = 'OPEN',
  ENTERED = 'ENTERED',
  EXPIRED = 'EXPIRED',
  COMPLETE = 'COMPLETE',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export enum RequestedAmountUnit {
  SHARES = 'SHARES',
  DOLLARS = 'DOLLARS',
  PERCENTAGE = 'PERCENTAGE',
  OPTION = 'OPTION',
}

export enum Instruction {
  BUY = 'BUY',
  BUY_MINUS = 'BUY_MINUS',
  BUY_TO_COVER = 'BUY_TO_COVER',
  EXCHANGE = 'EXCHANGE',
  EXCHANGE_ALL = 'EXCHANGE_ALL',
  SELL = 'SELL',
  SELL_TRANSFER = 'SELL_TRANSFER',
  SELL_PLUS = 'SELL_PLUS',
  SELL_SHORT = 'SELL_SHORT',
  SSLOCEX = 'SSLOCEX',
}

export enum SecurityAccountType {
  CASH = 'CASH',
  MARGIN = 'MARGIN',
  SHORT = 'SHORT',
}

export enum SecurityIdType {
  ORDER_SECURITY_TYPE = 'ORDER SECURITY TYPE',
  CUSIP = 'CUSIP',
  SYMBOL = 'SYMBOL',
  SECURITY_TYPE = 'SECURITY TYPE',
  DESCRIPTION = 'DESCRIPTION',
}

export enum CostBasisMethod {
  FIFO = 'FIFO',
  SPEC = 'SPEC',
  AVGC = 'AVGC',
  HIFO = 'HIFO',
}
