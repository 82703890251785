import { HttpErrorResponse } from '@angular/common/http';
import { EcnHours } from '@etfs-equities/models/exchange.model';
import { Action } from '@ngrx/store';
import { SetExtendedHoursAgreementRequestEcnStatusEnum } from '@vanguard/invest-api-client-typescript-axios';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum ExtendedHoursActionTypes {
  LOAD_ECNHOURS = '[EXTENDED HOURS] LOAD ECN HOURS',
  LOAD_ECNHOURS_SUCCESS = '[EXTENDED HOURS] LOAD ECN HOURS SUCCESS',
  LOAD_ECNHOURS_FAILURE = '[EXTENDED HOURS] LOAD ECN HOURS FAILURE',
  ACCEPT_ECN_AGREEMENT = '[EXTENDED HOURS] ACCEPT ECN AGREEMENT',
  ACCEPT_ECN_AGREEMENT_SUCCESS = '[EXTENDED HOURS] ACCEPT ECN AGREEMENT SUCCESS',
  ACCEPT_ECN_AGREEMENT_FAILED = '[EXTENDED HOURS] ACCEPT ECN AGREEMENT FAILED',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class LoadEcnHoursAction implements Action {
  readonly type = ExtendedHoursActionTypes.LOAD_ECNHOURS;
}

export class LoadEcnHoursSuccessAction implements Action {
  readonly type = ExtendedHoursActionTypes.LOAD_ECNHOURS_SUCCESS;
  constructor(public payload: { ecnHours: EcnHours }) {}
}

export class LoadEcnHoursFailure implements Action {
  readonly type = ExtendedHoursActionTypes.LOAD_ECNHOURS_FAILURE;
  constructor(public payload: Error) {}
}

export class AcceptEcnAgreementAction implements Action {
  readonly type = ExtendedHoursActionTypes.ACCEPT_ECN_AGREEMENT;
}

export class AcceptEcnAgreementSuccessAction implements Action {
  readonly type = ExtendedHoursActionTypes.ACCEPT_ECN_AGREEMENT_SUCCESS;
  constructor(public payload: SetExtendedHoursAgreementRequestEcnStatusEnum) {}
}

export class AcceptEcnAgreementFailedAction implements Action {
  readonly type = ExtendedHoursActionTypes.ACCEPT_ECN_AGREEMENT_FAILED;
  constructor(public payload: HttpErrorResponse) {}
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createLoadEcnHours = () => {
  return new LoadEcnHoursAction();
};

export const createLoadEcnHoursSuccess = (ecnHours: EcnHours) => {
  return new LoadEcnHoursSuccessAction({ ecnHours });
};

export const createLoadEcnHoursFailure = (error: Error) => {
  return new LoadEcnHoursFailure(error);
};

export const createAcceptEcnAgreement = () => {
  return new AcceptEcnAgreementAction();
};

export const createAcceptEcnAgreementSuccess = (response: SetExtendedHoursAgreementRequestEcnStatusEnum) => {
  return new AcceptEcnAgreementSuccessAction(response);
};

export const createAcceptEcnAgreementFailed = (response: HttpErrorResponse) => {
  return new AcceptEcnAgreementFailedAction(response);
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type ExtendedHoursActions =
  | LoadEcnHoursAction
  | LoadEcnHoursSuccessAction
  | LoadEcnHoursFailure
  | AcceptEcnAgreementAction
  | AcceptEcnAgreementSuccessAction
  | AcceptEcnAgreementFailedAction;
