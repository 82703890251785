import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, CrewDisclaimer } from '@app/etfs-equities/models';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CrewDisclaimerService {
  //  Public variables...
  crewCanTransact = false;

  constructor(private readonly http: HttpClient, private readonly envService: EnvironmentService) {}

  fetchCrewDisclaimerFlag(accountId: number) {
    return this.http
      .get<ApiResponse<CrewDisclaimer>>(
        this.envService.getApiUrlBaseOnRoute() + `api/crew-disclaimer?accountId=${accountId}`,
        { withCredentials: true }
      )
      .pipe(
        tap((response) => (this.crewCanTransact = response.data.crewCanTransact)),
        catchError((error: HttpErrorResponse) => this.handleHttpError(error))
      );
  }

  private handleHttpError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error);
  }
}
