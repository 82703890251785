import { Injectable } from '@angular/core';
import { SessionCheckService } from '@app/core/services/session-check/session-check.service';
import { EnvironmentService } from '@app/shared/services/environment/environment.service';
import { CookieService } from 'ngx-cookie-service';

import { CrewDisclaimerService } from '../crew-disclaimer/crew-disclaimer.service';

const digit = (spoid: string, i: number): number => {
  const char = spoid.charAt(i);
  return 9 - parseInt(char, 10);
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    readonly cookieService: CookieService,
    readonly crewDisclaimerService: CrewDisclaimerService,
    readonly sessionCheckService: SessionCheckService,
    readonly envService: EnvironmentService
  ) {}

  getSpoid(): string {
    return this.cookieService.get('cl_spoid') || '-1';
  }

  getPoid(): string {
    const spoid = this.getSpoid();

    if (spoid.length !== 10) {
      return spoid;
    }

    const poid = [];

    poid[0] = digit(spoid, 8);
    poid[1] = digit(spoid, 9);
    poid[2] = digit(spoid, 4);
    poid[3] = digit(spoid, 3);
    poid[4] = digit(spoid, 2);
    poid[5] = digit(spoid, 7);
    poid[6] = digit(spoid, 6);
    poid[7] = digit(spoid, 5);
    poid[8] = digit(spoid, 0);
    poid[9] = digit(spoid, 1);

    return poid.join('').replace(/^0+/, '');
  }
}
