import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SessionStorageKeys } from '@app/etfs-equities/enums/session-storage-keys.enums';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private readonly window: Window;

  constructor(@Inject(DOCUMENT) document: Document) {
    this.window = document.defaultView;
  }

  get(key: SessionStorageKeys) {
    return JSON.parse(this.window.sessionStorage.getItem(key));
  }

  set(key: SessionStorageKeys, value: any) {
    this.window.sessionStorage.setItem(key, JSON.stringify(value));
  }
}
