import { Component, OnInit, ViewChild } from '@angular/core';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@app/core/services';
import { TradeHelpIDs } from '@app/etfs-equities/enums';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-trade-help-modal',
  templateUrl: './trade-help-modal.component.html',
  styleUrls: ['./trade-help-modal.component.scss'],
})
export class TradeHelpModalComponent implements OnInit {
  //  Decorators...
  @ViewChild('modal')
  modal: ModalDialogComponent;

  //  Public variables...
  content: JsonContent = content;
  activeItem = TradeHelpIDs.TRADING_POLICIES;

  //  Public observables/subjects...
  dollarBasedTradesEnabled$: Observable<boolean>;

  constructor(private readonly gatekeeperService: GatekeeperService) {}

  ngOnInit() {
    this.dollarBasedTradesEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_DOLLAR_BASED_TRADES
    );
  }

  setOption(option: TradeHelpIDs) {
    this.activeItem = option;
  }

  open(event?: Event) {
    event?.preventDefault();
    this.modal.openModalDialog();
  }
}
