<c11n-modal-dialog
  #costBasisLotLoadErrorModal
  modalId="cost-basis-lot-load-error-modal"
  [headingText]="content.costBasisLotLoadErrorModal.title"
  [primaryButtonLabel]="content.labels.editOrder"
  (closeEvent)="clearLotLoadError()"
  variant="error"
>
  <div c11n-modal-dialog-body>
    <div [innerHTML]="content.costBasisLotLoadErrorModal.body"></div>
  </div>
</c11n-modal-dialog>
