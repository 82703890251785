<ng-container
  *ngIf="{
    principal: (principal$ | async),
    amount: (amount$ | async),
    estimatedShares: (estimatedShares$ | async),
    dollarBasedEnabled: (dollarBasedEnabled$ | async),
    quoteIsVgEtf: (quoteIsVgEtf$ | async),
    isIncapacitated: (isIncapacitated$ | async),
    order: (order$ | async),
    isScreenXSmall: (isScreenXSmall$ | async)
  } as view"
>
  <div class="twe-card">
    <h2 class="twe-card-header">{{ isEditCostBasis ? content.labels.orderDetails : content.labels.tradeDetail }}</h2>

    <div *ngIf="!view.dollarBasedEnabled && !isEditCostBasis" class="c11n-space-stack-3x">
      <div class="list-item">
        {{ content.labels.estimatedPrincipal }}*
        <div class="list-item-data">{{ (view.principal | tweSubDollarCurrency) || '$—' }}</div>
      </div>
      <div class="list-item">
        {{ content.labels.commission }}
        <c11n-chip [labelText]="content.orderDetail.free"> </c11n-chip>
      </div>
      <div class="list-item">
        {{ content.labels.estimatedNetAmount }}*
        <div class="list-item-data">{{ (view.principal | tweSubDollarCurrency) || '$—' }}</div>
      </div>
    </div>

    <div *ngIf="view.dollarBasedEnabled && !isEditCostBasis" class="c11n-space-stack-3x">
      <div class="list-item" data-testid="Commission">
        {{ content.labels.commission }}
        <c11n-chip [labelText]="content.orderDetail.free"> </c11n-chip>
      </div>
      <div class="list-item" data-testid="Shares">
        {{
          view.quoteIsVgEtf && tradeTicketService?.amountTypeIsDollars()
            ? content.labels.estimatedShares
            : content.labels.shares
        }}
        <div class="list-item-data" data-testid="txt-estimated-shares-value"
          >{{ tradeTicketService?.transactionTypeIsSellShort() && view.amount > 0 ? '-' : ''
          }}{{
            ((view.quoteIsVgEtf && tradeTicketService?.amountTypeIsDollars() ? view.estimatedShares : view.amount)
              | number : '1.4-4') || '—'
          }}</div
        >
      </div>
      <div class="amount-row">
        {{
          view.quoteIsVgEtf && tradeTicketService?.amountTypeIsDollars()
            ? content.labels.amount
            : content.labels.estimatedAmount
        }}
        <div class="list-item-data">{{
          ((view.quoteIsVgEtf && tradeTicketService?.amountTypeIsDollars() ? view.amount : view.principal)
            | tweSubDollarCurrency) || '$—'
        }}</div>
      </div>
    </div>

    <div *ngIf="isEditCostBasis" class="c11n-space-stack-3x">
      <div class="amount-row no-border">
        {{ content.labels.executedAmount }}
        <div class="list-item-data">{{
          (view.order?.executedPrice * view.order?.shares | tweSubDollarCurrency) || '$—'
        }}</div>
      </div>
    </div>

    <div aria-live="polite">
      <twe-order-validation-banner></twe-order-validation-banner>
      <twe-margin-requirements-vg-etfs-banner></twe-margin-requirements-vg-etfs-banner>
    </div>
    <div class="twe-flex-button-wrap" [ngClass]="{ 'twe-flex-button-wrap__column-reverse-lg': isChangeOrder }">
      <c11n-button
        [attr.data-testid]="isChangeOrder ? 'btn-trade-keep-order' : 'btn-trade-clear-ticket'"
        class="twe-flex-button-wrap__button"
        buttonType="button"
        size="medium"
        variant="secondary"
        [fullWidth]="isChangeOrder || view.isScreenXSmall"
        [labelText]="isChangeOrder ? content.labels.keepOrder : content.labels.clearTicket"
        [isInvalid]="view.isIncapacitated"
        (clickEvent)="handleActionBtnClick()"
      ></c11n-button>

      <c11n-button
        #previewButton
        class="twe-flex-button-wrap__button"
        data-testid="btn-trade-preview-order"
        buttonType="button"
        size="medium"
        variant="primary"
        [fullWidth]="isChangeOrder || view.isScreenXSmall"
        [labelText]="
          tradeTicketService?.specIdIsSelected()
            ? content.labels.selectShares
            : isChangeOrder
            ? content.labels.previewChangeOrder
            : content.labels.previewOrder
        "
        [isWaiting]="orderService?.isValidating || costBasisService.isLoadingLots"
        [isInvalid]="view.isIncapacitated"
        (clickEvent)="handlePreviewBtnClick()"
      ></c11n-button>
    </div>
  </div>
</ng-container>
