import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { selectQuote } from '@app/etfs-equities/store/selectors/quote/quote.selectors';
import { selectTradeTicket } from '@app/etfs-equities/store/selectors/trade-ticket/trade-ticket.selectors';
import { TayneState } from '@app/etfs-equities/store/states/tayne.state';
import { OrderUtil, SharesUtil } from '@etfs-equities/utils';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EstimatedSharesValidator implements AsyncValidator {
  constructor(private readonly store: Store<TayneState>) {}

  validate(control: AbstractControl): Observable<ValidationErrors> {
    return this.store.pipe(
      withLatestFrom(this.store.pipe(select(selectTradeTicket)), this.store.pipe(select(selectQuote))),
      take(1),
      map(([_action, ticket, quote]) => {
        const order = OrderUtil.makeFromTradeTicket(ticket);
        order.shares = control.value;
        return SharesUtil.calculateShares(order, quote) >= CONSTANTS.MAX_ESTIMATED_SHARES
          ? { sharesLimitExceeded: true }
          : null;
      })
    );
  }
}
