import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const numericValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.value || control.value === '.') {
      return null;
    }

    if (isNaN(control.value)) {
      return { numeric: { value: control.value } };
    }

    return null;
  };
};
