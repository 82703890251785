<c11n-modal-dialog
  modalId="triggered-rule-alert-modal"
  #alertsModal
  [headingText]="content.alertsModal.title"
  [primaryButtonLabel]="content.labels.continue"
  (primaryClick)="alertsModal.closeDialogModal($event); acceptTriggeredRules()"
  [secondaryButtonLabel]="content.labels.editOrder"
  (secondaryClick)="alertsModal.closeDialogModal($event); edit()"
  variant="warning"
>
  <div c11n-modal-dialog-body>
    <twe-rule-content [triggeredRules]="triggeredRules"></twe-rule-content>
  </div>
</c11n-modal-dialog>

<c11n-modal-dialog
  modalId="triggered-rule-error-modal"
  #errorsModal
  [headingText]="content.errorModal.title"
  [primaryButtonLabel]="shouldSendToOrderStatusPage ? content?.labels.goToOrderStatus : content.labels.editOrder"
  (primaryClick)="errorsModal.closeDialogModal($event); shouldSendToOrderStatusPage ? navigateToOrderStatus() : edit()"
  variant="error"
>
  <div c11n-modal-dialog-body>
    <twe-rule-content [triggeredRules]="triggeredRules"></twe-rule-content>
  </div>
</c11n-modal-dialog>
