import { Component, Input, ViewChild } from '@angular/core';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';

@Component({
  selector: 'twe-generic-tooltip-modal',
  templateUrl: './generic-tooltip-modal.component.html',
  styleUrls: ['./generic-tooltip-modal.component.scss'],
})
export class GenericTooltipModalComponent {
  @ViewChild('modal')
  modal: ModalDialogComponent;

  @Input() headingText: string;
}
