import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@app/core/services';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

export const LOCATION_TOKEN = new InjectionToken<Location>('Window Location Object');

@Injectable()
export class EnvironmentService {
  readonly ENVIRONMENT_URL = environment.apiUrl + 'api/environment';
  subject: BehaviorSubject<any>;
  apiUrl = environment.apiUrl;

  isInactiveAPI = null;

  constructor(
    private readonly http: HttpClient,
    @Inject(LOCATION_TOKEN) private readonly location: Location,
    private readonly cookieService: CookieService,
    private readonly gatekeeperService: GatekeeperService
  ) {
    this.subject = new BehaviorSubject(this.getEnvironmentFromMidtier());
  }

  public getEnvironment(): Observable<any> {
    return this.subject.value;
  }

  // Get the apiUrl based on the route
  public getApiUrlBaseOnRoute(): string {
    let apiUrl: string = null;

    if (this.location?.href?.toLowerCase().indexOf('api-inactive.ecs.us-east-1') > -1 || this.isInactiveAPI) {
      apiUrl = environment.apiInactiveUrlUsEast1;
    } else if (this.location?.href?.toLowerCase().indexOf('api-inactive.ecs.us-west-2') > -1) {
      apiUrl = environment.apiInactiveUrlUsWest2;
    } else {
      apiUrl = this.apiUrl;
    }

    return apiUrl;
  }

  public isLocalEnvironment(): boolean {
    return environment.deployment === 'local';
  }

  public isServerlessEnvironment(): boolean {
    return this.location?.origin === environment.serverlessUrl;
  }

  public async setIsInactive(): Promise<void> {
    const testServerlessCookie = this.cookieService.get(CONSTANTS.SERVERLESS_TEST_COOKIE);

    if (testServerlessCookie && this.isServerlessEnvironment()) {
      this.isInactiveAPI = await lastValueFrom(
        this.gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_SERVERLESS_INACTIVE_API)
      );
    }

    return Promise.resolve();
  }

  private getEnvironmentFromMidtier(): Observable<any> {
    return this.http.get<any>(this.ENVIRONMENT_URL, { withCredentials: true }).pipe(shareReplay());
  }
}
