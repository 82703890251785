import { ChangeOrderActionTypes, ChangeOrderTypesUnion } from '../../actions/change-order/change-order.actions';
import { ChangeOrderState, initialChangeOrderState } from '../../states/change-order.state';

export const changeOrderReducer = (
  state: ChangeOrderState = initialChangeOrderState,
  action: ChangeOrderTypesUnion
): ChangeOrderState => {
  if (action.type === ChangeOrderActionTypes.SET_ISCHANGEORDER) {
    return { ...state, isChangeOrder: action.payload };
  }
  return state;
};
