import { FundsAvailableActionTypes, FundsAvailableActionTypesUnion } from '../../actions';
import { FundsAvailableState, initialFundsAvailableState } from '../../states';

export const fundsAvailableReducer = (
  state: FundsAvailableState = initialFundsAvailableState,
  action: FundsAvailableActionTypesUnion
): FundsAvailableState => {
  switch (action.type) {
    case FundsAvailableActionTypes.LOAD_FUNDS_AVAILABLE_SUCCESS:
      return {
        ...state,
        fundsAvailable: action.payload,
      };
    case FundsAvailableActionTypes.LOADING_FUNDS_AVAILABLE:
      return {
        ...state,
        isLoading: action.payload,
      };
    case FundsAvailableActionTypes.LOADING_FUNDS_AVAILABLE_FAILED:
      return {
        ...state,
        loadingFailed: action.payload,
      };
    case FundsAvailableActionTypes.FUNDS_AVAILABLE_PANEL_TOGGLE:
      return {
        ...state,
        panelIsOpen: action.payload,
      };
    default:
      return state;
  }
};
