<ng-container *ngIf="{ quote: (quote$ | async) } as view">
  <c11n-modal-dialog
    role="dialog"
    #dollarsToSharesModal
    [ariaLabel]="content.dollarsToSharesModal.title"
    modalId="dollarsToSharesModal"
    [headingText]="content.dollarsToSharesModal.title"
    [primaryButtonLabel]="content.dollarsToSharesModal.confirmButton"
    [secondaryButtonLabel]="content.dollarsToSharesModal.cancelButton"
    (primaryClick)="
      useCalculatedShares();
      adobeService.sendAdobeTrackingOnClick('Use Shares Amount', 'button', 'dollarsharecalculator')
    "
    (secondaryClick)="close(); adobeService.sendAdobeTrackingOnClick('Cancel', 'button', 'dollarsharecalculator')"
    (closeEvent)="close()"
  >
    <div c11n-modal-dialog-body>
      <div class="c11n-space-stack-4x">
        <div
          class="c11n-text-sm c11n-space-stack-1x twe-text-bold twe-c11n-color-ink-800"
          data-testid="txt-product-name"
        >
          {{ (view.quote | tweQuoteTickerLongName) || '&mdash;' }}
        </div>

        <div class="c11n-text-md c11n-space-stack-2x twe-c11n-color-ink-800" data-testid="txt-last-price">
          {{ lastTradePrice | currency }}
        </div>
        <div class="c11n-text-xs text-uppercase twe-c11n-color-ink-400" data-testid="txt-last-trade-timestamp">
          {{ content.dollarsToSharesModal.priceAsOfLastTrade }} {{ view.quote?.lastTradeDate | tweDate }}
        </div>
      </div>

      <div class="d2s-form row c11n-space-stack-2x" [formGroup]="form">
        <div class="d2s-form__control col-sm-6">
          <label c11n-label for="dollarsToSharesDollarAmount">{{
            content.dollarsToSharesModal.textInputDollarAmountLabel
          }}</label>
          <c11n-input size="medium" [hasError]="form.controls.dollars.hasError('pattern')">
            <input
              c11nInput
              type="text"
              inputmode="decimal"
              id="dollarsToSharesDollarAmount"
              name="dollarsToSharesDollarAmount"
              [imask]="inputMaskDollars"
              [unmask]="true"
              formControlName="dollars"
              (keydown.enter)="convertDollarsToShares()"
              (blur)="convertDollarsToShares()"
              [attr.aria-label]="content.dollarsToSharesModal.textInputDollarAmountAriaLabel"
              [attr.aria-describedby]="'dollars-description'"
            />
          </c11n-input>
          <c11n-hint-error
            [hasError]="form.controls.dollars.hasError('pattern')"
            [errorText]="content.validation.invalidChars"
            [hintErrorId]="'dollars-description'"
            [ngClass]="{ 'm-0': !form.controls.dollars.hasError('pattern') }"
          ></c11n-hint-error>
        </div>

        <div class="d2s-form__control col-sm-6">
          <label c11n-label for="dollarsToSharesEstimatedShares">{{ content.labels.estimatedSharesCustom }}</label>
          <c11n-input size="medium">
            <input
              c11nInput
              type="text"
              inputmode="decimal"
              id="dollarsToSharesEstimatedShares"
              name="dollarsToSharesEstimatedShares"
              [imask]="inputMaskInteger"
              [unmask]="true"
              [formControl]="form.controls.shares"
              (keydown.enter)="convertSharesToDollars()"
              (blur)="convertSharesToDollars()"
            />
          </c11n-input>
        </div>
      </div>
      <c11n-details
        [expandLabelText]="content.dollarsToSharesModal.showDisclaimer"
        [collapseLabelText]="content.dollarsToSharesModal.hideDisclaimer"
        labelWeight="normal"
      >
        <div class="c11n-color-ink-400 c11n-text-sm" [innerHTML]="content.dollarsToSharesModal.disclaimer"></div>
      </c11n-details>
    </div>
  </c11n-modal-dialog>
</ng-container>
