<ng-container [formGroup]="parentGroup">
  <div>
    <label c11n-label [for]="controlName"> {{ controlLabel }}</label>
    <c11n-input size="medium" [hasError]="hasError">
      <input
        c11nInput
        type="text"
        inputmode="decimal"
        [id]="controlName"
        [name]="controlName"
        [imask]="valueIsLessThanOne ? fourDecimalsCurrency : twoDecimalsCurrency"
        [unmask]="true"
        [formControlName]="controlName"
        [attr.aria-describedby]="controlName + '-description'"
        (keyup.enter)="emitBlur($event)"
      />
    </c11n-input>
    <c11n-hint-error
      [hasError]="hasError"
      [errorText]="getErrorText()"
      [hintErrorId]="controlName + '-description'"
      [ngClass]="{ 'm-0': !hasError }"
    ></c11n-hint-error>
  </div>
</ng-container>
