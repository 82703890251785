/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { AdobeAnalyticsService, GatekeeperService } from '@app/core/services';
import { CONSTANTS } from '@app/etfs-equities/constants';
import {
  CashMarginIndicatorTypes,
  OrderEnums,
  SecurityClassTypes,
  SymbolRetrievalType,
} from '@app/etfs-equities/enums';
import { Account, JsonContent, OpenOrder, OpenOrderExtended } from '@app/etfs-equities/models';
import { SubDollarCurrency } from '@app/etfs-equities/pipes';
import { WindowService } from '@app/etfs-equities/services';
import { OrderUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { selectCostBasisEligible, selectSelectedAccount, TayneState } from '@etfs-equities/store';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';

import {
  TweOpenOrdersOrderCategoryTypesEnum,
  TweOpenOrdersOrderDurationTypesEnum,
  TweOpenOrdersOrderInstructionCodeEnum,
  TweOpenOrdersOrderStatusCodeTypesEnum,
  TweOpenOrdersOrderStatusTypesEnum,
} from '../../enums/open-orders/open-orders.enum';

@Component({
  selector: 'twe-open-orders-component',
  templateUrl: './open-orders.component.html',
  styleUrls: ['./open-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenOrdersComponent implements OnInit, OnDestroy {
  //  Decorators...
  @Input() selectedAccount: Account.Account;

  @Input() set openOrders(value: OpenOrder[]) {
    this.openOrdersExtended = value
      ?.map((openOrder) => ({
        ...openOrder,
        orderStatusDisplayText: OrderUtil.orderStatusDisplayText(openOrder),
      }))
      ?.map((openOrder) => ({
        ...openOrder,
        orderColor: OrderUtil.orderColor(openOrder),
      }));
  }

  @Input() isLoading: boolean;

  @Input() orderRetrievalFailed: boolean;

  @Output() resetForm = new EventEmitter<void>();

  openOrdersExtended: OpenOrderExtended[];

  readonly TRADE_PATH = CONSTANTS.TRADE_PATH;

  //  Public variables...

  content: JsonContent = content;

  changeOrderEnabled$: Observable<boolean>;
  orderCancelEnabled$: Observable<boolean>;
  isEditCostBasisToggleEnabled$: Observable<boolean>;
  isCostBasisEligible$: Observable<boolean>;
  extendedTradingEnabled$: Observable<boolean>;
  selectedAccount$: Observable<Account.Account>;
  unsubscribe$ = new Subject<void>();

  amountIndicators = OrderEnums.AmountIndicators;
  securityClassTypes = SecurityClassTypes;

  statusDetailEnum = TweOpenOrdersOrderStatusTypesEnum;
  instructionCodeEnum = TweOpenOrdersOrderInstructionCodeEnum;
  orderDurationEnum = TweOpenOrdersOrderDurationTypesEnum;
  orderCategoryEnum = TweOpenOrdersOrderCategoryTypesEnum;

  orderCategoryLabel: JsonContent = {
    LMT: content.labels.limit,
    MKT: content.labels.market,
    STL: content.labels.stopLimit,
    STP: content.labels.stop,
  };

  orderInstructionCodeLabel: JsonContent = {
    SEL: content.labels.sell,
    BUY: content.labels.buy,
    BUYC: content.labels.buyToCover,
    SHOR: content.labels.sellShort,
  };

  securityIsEquity: string[] = [
    SecurityClassTypes.COMMON_STOCK.toString(),
    SecurityClassTypes.PREFERRED_STOCK.toString(),
    SecurityClassTypes.MISCELLANEOUS_STOCK.toString(),
    SecurityClassTypes.CONVERTIBLE_PREFERRED_STOCK.toString(),
    SecurityClassTypes.ETF.toString(),
    SecurityClassTypes.VANGUARD_ETF.toString(),
    SecurityClassTypes.RIGHTS.toString(),
    SecurityClassTypes.WARRANT.toString(),
    SecurityClassTypes.UNITS.toString(),
  ];

  orderStatusLink = environment.secureSiteUrls.orderStatus;

  isMarginAccount: boolean;

  constructor(
    public readonly tweCurrencyPipe: SubDollarCurrency,
    public readonly adobeService: AdobeAnalyticsService,
    public readonly windowService: WindowService,
    private readonly router: Router,
    private readonly store: Store<TayneState>,
    private readonly gatekeeperService: GatekeeperService
  ) {}

  ngOnInit() {
    this.isCostBasisEligible$ = this.store.pipe(select(selectCostBasisEligible));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));

    this.changeOrderEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_CHANGE_ORDER);
    this.extendedTradingEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TOGGLE_EXTENDED_TRADING
    );
    this.isEditCostBasisToggleEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_EDIT_COST_BASIS
    );
    this.orderCancelEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_CANCEL_ORDER);

    this.watchForSelectedAccount();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private watchForSelectedAccount(): void {
    this.selectedAccount$.pipe(takeUntil(this.unsubscribe$)).subscribe((account) => {
      this.isMarginAccount = account?.marginCode === CashMarginIndicatorTypes.MARGIN;
    });
  }

  showNumberOfSharesOrDollars(order: OpenOrder): string {
    let amount: string;
    if (
      this.securityIsEquity.includes(order.securityType) ||
      (order.orderSecurityType === 'MUTUAL_FUND' && order.amountIndicator !== 'DOLS')
    ) {
      if (
        order.statusDetail === this.statusDetailEnum.EXECUTED ||
        order.statusDetail === this.statusDetailEnum.PARTIAL_EXECUTION
      ) {
        amount = Number(order.executedShareQuantity).toFixed(4);
      } else {
        if (order.amountIndicator === this.amountIndicators.DOLLARS) {
          amount = ' - ';
        } else {
          amount = Number(order.originalShareQuantity).toFixed(4);
        }
      }
    } else {
      amount = this.tweCurrencyPipe.transform(order.orderValue);
    }
    return amount;
  }

  handleActionBtnClick(order: OpenOrder, action: 'change' | 'cancel' | 'editCostBasisMethod') {
    const { accountId, orderNumber: orderId } = order;
    let url: string;
    switch (action) {
      case 'change':
        url = CONSTANTS.TRADE_PATH;
        break;
      case 'cancel':
        url = CONSTANTS.CANCEL_PATH;
        break;
      case 'editCostBasisMethod':
        url = CONSTANTS.EDIT_COST_BASIS_PATH;
        break;
    }

    // Reset the form to clear any previous data
    this.resetForm.emit();

    this.router.navigate([url], {
      queryParams: {
        accountId,
        orderId,
      },
    });
  }

  showChangerOrderButton(
    order: OpenOrder,
    isChangeOrderToggleEnabled: boolean,
    isExtendedTradingEnabled: boolean
  ): boolean {
    const { amountIndicator, orderDuration, statusDetail, orderStatusCode } = order;
    return (
      isChangeOrderToggleEnabled &&
      (orderDuration !== this.orderDurationEnum.EVENING || isExtendedTradingEnabled) &&
      OrderUtil.isStatusChangeOrCancelEligible(statusDetail) &&
      amountIndicator !== this.amountIndicators.DOLLARS &&
      !OrderUtil.isCompletedPartial(order) &&
      orderStatusCode !== TweOpenOrdersOrderStatusCodeTypesEnum.ENTERED &&
      orderStatusCode !== TweOpenOrdersOrderStatusCodeTypesEnum.PENDING &&
      !OrderUtil.isPaperTradeOrTTSOrder(order)
    );
  }

  showCancelOrderButton(order: OpenOrder, isCancelOrderToggleEnabled: boolean): boolean {
    const { statusDetail } = order;
    return (
      isCancelOrderToggleEnabled &&
      (OrderUtil.isStatusChangeOrCancelEligible(statusDetail) ||
        statusDetail === this.statusDetailEnum.PENDING_CHANGE) &&
      !OrderUtil.isCompletedPartial(order) &&
      !OrderUtil.isPaperTradeOrTTSOrder(order)
    );
  }

  showEditCostBasisButton(
    order: OpenOrder,
    isEditCostBasisToggleEnabled: boolean,
    isEditCostBasisEligible: boolean
  ): boolean {
    const { orderInstructionCode, statusDetail } = order;
    return (
      isEditCostBasisEligible &&
      isEditCostBasisToggleEnabled &&
      this.securityIsEquity.includes(order.securityType) &&
      (orderInstructionCode === this.instructionCodeEnum.SELL ||
        orderInstructionCode === this.instructionCodeEnum.BUY_TO_COVER) &&
      (statusDetail === this.statusDetailEnum.EXECUTED ||
        statusDetail === this.statusDetailEnum.PARTIAL_CANCEL ||
        OrderUtil.isCompletedPartial(order)) &&
      !OrderUtil.isPaperTradeOrTTSOrder(order)
    );
  }

  getNonEquitySecurityTypeText(order: OpenOrder) {
    if (order.orderSecurityType === SymbolRetrievalType.MUTUAL_FUND) {
      return content.openOrders.mutualFund;
    }

    switch (order.securityType) {
      case SecurityClassTypes.MUTUAL_FUND.toString():
        return content.openOrders.mutualFund;
      case SecurityClassTypes.CALL_OPTION.toString():
      case SecurityClassTypes.PUT_OPTION.toString():
        return content.openOrders.options;
      case SecurityClassTypes.CORPORATE_BOND.toString():
      case SecurityClassTypes.MUNICIPAL_BOND.toString():
      case SecurityClassTypes.US_TREASURY_BILL.toString():
      case SecurityClassTypes.US_TREASURY_NOTE.toString():
      case SecurityClassTypes.US_TREASURY_BOND.toString():
      case SecurityClassTypes.OTHER_GOVERNMENT.toString():
      case SecurityClassTypes.US_TREASURY_ZERO_COUPON.toString():
      case SecurityClassTypes.GOVERNMENT_MORTGAGE.toString():
      case SecurityClassTypes.CERTIFICATES_OF_DEPOSIT.toString():
      case SecurityClassTypes.MISCELLANEOUS_FIXED_INCOME.toString():
        return content.openOrders.fixedIncome;
      default:
        return '';
    }
  }
}
