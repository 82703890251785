import { Component, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import { WindowService } from '@app/etfs-equities/services';
import content from '@content/content.json';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';

@Component({
  selector: 'twe-how-to-read-a-quote-modal',
  templateUrl: './how-to-read-a-quote-modal.component.html',
  styleUrls: ['./how-to-read-a-quote-modal.component.scss'],
})
export class HowToReadAQuoteModalComponent {
  //  Decorators...
  @ViewChild('modal', { static: false })
  modal: ModalDialogComponent;

  //  Public variables...
  content: JsonContent = content;

  constructor(public readonly windowService: WindowService) {}

  open(event?: Event) {
    event?.preventDefault();
    this.modal.openModalDialog();
  }
}
