<div class="container disclosure-container">
  <div class="c11n-space-stack-6x">
    <h3 class="c11n-text-2xl-headline c11n-space-stack-3x"> {{ content.extendedTrading.disclosureTitle }} </h3>
    <p class="c11n-text-lg">
      {{ content.extendedTrading.disclosureSubtitle }}
    </p>
  </div>
  <div class="disclosure-card c11n-elevation-800 c11n-text-md c11n-space-stack-3x">
    <div class="d-flex flex-column flex-lg-row">
      <span class="col-lg-1 col-12 d-flex justify-content-center mb-3 mb-lg-0">
        <c11n-icon name="warning-fill-red" size="medium"></c11n-icon>
      </span>
      <div class="col-lg-11 col-12">
        <p class="c11n-space-stack-3x">
          {{ content.extendedTrading.disclosureConsiderationText }}
        </p>
        <ul>
          <li [innerHTML]="content.extendedTrading.disclosureRisksText" class="c11n-space-stack-3x"> </li>
          <li [innerHTML]="content.extendedTrading.disclosureLimitOrdersText" class="c11n-space-stack-3x"> </li>
          <li [innerHTML]="content.extendedTrading.disclosureAvailableSecuritiesText" class="c11n-space-stack-3x"></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center c11n-space-stack-3x">
    <p class="text-center">{{ content.extendedTrading.disclosureButtonDescriptionText }}</p>
    <c11n-button
      [variant]="'primary'"
      [labelText]="content.extendedTrading.disclosureButtonLabel"
      [buttonType]="'button'"
      (clickEvent)="extendedHoursDisclosureModal.modal.openModalDialog()"
    ></c11n-button>
  </div>
</div>

<twe-extended-hours-disclosure-modal #extendedHoursDisclosureModal></twe-extended-hours-disclosure-modal>
