import { Component, OnInit } from '@angular/core';
import { OrderEnums } from '@app/etfs-equities/enums';
import { JsonContent } from '@app/etfs-equities/models';
import { TradeTicketService } from '@app/etfs-equities/services';
import { selectOrderType } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs';

@Component({
  selector: 'twe-order-type-limit-only',
  templateUrl: './order-type-limit-only.component.html',
  styleUrl: './order-type-limit-only.component.scss',
})
export class OrderTypeLimitOnlyComponent implements OnInit {
  //  Public variables...
  content: JsonContent | undefined = content;

  constructor(public readonly tradeTicketService: TradeTicketService, private readonly store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(selectOrderType), take(1)).subscribe((type) => {
      if (type !== OrderEnums.Types.LIMIT) {
        this.tradeTicketService.setOrderType(OrderEnums.Types.LIMIT);
      }
    });
  }
}
