<c11n-modal-dialog #modal [headingText]="content?.specIdModal.header">
  <div c11n-modal-dialog-body>
    <p class="c11n-text-md-bold">{{ content.specIdModal.title }}</p>
    <p class="c11n-text-sm" [innerHtml]="content?.specIdModal.body1"></p>
    <p class="c11n-text-sm" [innerHtml]="content?.specIdModal.body2"></p>
    <p class="c11n-text-sm" [innerHtml]="content?.specIdModal.body3"></p>
    <p class="c11n-text-md-bold">{{ content?.specIdModal.costBasis }}</p>

    <dl class="c11n-list">
      <div class="py-3">
        <dt class="c11n-text-xs twe-c11n-color-ink-400">{{ content?.specIdModal.term }} </dt>
        <dd class="c11n-text-xs text-start twe-c11n-color-ink-400">{{ content?.specIdModal.description }} </dd>
      </div>
      <div *ngFor="let costBasisTerm of content.specIdModal.costbasisTerms">
        <dt class="c11n-text-sm-bold">{{ costBasisTerm.header }}</dt>
        <dd class="c11n-text-sm text-start">{{ costBasisTerm.description }}</dd>
      </div>
    </dl>

    <p class="c11n-text-sm twe-c11n-color-ink-400" [innerHtml]="content.specIdModal.note"> </p>
  </div>
</c11n-modal-dialog>
