import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import { AccountService } from '@app/etfs-equities/services';
import content from '@content/content.json';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'twe-accounts-error',
  templateUrl: './accounts-error.component.html',
  styleUrls: ['./accounts-error.component.scss'],
})
export class AccountsErrorComponent implements OnInit, OnDestroy {
  @Input() showAsPage = true;
  @Output() hasAccountRetrievalErrorEmitter = new EventEmitter();

  public content: JsonContent = content;
  public hasAccountRetrievalError = false;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(public accountService: AccountService) {}

  ngOnInit() {
    this.watchForAccountRetrievalError();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Clears the error flag and emits a request to retry loading accounts
   */
  retry() {
    this.hasAccountRetrievalError = false;
    this.hasAccountRetrievalErrorEmitter.emit(false);
    this.accountService.accountRetrievalRetry$.next();
  }

  /**
   * Waits for a notification of an accounts retrieval error and sets the error flag
   */
  private watchForAccountRetrievalError() {
    this.accountService.accountRetrievalError$
      .pipe(
        tap(() => {
          this.hasAccountRetrievalError = true;
          this.hasAccountRetrievalErrorEmitter.emit(true);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
