import { DOCUMENT } from '@angular/common';
import { Directive, Inject, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appLoadScript]',
})
export class LoadScriptDirective implements OnInit {
  @Input() srcUrl = '';
  @Input() timeout = 0;

  constructor(private readonly render: Renderer2, @Inject(DOCUMENT) public readonly $document: any) {}

  ngOnInit(): void {
    // timeout needs to postpone load script to the end of event loop or wait for timeout if it was provided
    setTimeout(() => {
      if (this.srcUrl && !this.isDuplicatedScript()) {
        this.render.appendChild(this.$document.body, this.buildScript());
      }
    }, this.timeout);
  }

  /**
   * Method checks if script element with such url already was loaded on page
   * @returns boolean value
   *
   */
  isDuplicatedScript(): boolean {
    return !!this.$document.body.querySelector(`script[src="${this.srcUrl}"]`);
  }

  /**
   * Builder for script element
   * @returns Node of script element
   *
   */
  private buildScript(): Node {
    const script = this.render.createElement('script');
    script.type = 'application/javascript';
    script.src = this.srcUrl;

    return script;
  }
}
