import { Injectable } from '@angular/core';
import { HeadersUtil } from '@app/etfs-equities/utils/header/header.util';
import { CONSTANTS } from '@etfs-equities/constants';
import { selectEcnHours } from '@etfs-equities/store';
import { DateAndTimeUtil } from '@etfs-equities/utils';
import { Store } from '@ngrx/store';
import {
  AccountApi,
  ExtendedHoursAgreementResponse,
  SetExtendedHoursAgreementRequestEcnStatusEnum,
} from '@vanguard/invest-api-client-typescript-axios';
import { AxiosResponse } from 'axios';
import { finalize, from, map, Observable, Subject, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExtendedHoursService {
  // Public variables...
  isLoading = false;

  // public observables/subjects...
  showWarning$ = new Subject<number>();
  showExpired$ = new Subject<void>();

  // private variables...
  private warningSubscription: Subscription;
  private expiredSubscription: Subscription;

  constructor(private readonly accountApi: AccountApi, private store: Store) {}

  getExtendedHoursAgreement(): Observable<ExtendedHoursAgreementResponse> {
    this.isLoading = true;

    return from(this.accountApi.getExtendedHoursAgreement()).pipe(
      map((response: AxiosResponse<ExtendedHoursAgreementResponse>) => response.data),
      finalize(() => (this.isLoading = false))
    );
  }

  // this service returns a 400 if the client has already accepted or declined the agreement
  acceptExtendedHoursAgreement(): Observable<string> {
    return from(
      this.accountApi.setExtendedHoursAgreement(
        { ecnStatus: SetExtendedHoursAgreementRequestEcnStatusEnum.ACPT },
        HeadersUtil.showLoadingHeaders()
      )
    ).pipe(map((response: AxiosResponse<string>) => response.data));
  }

  setupExtendedHoursTimers() {
    const MSECS_PER_MIN = 1000 * 60;

    this.store
      .select(selectEcnHours)
      .pipe(take(1))
      .subscribe((ecnHours) => {
        const msecsRemaining = DateAndTimeUtil.timeRemaining(ecnHours); // how long before EH expires
        let minutesRemainingAfterWarning = CONSTANTS.EXTENDED_HOURS_WARNING_TIMEOUT; // minutes between warning and expiration
        let msecsUntilWarning = msecsRemaining - minutesRemainingAfterWarning * MSECS_PER_MIN; // how long before we show warning

        if (msecsRemaining >= 0) {
          if (msecsUntilWarning < 0) {
            // we are already within the warning window. We need to show the warning now for the number of minutes remaining
            // (rounded up) and determine the number of whole minutes left
            msecsUntilWarning = msecsRemaining % MSECS_PER_MIN;
            minutesRemainingAfterWarning = msecsRemaining / MSECS_PER_MIN;
            this.showWarning$.next(Math.ceil(minutesRemainingAfterWarning));
            minutesRemainingAfterWarning = Math.trunc(minutesRemainingAfterWarning);
          }

          // set up a timer to go off every minute, so we can inform the user of the number of minutes remaining
          this.warningSubscription = timer(msecsUntilWarning, MSECS_PER_MIN).subscribe((counter) => {
            this.showWarning$.next(minutesRemainingAfterWarning - counter);
          });

          // set up a timer to go off when extended hours has expired
          this.expiredSubscription = timer(msecsRemaining).subscribe(() => {
            this.showExpired$.next();
          });
        }
      });
  }

  cancelExtendedHoursTimers() {
    this.warningSubscription?.unsubscribe();
    this.expiredSubscription?.unsubscribe();
  }

  // Checks if the current time is within the specified ECN Hours interval
  isWithinEcnHours(): Observable<boolean> {
    return this.store.select(selectEcnHours).pipe(map((ecnHours) => DateAndTimeUtil.isWithinSpecifiedHours(ecnHours)));
  }
}
