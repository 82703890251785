import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';
import { TriggeredTradingRule } from '@etfs-equities/models/order-cancel';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';

@Component({
  selector: 'twe-cancel-warning-modal',
  templateUrl: './cancel-warning-modal.component.html',
  styleUrls: ['./cancel-warning-modal.component.scss'],
})
export class CancelWarningModalComponent {
  //  Decorators...

  @Input() triggeredRules: TriggeredTradingRule[];
  @Input() isSubmissionWithTriggeredRules: boolean;

  @ViewChild('cancelWarningModal') cancelWarningModal: ModalDialogComponent;

  @Output() cancelOrder = new EventEmitter();

  //  Public variables...
  content: JsonContent = content;

  close() {
    this.cancelWarningModal.closeDialogModal();
  }

  open() {
    this.cancelWarningModal.openModalDialog();
  }
}
