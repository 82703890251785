import { Component, Input } from '@angular/core';
import content from '@content/content.json';
import { JsonContent } from '@etfs-equities/models';

@Component({
  selector: 'twe-alert-info',
  templateUrl: './alert-info.component.html',
  styleUrls: ['./alert-info.component.scss'],
})
export class AlertInfoComponent {
  content: JsonContent = content;

  @Input()
  heading: string;

  @Input()
  bodyText: string[];
}
