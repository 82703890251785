import { CONSTANTS } from '@etfs-equities/constants';

export class FocusUtil {
  static focusElement: HTMLElement = null;

  /**
   * Set the focus element to the tooltip button element in the tooltip
   * @param event - the triggering event (mouse click inside the tooltip)
   */
  static setTooltipFocusElement(event: Event) {
    if (!!event) {
      FocusUtil.focusElement = (event.target as HTMLElement)
        ?.closest('twe-tooltip')
        ?.getElementsByClassName(CONSTANTS.C11N_TOOLTIP_TRIGGER_CLASS)[0] as HTMLElement;
    }
  }

  /**
   * Sets browser focus to the focusElement
   */
  static setFocus() {
    if (!!FocusUtil.focusElement) {
      // TODO: setTimeout is a workaround until the OrderType modal is converted to c11n (the TDS version also sets focus;
      // the setTimeout here ensures that this call follows the TDS call)
      setTimeout(() => {
        FocusUtil.focusElement.focus();
        FocusUtil.focusElement = null;
      });
    }
  }
}
