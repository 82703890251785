<c11n-modal-dialog
  #modal
  modalId="no-changes-error-modal"
  [headingText]="content?.changeOrder?.noChangesErrorModal?.title"
  [primaryButtonLabel]="content?.labels?.ok"
  (primaryClick)="modal.closeDialogModal()"
>
  <div c11n-modal-dialog-body>
    <p class="c11n-text-md">
      {{ content?.changeOrder?.noChangesErrorModal?.body }}
    </p>
  </div>
</c11n-modal-dialog>
