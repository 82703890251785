import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  brokerageExchangeAgreementGuard,
  gatekeeperGuardActivateFn,
  gatekeeperGuardCanMatchFn,
  serverlessRouteGuard,
} from '@app/core/guards';

import { gatekeeperResolver } from './etfs-equities/resolvers/gatekeeper/gatekeeper.resolver';

const routes: Routes = [
  {
    path: 'trade',
    loadChildren: () => import('./etfs-equities/etfs-equities.module').then((m) => m.EtfsEquitiesModule),
    canMatch: [gatekeeperGuardCanMatchFn],
    canActivate: [gatekeeperGuardActivateFn, brokerageExchangeAgreementGuard, serverlessRouteGuard],
    resolve: {
      gatekeeper: gatekeeperResolver,
    },
  },
  {
    path: '',
    redirectTo: 'trade',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'trade/ticket',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
