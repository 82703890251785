import { EnvironmentActionTypes, EnvironmentActionTypesUnion } from '../../actions';
import { EnvironmentState, initialEnvironmentState } from '../../states/environment.state';

export const environmentReducer = (
  state: EnvironmentState = initialEnvironmentState,
  action: EnvironmentActionTypesUnion
): EnvironmentState => {
  if (action.type === EnvironmentActionTypes.LOAD_ENVIRONMENT_SUCCESS) {
    return action.payload;
  }

  return state;
};
