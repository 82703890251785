import { Action } from '@ngrx/store';

/*
|=====================================================================
| Action Types
|=====================================================================
*/

export enum KeywordSearchActionTypes {
  CLEAR_KEYWORD_SEARCH = '[Keyword Search] Clear',
}

/*
|=====================================================================
| Actions
|=====================================================================
*/

export class ClearKeywordSearchAction implements Action {
  readonly type = KeywordSearchActionTypes.CLEAR_KEYWORD_SEARCH;
}

/*
|=====================================================================
| Factory Helper Methods
|=====================================================================
*/

export const createClearKeywordSearchAction = () => {
  return new ClearKeywordSearchAction();
};

/*
|=====================================================================
| Union
|=====================================================================
*/

export type KeywordSearchActionTypesUnion = ClearKeywordSearchAction;
