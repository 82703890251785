import { HistoryActionTypes, HistoryActionTypesUnion } from '../../actions';
import { HistoryState, initialHistoryState } from '../../states';

export const historyReducer = (
  state: HistoryState = initialHistoryState,
  action: HistoryActionTypesUnion
): HistoryState => {
  switch (action.type) {
    case HistoryActionTypes.SET_HISTORY_URL:
      return { ...state, url: action.payload };

    case HistoryActionTypes.CLEAR_HISTORY_URL:
      return { ...state, url: null };

    default:
      return state;
  }
};
