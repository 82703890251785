<ng-container
  *ngIf="{
    order: (orderCancel$ | async),
    hardStopRules: (hardStopRules$ | async),
    changeOrderEnabled: (changeOrderEnabled$ | async)
  } as view"
>
  <twe-critical-error *ngIf="hasCriticalOrderCancelError">
    <p class="twe-c11n-color-ink-400 c11n-space-stack-0_5x" [innerHTML]="content.cancelOrder.requestCannotBeCompleted">
    </p>
  </twe-critical-error>

  <div *ngIf="!hasCriticalOrderCancelError">
    <div class="container-fluid c11n-space-stack-5x c11n-space--force">
      <div class="row">
        <div class="col-md-6 mt-6" data-testid="cancelOrderData">
          <h2 class="c11n-text-lg-bold c11n-space-stack-1_5x mt-3">
            {{ content.cancelOrder.header }}
          </h2>
          <p class="twe-text-bold c11n-space-stack-3x">
            {{ content.cancelOrder.note }}
          </p>
          <p class="c11n-text-md c11n-space-stack-3x">
            {{ content.cancelOrder.orderInfo1 }}
            <span class="security-name text-uppercase">
              {{ view.order?.description || '&mdash;' }} ({{ view.order?.ticker || '&mdash;' }}).
            </span>
            <span *ngIf="view.changeOrderEnabled && view.order?.amountType !== amountTypes.DOLLARS">
              {{ content?.cancelOrder.changeOrderInfo.part1 }}
              <a
                c11n-link
                variant="primary-reinforced"
                [routerLink]="TRADE_PATH"
                [queryParams]="{ accountId: view.order?.accountId, orderId: view.order?.orderId }"
              >
                {{ content?.cancelOrder.changeOrderInfo.linkText }}</a
              >
              {{ content?.cancelOrder.changeOrderInfo.part2 }}
            </span>
          </p>

          <twe-cancel-order-detail></twe-cancel-order-detail>
        </div>
        <div class="col-md-6 col-xl-4 offset-xl-1">
          <div class="mt-3 c11n-space-stack-5x">
            <div class="c11n-space-stack-1_5x">
              <c11n-button
                appSubmitButtonOnce
                id="submit-cancel-validate-button"
                buttonType="button"
                size="medium"
                variant="primary"
                [fullWidth]="true"
                [labelText]="content.cancelOrder.cancelButtonLabel"
                (clickFunc)="onValidateOrderCancel()"
              ></c11n-button>
            </div>
            <div
              ><c11n-button
                id="keep-order-button"
                buttonType="button"
                size="medium"
                variant="secondary"
                [fullWidth]="true"
                [labelText]="content.labels.keepOrder"
                (clickEvent)="handleKeepOrder()"
              ></c11n-button
            ></div>
          </div>

          <div class="cancel-page-quote">
            <twe-quote [isCancelOrder]="true"></twe-quote>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Warning Modal -->
  <twe-cancel-warning-modal
    [triggeredRules]="triggeredWarningRules"
    (cancelOrder)="submitOrderCancel()"
    [isSubmissionWithTriggeredRules]="isSubmitWithTriggeredRules"
  ></twe-cancel-warning-modal>

  <!-- Error modal -->
  <twe-cancel-error-modal
    [hardStopRules]="view.hardStopRules"
    (navigateToOrderStatus)="navigateToOrderStatus()"
  ></twe-cancel-error-modal>

  <!-- Cancel request cannot be completed modal -->
  <twe-generic-error-modal></twe-generic-error-modal>
</ng-container>
