import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/*
|=====================================================================
| Required When In
|=====================================================================
|
| This validator requires the given control only when a second control
| has one of the given values. For example, limit price is required
| only when the order type is limit or stop limit.
|
*/

export const requiredWhenIn = (controlToRequire: string, controlToCheck: string, values: string[]): ValidatorFn => {
  return (control: FormGroup): ValidationErrors | null => {
    const controlToCheckHasTargetValue = values.indexOf(control.get(controlToCheck).value) !== -1;

    // Should we be more opinionated here and also check for empty strings like '    '?

    const controlToRequireIsEmpty =
      control.get(controlToRequire).value === null ||
      control.get(controlToRequire).value === undefined ||
      control.get(controlToRequire).value === '';

    if (controlToCheckHasTargetValue && controlToRequireIsEmpty) {
      const error = {};

      error[`${controlToRequire}RequiredWhenIn`] = {
        requiredWhenIn: {
          controlToRequire,
          controlToCheck,
          values,
        },
      };

      return error;
    }

    return null;
  };
};
