import { Component } from '@angular/core';
import content from '@content/content.json';
import { AdobeAnalyticsService } from '@core/services';
import { SessionStorageKeys } from '@etfs-equities/enums/session-storage-keys.enums';
import { JsonContent } from '@etfs-equities/models';
import { SessionStorageService } from '@etfs-equities/services';

@Component({
  selector: 'twe-volatility-banner',
  templateUrl: './volatility-banner.component.html',
  styleUrls: ['./volatility-banner.component.scss'],
})
export class VolatilityBannerComponent {
  //  Public variables...
  content: JsonContent = content;
  dismissed = this.sessionStorageService.get(SessionStorageKeys.HAS_DISMISSED_MARKET_VOLATILITY_BANNER) === true;

  constructor(
    public readonly adobeService: AdobeAnalyticsService,
    private readonly sessionStorageService: SessionStorageService
  ) {}

  dismiss() {
    this.dismissed = true;
    this.sessionStorageService.set(SessionStorageKeys.HAS_DISMISSED_MARKET_VOLATILITY_BANNER, true);
  }
}
